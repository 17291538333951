import React, { useState } from 'react';
import Modal from 'react-responsive-modal';

const Popup = ({ open, onPopupClose, icon, children }) => {

    return (
        <Modal 
            classNames={{ modal: "popup", overlay: 'popup__overlay' }}
            open={open}
            onClose={onPopupClose} 
            center
        >
            {icon ?
               icon : null
            }
            {children}
        </Modal>
    )
}

export default Popup;