import React from 'react';

const TermsPage = () => {
    return (
        <div className="terms">
            <div className="container m-container m-container--center">
                <div className="terms__card m-card">
                    <h1 className="terms__headline m-txt m-txt--xl m-txt--bold">Terms and Conditions</h1>
                    <p class="m-txt">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
                        ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
                        parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,
                        pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec
                        pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
                    </p>
                    <br />
                    <br />
                    <p class="m-txt">
                        In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                        dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.
                        Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo
                        ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem
                        ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla
                        ut metus varius laoreet.
                    </p>
                    <br />
                    <br />
                    <p class="m-txt">
                        Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur
                        ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus,
                        tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing
                        sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit
                        id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut
                        libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros
                        faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec
                        sodales sagittis magna.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsPage;
