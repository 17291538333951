import React, { useCallback } from 'react';

import NavBar from 'components/nav-bar/nav-bar';
import Footer from 'components/footer/footer';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { setUserLogout } from 'redux/actions/user-actions';
import { pathnames, routes } from 'routes/routes';
import { useIsMount } from 'hooks/use-mount';

const Layout = ({ children }) => {
    const isMount = useIsMount();
    const dispatch = useDispatch();
    const location = useLocation();
    const { auth, profile } = useSelector((state) => state.user);
    const logout = useCallback(() => dispatch(setUserLogout()), [dispatch]);
    const current = routes.filter((o) => {
        if (o.path.includes(':')) {
            const path = o.path.split(':')[0];
            const includedPath = location.pathname.includes(path);
            if (includedPath) return o;
        } else {
            return location.pathname === o.path;
        }
    });

    if (isMobile && isMount) {
        return <Redirect to={pathnames.mobileUser} />;
    }

    if (current[0] && !isMobile) {
        const privateRoute = current[0].private;
        if (auth && !profile && privateRoute) {
            logout();
            return <Redirect to={pathnames.login} />;
        }

        if (!auth && privateRoute) {
            logout();
            return <Redirect to={pathnames.login} />;
        }
    }

    return (
        <div className="m-page">
            <NavBar />
            {children}
            <Footer />
        </div>
    );
};

export default Layout;
