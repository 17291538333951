import React, { useState, useMemo, useEffect } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import ProfilePage from 'pages/accounts/account-profile/account-profile-page';
import AccountResetPasswordPage from 'pages/accounts/account-reset-password/account-reset-password-page';
import AccountAddressPage from 'pages/accounts/account-address/account-address-page';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { pathnames } from 'routes/routes';
import { useIsMount } from 'hooks/use-mount';
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const AccountSettingsPage = (props) => {
    const history = useHistory();
	const isMount = useIsMount();
	const { state } = useLocation();
	const {test} = useParams();
    const [value, setValue] = React.useState(state.currentTab == 'profile'? 0:state.currentTab == 'address'? 1:state.currentTab == 'resetPassword'? 2:0);
	const [section, setSection] = useState(state.currentTab? state.currentTab : 'profile');
    const sectionList = useMemo(
        () => [
            { value: 'profile', label: 'Profile', component: <ProfilePage {...props} /> },            
            { value: 'address', label: 'My Address', component: <AccountAddressPage {...props} /> },
			{
                value: 'resetPassword',
                label: 'Reset Password',
                component: <AccountResetPasswordPage {...props} />,
            },
        ],
        [props]
    );
    const currentSection = sectionList.filter((s) => s.value === state.currentTab)[0];

    const handleChange = (event, newValue) => {	
		switch (newValue)	{
			case 0:
				history.push('/account/settings/profile', { currentTab: 'profile'});
				break;
			case 1:
				history.push('/account/settings/address', { currentTab: 'address'});
				break;
			case 2:
				history.push('/account/settings/resetPassword', { currentTab: 'resetPassword'});
				break;
		}		
		setValue(newValue);
	};

    const setAddressTab = () => {
		setSection('address');
	};

	useEffect(() => {
		const testValue = (state.currentTab == 'profile'? 0:state.currentTab == 'address'? 1:state.currentTab == 'resetPassword'? 2:0);
        if (testValue != value) {
            handleChange(null, (state.currentTab == 'profile'? 0:state.currentTab == 'address'? 1:state.currentTab == 'resetPassword'? 2:0));
        }
    }, [handleChange]);

    return (
        <div className="account-settings">
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[
                            { onClick: ()=>history.push(pathnames.accounts), text: 'My Account' },
                            'Account Setting',
                        ]}
                    />
                </div>
            </div>
			<div className="m-container m-container--center">
            <AppBar className="m-wrapper ml-5 z-0"
					position="static"
					style={{ background: "none", boxShadow: "none", color: "black", marginTop: 20 }}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						TabIndicatorProps={{ style: { background: "#000000", height: "3px" } }}
                        className="mr-auto"
					>
						<Tab
							disableRipple
							label="Profile"
							{...a11yProps(0)}
							style={{ boxShadow: "none" }}
                            onClick={() => setSection('profile')}
						/>
						<Tab disableRipple label="My Address" {...a11yProps(1)} style={{ boxShadow: "none" }} onClick={() => setSection('address')}/>
						<Tab disableRipple label="Reset Password" {...a11yProps(2)} style={{ boxShadow: "none" }} onClick={() => setSection('resetPassword')}/>
					</Tabs>
				</AppBar>
				</div>
            <div className="m-container m-container--center">
                <div className="m-wrapper account-settings__wrapper">
                    <div className="account-settings__page m-card border border-gray-300">{currentSection.component}</div>
                </div>
            </div>
        </div>
    );
};

export default AccountSettingsPage;
