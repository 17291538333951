import React, { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { pathnames } from 'routes/routes';

const ProductCard = ({ products }) => {
    const location = useLocation();
    const history = useHistory();

    const onHandleNavigateProduct = (id) => {
        const path = pathnames.productDetails.replace(':productId', id)
        history.push(path, { from: location.pathname });
    };

    return (
        <Fragment>
            {products.map((o, i) => {
                const key = `${i}-product-card`;

                return (<div key={key} className="product-card" onClick={() => onHandleNavigateProduct(o.id)}>
                    <div className="product-card-home__item">
                        <div
                            className="product-card__image"
                            style={{ backgroundImage: `url(${o.productImages[0]})` }}
                        />
                        <div className="product-card__content">
                            <p className="m-txt">{o.name}</p>
                            <p className="m-txt m-txt--bold">RM {o.price}</p>
                        </div>
                    </div>
                </div>)
            })}
        </Fragment>
    );
};

export default ProductCard;
