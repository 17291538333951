import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { BiReceipt } from 'react-icons/bi';
import { FiEdit2, FiMail, FiMapPin, FiPhoneCall } from 'react-icons/fi';
import * as yup from 'yup';
import { formatCurrency, getVariationStatus, changeAddressToString } from 'helpers/utility';
import Button from 'components/button/button';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import { pathnames } from 'routes/routes';
import api from 'services/api';
import Modal from 'react-responsive-modal';
import { Scrollbars } from 'rc-scrollbars';
import { ErrorMessage, Form, Formik } from 'formik';
import TextInput from 'components/inputs/text-input/text-input';
import Dropdown from 'components/inputs/dropdown/dropdown';
import config from 'helpers/config';
import { getUserProfile } from 'redux/actions/user-actions';
import { useIsMount } from 'hooks/use-mount';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const validateSchema = yup.object().shape({
    Address: yup.string().required('Address is required.'),
    country: yup.string().required('Country is required.'),
    state: yup.string().required('State is required.'),
    city: yup.string().required('City is required.'),
    postcode: yup.string().required('Postcode is required.'),
});

const CheckoutPage = (props) => {
    const isMount = useIsMount();
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.user);
    const { address, id } = useSelector((state) => state.user.profile);
    const [deliveryAddress, setDeliveryAddress] = useState(profile.deliveryAddress);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [onSubmit, setOnSubmit] = useState(false);
    const history = useHistory();
    const { state } = useLocation();
    const [countries, setCountries] = useState([]);
    const products = state?.checkout?.products || [];
    const submitBtnText = onSubmit ? 'UPDATING' : 'UPDATE';
    const [selectedAddressType, setSelectedAddressType] = useState();
    const onHandleGetUserProfile = useCallback((o) => dispatch(getUserProfile(o)), [dispatch]);

    const initialValues = {
        name: profile?.displayName || '',
        mobileNumber: profile?.mobileNumber || '',
        Address: address?.Address,
        city: address?.city || '',
        state: address?.state || '',
        postcode: address?.postcode || '',
        country: address?.country || '',
        addressType: address?.addressType || '',
    };

    const onHandleGetCountries = useCallback(async () => {
        try {
            const response = await api.get.countries();
            const mappedCountries = response.data.result.map((o) => ({
                label: o.country,
                value: o.id,
            }));
            const mysiaIndex = mappedCountries.findIndex(
                (o) => o.label.toLowerCase() === 'malaysia'
            );
            mappedCountries.unshift(mappedCountries[mysiaIndex]);
            mappedCountries.splice(mysiaIndex);
            setCountries(mappedCountries);
            updateAddress(mappedCountries);            
        } catch (error) {
            console.log(error);
        }
    }, []);

    const updateAddress = (mappedCountries) => {
            const oldCountry = '"country":'+JSON.parse(deliveryAddress)['country'];
            const newCountry = '"country":'+JSON.stringify(mappedCountries.filter(x => x.value === JSON.parse(deliveryAddress)['country'])[0]?.label);
            const newAddress = deliveryAddress.replace(oldCountry,newCountry);
            const newAddress2 = newAddress.replace(',"addressType":""', '');
            setDeliveryAddress(newAddress2);
    }

    useEffect(() => {
        if (isMount) {
            onHandleGetCountries();
        }
    }, [isMount, onHandleGetCountries]);

    useEffect(() => {
        if (props.deliveryAddressForm) {
            setDeliveryAddress(JSON.stringify(props.deliveryAddressForm));
        }
    }, [props]);

    const onHandleCreateOrder = async () => {
        if (!profile.deliveryAddress && !deliveryAddress) {
            setModalVisibility(true);
        } else {
            try {
                const payload = {
                    userId: profile.id,
                    deliveryName: profile.displayName,
                    deliveryAddress: deliveryAddress ? deliveryAddress : profile.deliveryAddress,
                    mobileNumber: profile.mobileNumber,
                    email: profile.email,
                    products: products.map((o) => ({
                        productId: o.productId,
                        productQuantity: o.quantity,
                        variationId: o.variations ? o.variations[0].id : null,
                    })),
                };
                await api.post.createOrder(payload);
                history.push(pathnames.cart);
                // "WIP"
                // const resp = await api.post.createOrder(payload);
			    // const { paymentReferenceNumber } = resp.data.result;
                // history.push(pathnames.payment, {paymentReferenceNumber: paymentReferenceNumber, type: 'default'});
                createNotification('success', 'Thank you, your order has been made');
            } catch (error) {  
                alert(typeof deliveryAddress)              
                // createNotification('error', error.toString());
            }
        }
    };

    const createNotification = (type, message, title) => {
        switch (type) {
            case 'info':
                NotificationManager.info(message);
                break;
            case 'success':
                NotificationManager.success(message);
                break;
            case 'warning':
                NotificationManager.warning(message);
                break;
            case 'error':
                NotificationManager.error(message);
                break;
        }
    };

    const onHandleUpdateAddress = async (values) => {
        setOnSubmit(true);
        if(!values.addressType || values.addressType == ""){
            values.addressType = 'Home';
        }
        try {
            var mobileNum = document.getElementById('mobile').value;
            const formData = new FormData();
            formData.append('id', id);
            formData.append(
                'address',
                JSON.stringify({
                    Address: values.Address,
                    city: values.city,
                    state: values.state,
                    postcode: values.postcode,
                    country: values.country,
                    addressType: values.addressType,
                })
            );
            formData.append('name', values.name);
            formData.append('mobileNumber', mobileNum);
            await api.post.profileUpdate(formData);
            onHandleGetUserProfile(id);     
            const formAddress = JSON.stringify({
                Address: values.Address,
                city: values.city,
                state: values.state,
                postcode: values.postcode,
                country: values.country,
                addressType: values.addressType,
            });
            const oldCountry = '"country":'+JSON.parse(formAddress)['country'];
            const newCountry = '"country":'+JSON.stringify(countries.filter(x => x.value === JSON.parse(formAddress)['country'])[0]?.label);
            const newAddress = formAddress.replace(oldCountry,newCountry);
            const newAddress2 = newAddress.replace(',"addressType":""', '');
            setDeliveryAddress(newAddress2);
            setTimeout(() => {
                createNotification('success', 'Delivery Address Updated');                
            }, 500);
        } catch (error) {
            //createNotification('error', error);
            console.log(error)
        } finally {
            setOnSubmit(false);
        }
    };

    const handleMobileNumber = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]+/g, '');
    };

    return (
        <div className="checkout">
            <NotificationContainer />
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[{ onClick: ()=>history.push(pathnames.cart), text: 'My Cart' }, 'Shipping Details']}
                    />
                </div>
            </div>
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <div className="checkout__shipping-details">
                        <div className="checkout__shipping-content checkout__shipping-content--header">
                            <p className="m-txt m-txt--m m-txt--grey">Shipping Details</p>
                            <div
                                className="checkout__edit m-txt"
                                onClick={() => setModalVisibility(true)}
                            >
                                <FiEdit2 size={20} />
                                <p className="m-txt m-txt--bold">Edit</p>
                            </div>
                        </div>
                    </div>
                    <div className="checkout__shipping-details">
                        <div className="checkout__shipping-content">
                            <FiMapPin size={25} />
                            <div>
                                <p className="m-txt m-txt--m">{profile.displayName}</p>
                                <p className="m-txt">
                                    {changeAddressToString(deliveryAddress) || '-'}
                                </p>
                            </div>
                        </div>

                        <div className="checkout__shipping-content">
                            <BiReceipt size={25} />
                            <p className="m-txt">
                                {(deliveryAddress) || profile.deliveryAddress
                                    ? 'Bill to the same address'
                                    : '-'}
                            </p>
                        </div>

                        <div className="checkout__shipping-content">
                            <FiPhoneCall size={25} />
                            <p className="m-txt">{profile.mobileNumber}</p>
                        </div>

                        <div className="checkout__shipping-content">
                            <FiMail size={25} />
                            <p className="m-txt">{profile.email}</p>
                        </div>
                    </div>

                    <div className="checkout__shipping-line" />
                    {products.map((j, k) => {
                        const itemKey = `${k}-cart-item`;
                        return (
                            <div className="checkout__item" key={itemKey}>
                                <div className="checkout__item-wrapper checkout__item-wrapper--product">
                                    <CircleAvatar src={j.productImage} size={55} />
                                    <div className="checkout__item-product">
                                        <p className="m-txt">
                                            {j.productName} / {j.weightPerUnit}kg
                                        </p>
                                        <p className="m-txt m-txt--s m-txt--grey">
                                            {getVariationStatus(j)}
                                        </p>
                                        <p className="m-txt m-txt--error m-txt--bold">
                                            RM {j.productPrice.toFixed(2)}
                                        </p>
                                    </div>
                                </div>
                                <div className="checkout__item-wrapper  checkout__item-wrapper--total">
                                    <div className="checkout__item-product">
                                        <p className="m-txt">Qty : {j.quantity}</p>
                                    </div>
                                </div>
                                <div className="checkout__item-wrapper  checkout__item-wrapper--total">
                                    <div className="checkout__item-product">
                                        <p className="m-txt m-txt--grey m-txt--bold">{`RM ${(
                                            j.quantity * j.productPrice
                                        ).toFixed(2)}`}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className="checkout__total">
                        <div className="checkout__total" />
                        <div className="checkout__total" />
                        <div className="checkout__total" />
                        <div className="checkout__total-price text-right">
                            <p className="m-txt m-txt--grey">
                                Subtotal ({products.length} {products.length > 1 ? 'items' : 'item'}
                                ):
                            </p>
                            <p className="m-txt m-txt--grey">Shipping fee:</p>
                        </div>
                        <div className="checkout__total-price">
                            <p className="m-txt m-txt--bold">
                                RM{' '}
                                {formatCurrency(state.checkout.total - state.checkout.shippingFee)}
                            </p>
                            <p className="m-txt m-txt--bold">
                                RM {formatCurrency(state.checkout.shippingFee)}
                            </p>
                        </div>
                    </div>
                    <div className="checkout__total checkout__total--white">
                        <div className="checkout__total" />
                        <div className="checkout__total" />
                        <div className="checkout__total" />
                        <div className="checkout__total-price">
                            <p className="m-txt m-txt--m m-txt--bold m-txt--grey">
                                Total to pay (Inc SST):
                            </p>
                        </div>
                        <div className="checkout__total-price">
                            <p className="m-txt m-txt--m m-txt--bold m-txt--grey">
                                <span className="m-txt--error">
                                    RM {formatCurrency(state.checkout.total)}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="checkout__button-wrapper checkout__button-wrapper--center">
                        <Button label="PLACE ORDER" onClick={onHandleCreateOrder} />
                    </div>
                </div>
            </div>
            <Modal
                center
                open={modalVisibility}
                onClose={() => setModalVisibility(false)}
                classNames={{ modal: 'w-1/2' }}
            >
                <div className="address-modal-card bg-white w-full">
                    <div className="mr-auto">
                        <p className="font-medium text-left text-2xl">Edit Shipping Details</p>
                    </div>
                    <Scrollbars>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validateSchema}
                            onSubmit={onHandleUpdateAddress}
                            classNames="w-1/2"
                        >
                            {({ values, setFieldValue }) => (
                                <Form className="micro-address__form">
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="name"
                                            placeholder="Recipient’s name"
                                            label="Recipient’s name"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="name" />
                                        </p>
                                    </div>
                                    <div
                                        className="micro-address__input-wrapper"
                                        style={{
                                            borderBottom: '1px solid lightgrey',
                                            paddingBottom: '20px',
                                        }}
                                    >
                                       <label
                                            class="m-text m-text--s text-input__label"
                                            for="name"
                                        >
                                            Phone Number
                                        </label>
                                        <div class="inputpad-group prefix mt-2">
                                            <span class="inputpad-group-addon">
                                                +60{' '}
                                                <h1
                                                    style={{
                                                        color: 'lightgray',
                                                        display: 'inline',
                                                        marginLeft: 10,
                                                    }}
                                                >
                                                    {' '}
                                                    |{' '}
                                                </h1>{' '}
                                            </span>
                                            <input
                                                maxLength={9}
                                                type="text"
                                                id="mobile"
                                                pattern="[0-9]*"
                                                defaultValue={profile.mobileNumber}
                                                onChange={(e) => handleMobileNumber(e)}
                                                placeholder="Mobile Number"
                                                className="bg-white
						   py-4 inputpad
						  justify-between items-center"
                                                style={{
                                                    border: '1px solid rgba(0, 0, 0, 0.1)',
                                                    borderLeft: 'none',
                                                    borderRadius: '0 0.25rem 0.25rem 0',
                                                    paddingLeft: 0,
                                                }}
                                            />
                                        </div>
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="mobileNumber" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="Address"
                                            placeholder="Address"
                                            label="Address"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="Address" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper">
                                        <Dropdown
                                            outline
                                            max
                                            value={
                                                countries.filter(
                                                    (o) => o.value === values.country
                                                )[0]
                                            }
                                            options={countries}
                                            label="Country"
                                            disabled={onSubmit}
                                            onChange={(country) =>
                                                setFieldValue('country', country.value)
                                            }
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="country" />
                                        </p>
                                    </div>
                                    {values.country && values.country === 157 ? (
                                        <div className="account-address__input-wrapper">
                                            <Dropdown
                                                outline
                                                max
                                                value={
                                                    config.malaysia.state.filter(
                                                        (o) => o.value === values.state
                                                    )[0]
                                                }
                                                options={config.malaysia.state}
                                                label="State"
                                                disabled={onSubmit}
                                                onChange={(state) =>
                                                    setFieldValue('state', state.value)
                                                }
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="state" />
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="account-address__input-wrapper">
                                            <TextInput
                                                max
                                                outline
                                                name="state"
                                                placeholder="States"
                                                label="States"
                                                disabled={onSubmit}
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="state" />
                                            </p>
                                        </div>
                                    )}
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="city"
                                            placeholder="City"
                                            label="City"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="city" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="postcode"
                                            placeholder="Zip/Postal code"
                                            label="Zip/Postal code"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="postcode" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper mb-6">
                                        <RadioGroup
                                            row
                                            aria-label="addressType"
                                            name="addressType"
                                            defaultValue={
                                                initialValues.addressType
                                                    ? initialValues.addressType
                                                    : 'Home'
                                            }
                                            onChange={(e) =>
                                                setFieldValue('addressType', e.target.value)
                                            }
                                        >
                                            <FormControlLabel
                                                value="Home"
                                                control={<Radio color="default" />}
                                                label="Home"
                                            />
                                            <FormControlLabel
                                                value="Office"
                                                control={<Radio color="default" />}
                                                label="Office"
                                            />
                                        </RadioGroup>
                                    </div>
                                    <div className="cart__button-wrapper">
                                        <label
                                            className="self-center m-auto cursor-pointer"
                                            onClick={() => setModalVisibility(false)}
                                        >
                                            CANCEL
                                        </label>
                                        <Button
                                            type="submit"
                                            label="SAVE"
                                            text
                                            gold
                                            disabled={onSubmit}
                                            style={{ boxShadow: 'none' }}
                                            onClick={() => setModalVisibility(false)}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Scrollbars>
                </div>
            </Modal>
        </div>
    );
};

export default CheckoutPage;
