import { takeEvery, put, call } from "redux-saga/effects";

import { products } from "redux/actions/products-actions";
import api from "services/api";

export default function* productsSaga() {
    yield takeEvery(products.PRODUCTS_GET, getProducts);
}

function* getProducts(action) {
    try {
        const params = action.data;
        const response = yield call(api.get.products, params);
        yield put({ type: products.PRODUCTS_SET, data: response.data.result });
    } catch (error) {
        console.log(error.response.data.message);
    }
}