import React from 'react';
import { Link } from 'react-router-dom';
import notfound from 'assets/notfound/notfound.png';
import Button from 'components/button/button';
import { pathnames } from 'routes/routes';

const NotFoundPage = () => {
    return (
        <div className="not-found m-full-h">
            <div className="container m-container m-container--center">
                <div className="not-found__card m-card">
                    <img className="not-found__image" src={notfound} alt="notfound" />
                    <h1 className="not-found__headline m-txt m-txt--xl m-txt--bold">Oops, We got a problem!</h1>
                    <p className="m-txt">Looks like we had lost you.</p>
                    <Link className="not-found__link" to={pathnames.home}>
                        <Button label="BACK TO HOME" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
