export const user = {
  USER_AUTH_SET: 'USER_AUTH_SET',
  USER_PROFILE_GET: "USER_PROFILE_GET",
  USER_PROFILE_SET: "USER_PROFILE_SET",
  USER_SET_WISH: "USER_SET_WISH",
  USER_NOTIFICATION_SUMMARY_GET: "USER_NOTIFICATION_SUMMARY_GET",
  USER_NOTIFICATION_SUMMARY_SET: "USER_NOTIFICATION_SUMMARY_SET",
  USER_LOGOUT: "USER_LOGOUT",
}


export const setUserAuth = (o) => {
  return {
    type: user.USER_AUTH_SET,
    data: o
  };
}

export const setUserLogout = () => {
  return {
    type: user.USER_LOGOUT,
  };
}

export const getUserProfile = (o) => {
  return {
    type: user.USER_PROFILE_GET,
    data: o
  };
}

export const setUserProfile = (o) => {
  return {
    type: user.USER_PROFILE_SET,
    data: o
  };
}


export const setWishProduct = (o) => {
  return {
    type: user.USER_SET_WISH,
    data: o
  };
}

export const getUserNotificationSummary = (o) => {
  return {
    type: user.USER_NOTIFICATION_SUMMARY_GET,
    data: o
  };
}

