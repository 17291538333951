import React from 'react';
import { Link } from 'react-router-dom';
import { pathnames } from 'routes/routes';
import logo from 'assets/navbar/logo.png';
import appStore from 'assets/mobileUser/appleStore.svg';
import playStore from 'assets/mobileUser/googlePlay.svg';
import paymentMethod from 'assets/payment-method.png';
import moment from "moment";
import ITUBEPolicy from 'assets/document/Itube Policy.pdf';
import ITUBEPrivacyPolicy from 'assets/document/HOT TV ENTERTAINMENT SDN BHD Privacy Policy.pdf';
import ITUBEHelp from 'assets/document/Help iTube.pdf';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const Footer = () => {
    const history = useHistory();
    var year = moment().format('YYYY');
    return (
        <div className="footer">
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <section className="footer__section">
                        <div className="footer__content footer__content--logo">
                            <div className="footer__logo">
                                <img src={logo} alt="m-touch" />
                            </div>
                            <div className="footer__about">
                                <p className="m-txt m-txt--bold">We accept</p>
                                <img src={paymentMethod} alt="payment method" />
                            </div>
                        </div>
                        {!isMobile?
                        <div className="footer__content">
                            <p className="m-txt m-txt--bold">Download our mobile app via</p>
                            <div className="footer__app-download">
                                <img src={appStore} alt="app store" />
                                <img src={playStore} alt="play store" />
                            </div>
                        </div>:null}
                    </section>
                    <section className="footer__section">
                        <p className="m-txt">© {year} BUYER. All rights reserved.</p>

                        <ul className="footer__list">
                            <li className="footer__list-item"><a href='https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf' target="_blank">Terms of Services</a></li>
                            <li className="footer__list-item"><a href='https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf' target="_blank">Privacy Policy</a></li>
                            <li className="footer__list-item"><a onClick={()=>history.push(pathnames.help)}>Help</a></li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    )

}

export default Footer;