import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import { IoRemoveOutline } from 'react-icons/io5';

import ProductCard from 'components/product-card/product-card';
import TextInput from 'components/inputs/text-input/text-input';
import RatingPicker from 'components/rating-picker/rating-picker';
import Button from 'components/button/button';
import { getProducts, clearProducts } from 'redux/actions/products-actions';
import api from 'services/api';
import { useIsMount } from 'hooks/use-mount';
import noProductIcon from 'assets/notfound/search-failed-icon.png';

const CategoryPage = (props) => {
    const pageRef = useRef(0);
    const scrollableRef = useRef();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { categoryId } = useParams();
    const { lists } = useSelector((state) => state.products);
    const onHandleGetProducts = useCallback((o) => dispatch(getProducts(o)), [dispatch]);
    const onHandleClearProducts = useCallback(() => dispatch(clearProducts()), [dispatch]);

    useEffect(() => {
        pageRef.current = 0;
        onHandleClearProducts();
        const params = {};

        if (categoryId) {
            params.categories = categoryId;
        }
        if (state && state.query) {
            params.name = state.query;
        }

        onHandleGetProducts(params);
    }, [state, pageRef, categoryId, onHandleClearProducts, onHandleGetProducts]);

    const onHandleReset = () => {
        window.scrollTo(0, 0);
        pageRef.current = 0;
        onHandleClearProducts();
    };

    const onHanldeScrollGetProduct = () => {
        if (!lists) return;
        if (pageRef && pageRef.current + 1 < lists.totalPage) {
            let increasedPage = (pageRef.current += 1);
            pageRef.current = increasedPage;
            onHandleGetProducts({ page: increasedPage });
        }
    };

    const debounceScroll = _.debounce(onHanldeScrollGetProduct, 1000);

    useEffect(() => {
        const onHandleScroll = () => {
            if (!scrollableRef.current) return;
            if (window.innerHeight + window.scrollY >= scrollableRef.current.clientHeight) {
                debounceScroll();
            }
        };

        window.addEventListener('scroll', onHandleScroll);

        return () => {
            window.removeEventListener('scroll', onHandleScroll);
        };
    }, [scrollableRef, debounceScroll]);

    return (
        <div className="category" ref={scrollableRef}>
            <div className="m-container m-container--center">
                <div className="category__wrapper m-wrapper">
                    <ProductFilter onHandleReset={onHandleReset} {...props} />
                    <div className="category__products-wrapper">
                        {lists && lists.productList.length > 0 ? (
                            <ProductCard products={lists.productList} />
                        ) : (
                            <>
                                <div className="m-wrapper">
                                    <img src={noProductIcon} className="m-auto mt-48 mb-4 w-12" style={{width:"80px"}}/>
                                    <p className="text-center mb-40 text-gray-500">
                                        Sorry, we can’t find any matches
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryPage;

const LocationOptions = [
    { name: 'Local', value: 'local' },
    { name: 'Overseas', value: 'overseas' },
];

const initialValues = {
    min: '',
    max: '',
    rating: '',
    location: '',
    categories: [],
};

export const ProductFilter = ({ onHandleReset, ...props }) => {
    const isMount = useIsMount();
    const formikRef = useRef();
    const [categories, setCategories] = useState();
    const { categoryId } = useParams();
    const dispatch = useDispatch();
    const onHandleGetProducts = useCallback((o) => dispatch(getProducts(o)), [dispatch]);

    const onHandleGetCategories = useCallback(async () => {
        try {
            const response = await api.get.categories();
            setCategories(response.data.result);
        } catch (error) {
            console.log(error.response.data.message);
        }
    }, []);

    useEffect(() => {
        if (isMount) {
            onHandleGetCategories();
            if (formikRef.current && !isNaN(categoryId)) {
                formikRef.current.setFieldValue('categories', [+categoryId]);
            }
        }
    }, [isMount, categoryId, formikRef, onHandleGetCategories]);

    const onHandleSelectCategories = (id) => {
        let ids = formikRef.current.values.categories;

        if (ids.indexOf(id) > -1) {
            ids = ids.filter((o) => o !== id);
        } else {
            ids.push(id);
        }

        if (formikRef.current) {
            formikRef.current.setFieldValue('categories', ids);
        }
    };

    const getFormParams = (values) => {
        const params = {};
        if (values.rating) params.rating = values.rating;
        if (values.min && values.max) params.priceRange = `${values.min},${values.max}`;
        if (values.categories) params.categories = values.categories.toString();
        if (values.location) params.location = values.location;

        return params;
    };

    const onHandleSearch = (values) => {
        const params = getFormParams(values);
        try {
            onHandleReset();
            onHandleGetProducts(params);
        } catch (error) {
            console.log(error);
        }
    };

    const onHandleResetFilter = () => {
        if (!formikRef.current) return;
        onHandleReset();
        onHandleGetProducts();
        formikRef.current.resetForm();
        formikRef.current.setFieldValue('categories', []);
    };

    return (
        <div className="category__filter">
            <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onHandleSearch}>
                {({ values, ...props }) => (
                    <Form>
                        <p className="category__headline m-headline">Filter</p>
                        <div className="category__filter-section">
                            <p className="category__label m-txt m-txt--bold">Price (RM)</p>
                            <div className="category__input-wrapper">
                                <TextInput name="min" type="number" placeholder="Min" />{' '}
                                <span className="category__price-spacer" className="mt-8">
                                    <IoRemoveOutline size={20} />{' '}
                                </span>
                                <TextInput name="max" type="number" placeholder="Max" />
                            </div>
                        </div>

                        <div className="category__filter-section">
                            <p className="category__label m-txt m-txt--bold">Rating</p>
                            <RatingPicker
                                value={values.rating}
                                onChange={(v) => props.setFieldValue('rating', v)}
                                forFilterUse
                            />
                        </div>

                        <div className="category__filter-section">
                            <p className="category__label m-txt m-txt--bold">Location</p>
                            {LocationOptions.map((o, i) => {
                                const key = `${i}-loc-${o.name}`;
                                const className =
                                    o.value === values.location
                                        ? 'category__filter-btn category__filter-btn--active'
                                        : 'category__filter-btn';
                                return (
                                    <button
                                        key={key}
                                        type="button"
                                        className={className}
                                        onClick={() => props.setFieldValue('location', o.value)}
                                    >
                                        {o.name}
                                    </button>
                                );
                            })}
                        </div>
                        {categories ? (
                            <div className="category__filter-section">
                                <p className="category__label m-txt m-txt--bold">Category</p>
                                {categories?.map((o, i) => {
                                    const key = `${i}-category-${o.name}`;
                                    const className =
                                        values.categories && values.categories.indexOf(o.id) > -1
                                            ? 'category__filter-btn category__filter-btn--active'
                                            : 'category__filter-btn';

                                    return (
                                        <>
                                            {o.active == true ? (
                                                <button
                                                    className={className}
                                                    key={key}
                                                    type="button"
                                                    onClick={() => {
                                                        onHandleSelectCategories(o.id);
                                                    }}
                                                >
                                                    {o.name}
                                                </button>
                                            ) : null}
                                        </>
                                    );
                                })}
                            </div>
                        ) : null}

                        <div className="category__filter-section">
                            <Button
                                label="RESET"
                                type="button"
                                outline
                                small
                                onClick={onHandleResetFilter}
                            />
                            <Button label="SEARCH" type="submit" gold />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
