export const products = {
    PRODUCTS_GET: 'PRODUCT_GET',
    PRODUCTS_SET: "PRODUCT_SET",
    PRODUCTS_CLEAR: "PRODUCTS_CLEAR",
}

export const getProducts = (o) => {
    return {
        type: products.PRODUCTS_GET,
        data: o
    };
}

export const setProducts = (o) => {
    return {
        type: products.PRODUCTS_SET,
        data: o
    };
}

export const clearProducts = (o) => {
    return {
        type: products.PRODUCTS_CLEAR,
        data: o
    };
}


