import { user } from 'redux/actions/user-actions';

const initialState = {
  auth: null,
  profile: null,
  cart: null,
  notifications: null,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {

    case user.USER_AUTH_SET:
      return {
        ...state,
        auth: action.data
      }

    case user.USER_PROFILE_SET:
      return {
        ...state,
        profile: action.data
      }

    case user.USER_NOTIFICATION_SUMMARY_SET:
      return {
        ...state,
        notifications: action.data
      }

    default:
      return state;
  }
}

export default userReducer;