import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Modal from 'react-responsive-modal';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import RatingPicker from 'components/rating-picker/rating-picker';
import { useIsMount } from 'hooks/use-mount';
import api from 'services/api';
import starIcon from 'assets/notfound/star.png';
import Button from 'components/button/button';
import config from 'helpers/config';
import { IoClose, IoAdd, IoRemove } from 'react-icons/io5';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { pathnames } from 'routes/routes';

const orderDateFormat = 'DD MMM YYYY';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ReviewSection = (props) => {
    const isMount = useIsMount();
    const uploadInputRef = useRef();
    const history = useHistory();
    const location = useLocation();
    const [value, setValue] = React.useState(0);
    const [toReview, setToReview] = useState([]);
    const [completedReviews, setCompletedReviews] = useState([]);
    const { id } = useSelector((state) => state.user.profile);
    const [reviewModalVisibility, setReviewModalVisibility] = useState(false);
    const [reviewModaldata, setReviewModalData] = useState();
    const fileErrors = [
        { type: 'fileSize', error: 'File Size is too large' },
        { type: 'fileType', error: 'Unsupported File Format' },
    ];

    const initialValues = {
        rating: 0,
        review: '',
        image: null,
    };

    const validationSchema = yup.object().shape({
        rating: yup.number().min(1, 'Please rate the product.'),
        review: yup.string().required('Review is required.'),
        // image: yup
        //     .mixed()
        //     .test(fileErrors[0].type, fileErrors[0].error, function (value) {
        //         const { image } = this.parent;
        //         return value && image?.size <= config.file.size ? true : !value ? true : false;
        //     })
        //     .test(fileErrors[1].type, fileErrors[1].error, function (value) {
        //         const { image } = this.parent;
        //         return image && config.file.types.includes(image.type)
        //             ? true
        //             : !value
        //             ? true
        //             : false;
        //     }),
    });
    const onHandleGetToReview = useCallback(async () => {
        try {
            const response = await api.get.toReview(id);
            localStorage.setItem('toReviewAmount', response.data.result.length);
            setToReview(response.data.result);
            console.log(response.data);
        } catch (error) {}
    }, [id, props]);

    useEffect(() => {
        if (isMount) {
            onHandleGetToReview();
        }
    }, [isMount, onHandleGetToReview]);

    const onHandleGetCompletedReviews = useCallback(async () => {
        try {
            const response = await api.get.myReview({ id: id, page: 0, length: 50 });
            setCompletedReviews(response.data.result);
        } catch (error) {
            console.log(error);
        }
    }, [id]);

    useEffect(() => {
        if (isMount) {
            onHandleGetCompletedReviews();
        }
    }, [isMount, onHandleGetCompletedReviews]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onhandleReviewModal = (data) => {
        setReviewModalVisibility(!reviewModalVisibility);
        setReviewModalData(data);
    };

    const onHandleOnSubmit = async (values) => {
        console.log(values)
        try {
            const formData = new FormData();

            if (!values.ratingId) {
                formData.append('ratingId', reviewModaldata.id);
            }

            for (var key in values) {
                if(key != 'image'){
                    formData.append(key, values[key]);
                }                
            }
            if(values['image']?.length > 0){
                for(var i = 0 ; i < values['image'].length; i ++){
                    formData.append('image', values['image'][i]);
                }
            }            
            await api.post.rateProduct(formData);
            setReviewModalVisibility(false);
            onHandleGetCompletedReviews();
            onHandleGetToReview();
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } catch (error) {
            console.log(error)
        }
    };

    const onHandleTriggerUpload = () => {
        if (uploadInputRef) {
            uploadInputRef.current.click();
        }
    };

    const onHandleNavigateProduct = (id) => {
        const path = pathnames.productDetails.replace(':productId', id);
        history.push(path, { from: location.pathname });
    };

    return (
        <>
            <Modal
                center
                open={reviewModalVisibility}
                onClose={() => setReviewModalVisibility(false)}
                showCloseIcon={false}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onHandleOnSubmit}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <div className="m-card">
                                <div className="account-reviews__input-wrapper pb-0">
                                    <button
                                        className="account-reviews__close"
                                        type="button"
                                        onClick={() => setReviewModalVisibility(false)}
                                        style={{ boxShadow: 'none' }}
                                    >
                                        <IoClose size={30} color="lightGrey" />
                                    </button>
                                    <div className="product__reviews-content mr-auto">
                                        <div className="mb-4">
                                            <CircleAvatar src={reviewModaldata.productImage} />
                                        </div>
                                        <div className="product__reviews-user">
                                            <p className="m-txt font-medium mb-0 -mt-2 text-lg truncate">
                                                {reviewModaldata.productName}
                                            </p>
                                            <p
                                                className="text-xs mb-2"
                                                style={{ color: '#888888' }}
                                            >
                                                {reviewModaldata.productVariation}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="account-reviews__input-wrapper items-start py-0 mb-4">
                                    <p className="text-sm ml-2 mb-2">Make a rate</p>
                                    <RatingPicker
                                        value={values.rating}
                                        onChange={(value) => setFieldValue('rating', value)}
                                    />
                                    <p className="m-txt m-txt--small m-txt--error">
                                        <ErrorMessage name="rating" />
                                    </p>
                                </div>
                                <div className="account-reviews__input-wrapper mx-2">
                                    <textarea
                                        value={values.review}
                                        className="account-reviews__textarea bg-white rounded-none"
                                        style={{ border: '1px solid lightGrey', width: '600px' }}
                                        placeholder="How do you feel about this product, share your comment with others!"
                                        onChange={(e) => setFieldValue('review', e.target.value)}
                                    />
                                    <p className="m-txt m-txt--small m-txt--error w-full">
                                        <ErrorMessage name="review" />
                                    </p>
                                </div>
                                <div className="account-reviews__input-wrapper items-start py-0 mt-0 mb-2">
                                    <p className="text-sm ml-2 mb-2">Click to upload photo</p>
                                </div>
                                <div className="account-reviews__input-wrapper account-reviews__input-wrapper--start px-8">
                                    <button
                                        className="account-reviews__upload-btn shadow-none"
                                        type="button"                                        
                                    >
                                        <span className="m-txt contents">
                                            {!values.image ? (
                                                <div
                                                    style={{
                                                        padding: '25px',
                                                        border: '2px dotted lightgray',
                                                    }}
                                                    className="rounded"
                                                    onClick={
                                                        onHandleTriggerUpload
                                                    }
                                                >
                                                    <IoAdd size={30} style={{ color: '#BBBBBB' }} />
                                                </div>
                                            ) : (
                                                <>
                                                    {values.image?.length < 5?
                                                    <div
                                                    style={{
                                                        padding: '25px',
                                                        border: '2px dotted lightgray',
                                                    }}
                                                    className="rounded"
                                                    onClick={
                                                        onHandleTriggerUpload
                                                    }
                                                >
                                                    <IoAdd size={30} style={{ color: '#BBBBBB' }} />
                                                </div>

                                                    :null}
                                                    {values.image?.length > 0 ? (
                                                        <>
                                                        {values.image.map((o, i) => {  
                                                            return( 
                                                        <div className="account-reviews__input-wrapper flex-row" style={{height:"84px"}}>
                                                           <div className="account-reviews__image table">
                                                                <div className="table-cell align-middle">
                                                                <div
                                                                    className="nav-bar__badge"
                                                                    style={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        borderRadius: '50px',
                                                                    }}
                                                                    onClick={()=> setFieldValue('image', values.image.filter(item => item !== o))}
                                                                >
                                                                    <IoRemove
                                                                        size={25}
                                                                        color="white"
                                                                    />
                                                                </div>
                                                                    <img
                                                                        className="object-contain"
                                                                        src={URL.createObjectURL(
                                                                            o
                                                                        )}
                                                                        style={{height:"84px"}}
                                                                        alt={'review_image'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>)
                                                            })}
                                                            </>
                                                    ) : null}
                                                </>
                                            )}
                                        </span>
                                    </button>
                                    <input
                                        hidden
                                        type="file"
                                        name="image"
                                        ref={uploadInputRef}
                                        onChange={(e) => {
                                            const imgArr = [];
                                            if(values.image){
                                                console.log(values.image)
                                                const existedArr = values.image;
                                                setFieldValue('image', existedArr.concat(e.target.files[0]));
                                            }
                                            else{
                                                imgArr.push(e.target.files[0]);
                                                setFieldValue('image', imgArr);
                                            }                                            
                                        }}
                                    />
                                </div>
                                <div className="account-reviews__button-wrapper">
                                    <Button
                                        text
                                        bold
                                        type="button"
                                        label="CANCEL"
                                        style={{ boxShadow: 'none' }}
                                        onClick={() => setReviewModalVisibility(false)}
                                    />
                                    <Button
                                        gold
                                        text
                                        bold
                                        label="SUBMIT"
                                        type="submit"
                                        style={{ boxShadow: 'none' }}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <AppBar
                position="static"
                style={{ background: 'none', boxShadow: 'none', color: 'black', marginTop: 20 }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    TabIndicatorProps={{
                        style: {
                            background: '#ffffff',
                            height: '100%',
                            width: '140px',
                            marginLeft: '10px',
                            borderTop: '1px solid lightGrey',
                            borderRight: '1px solid lightGrey',
                            borderLeft: '1px solid lightGrey',
                            zIndex: '-10',
                        },
                    }}
                >
                    <Tab
                        disableRipple
                        label={`To review (` + toReview.length + `)`}
                        {...a11yProps(0)}
                        style={{ boxShadow: 'none', textTransform: 'initial' }}
                    />
                    <Tab
                        disableRipple
                        label="Past reviews"
                        {...a11yProps(1)}
                        style={{ boxShadow: 'none', textTransform: 'initial' }}
                    />
                </Tabs>
            </AppBar>
            <TabPanel
                value={value}
                index={0}
                className="bg-white rounded-md mt-1"
                className="singleOrderTab"
            >
                {toReview?.length > 0 ? (
                    <div
                        className="bg-white"
                        style={{
                            border: '1px solid lightGrey',
                            marginLeft: '10px',
                            borderBottom: '0px',
                        }}
                    >
                        {toReview.map((o, i) => (
                            <ReviewCard toReview={o} openModal={onhandleReviewModal} />
                        ))}
                    </div>
                ) : (
                    <EmptyCard />
                )}
            </TabPanel>
            <TabPanel
                value={value}
                index={1}
                className="bg-white rounded-md mt-1"
                className="singleOrderTab"
            >
                {completedReviews.length > 0 ? (
                    <div
                        className="bg-white"
                        style={{
                            marginLeft: '10px',
                            borderBottom: '0px',
                            border: '1px solid lightGrey',
                        }}
                    >
                        {completedReviews.map((o, i) => {
                            if(o.image && o.image.includes("[")){
                                o.image = o.image.substring(1, o.image.length-1).split(',');
                            }              
                        return (
                            <div className="p-12" style={{ borderBottom: '1px solid lightGrey' }}>
                                <div className="product__reviews-content">
                                    <CircleAvatar
                                        size={50}
                                        src={o.productImage}
                                        onClick={() => onHandleNavigateProduct(o.productId)}
                                    />
                                    <div className="product__reviews-user">
                                        <p
                                            className="m-txt font-medium cursor-pointer"
                                            onClick={() => onHandleNavigateProduct(o.productId)}
                                        >
                                            {o.productName}
                                        </p>
                                        {o.productVariation ? (
                                            <p className="m-txt font-normal text-gray-700">
                                                {o.productVariation.replace(/:/g, ': ')}
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className="ml-auto">
                                        {moment(o.createdAt).format(orderDateFormat)}
                                    </div>
                                </div>
                                <p className="text-sm ml-20 font-medium my-3">
                                    Your product rating & review:
                                </p>
                                <p className="ml-18 mb-3">
                                    <RatingPicker value={o.rating} size={20} />
                                </p>
                                <p className="ml-20 mb-4">{o.review}</p>
                                {o.image && typeof o.image == 'string'   ? (
                                    <div
                                        className="product__reviews-image ml-20"
                                        style={{ backgroundImage: `url(${o.image})` }}
                                    />
                                ) 
                                :o.image &&typeof o.image != 'string'  ? (                                    
                                    <div className="flex ml-20">
                                        {o.image.map((imageItem, index) =>(
                                            <div
                                            className="product__reviews-image mx-4"
                                            style={{ backgroundImage: `url(${imageItem})` }}
                                        />
                                        ))}
                                    </div>
                                ) 
                                : 
                                null                                
                                }
                                {o.replies && o.replies != '' ? (
                                    <div
                                        className="product__reviews product__reviews--seller ml-20"
                                        style={{ width: '85%' }}
                                    >
                                        <p className="inline-block ml-2">Seller replied:</p>
                                        <div className="ml-auto w-auto inline-block float-right">
                                            {moment(o.replyAt).format(orderDateFormat)}
                                        </div>
                                        <div className="product__reviews-replies  ml-2">
                                            <p className="m-0 text-black">{o.replies}</p>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        )})}
                    </div>
                ) : (
                    <EmptyCard />
                )}
            </TabPanel>
        </>
    );
};
export default ReviewSection;

const EmptyCard = () => {
    return (
        <div className="home__products  container m-container m-container--center mt-0">
            <div
                className="m-wrapper bg-white"
                style={{ border: '1px solid lightGrey', marginLeft: '10px' }}
            >
                <img src={starIcon} className="m-auto mt-40 mb-4 w-12" />
                <p className="text-center mb-4 text-gray-400 mb-40">
                    There is no orders for {`\n`} review yet
                </p>
            </div>
        </div>
    );
};

const ReviewCard = ({ toReview, openModal }) => {
    const history = useHistory();
    const location = useLocation();
    const onHandleNavigateProduct = (id) => {
        const path = pathnames.productDetails.replace(':productId', id);
        history.push(path, { from: location.pathname });
    };

    return (
        <div>
            <div className="py-6 px-12" style={{ borderBottom: '1px solid lightGrey' }}>
                <div className="product__reviews-content">
                    <div className="mb-4">
                        <CircleAvatar
                            size={50}
                            src={toReview?.productImage}
                            onClick={() => onHandleNavigateProduct(toReview.productId)}
                        />
                    </div>
                    <div className="product__reviews-user">
                        <p
                            className="m-txt font-medium mb-0 cursor-pointer"
                            onClick={() => onHandleNavigateProduct(toReview.productId)}
                        >
                            {toReview.productName}
                        </p>
                        {toReview.productVariation ? (
                            <p className="text-sm mb-2" style={{ color: '#888888' }}>
                                {toReview.productVariation.replace(/:/g, ': ')}
                            </p>
                        ) : null}
                    </div>
                    <div className="ml-auto">
                        <Button
                            label="Review"
                            tiny
                            gold
                            style={{ boxShadow: 'none' }}
                            onClick={() => openModal(toReview)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
