import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import _ from 'lodash';
import { IoHeart, IoAdd, IoRemove } from 'react-icons/io5';

import BreadCrumb from 'components/breadcrumb/breadcrumb';
import ProductCard from 'components/product-card/product-card';
import ShareButton from 'components/share-button/share-button';
import RatingPicker from 'components/rating-picker/rating-picker';
import Button from 'components/button/button';
import { setWishProduct } from 'redux/actions/user-actions';
import { getUserNotificationSummary } from 'redux/actions/user-actions';
import { pathnames } from 'routes/routes';
import api from 'services/api';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import withLogin from 'context/with-login';
import withCart from 'context/with-cart';
import { useIsMount } from 'hooks/use-mount';
import ShowMoreText from 'react-show-more-text';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import moment from "moment";
import { FiChevronRight } from 'react-icons/fi';
const orderDateFormat = 'DD MMM YYYY';

const defaultSelectedVar = {
    variationOne: '',
    variationTwo: '',
    product: null,
};

const ProductPage = (props) => {
    const isMount = useIsMount();
    const history = useHistory();
    const location = useLocation();
    const { productId } = useParams();
    const dispatch = useDispatch();
    const [onSubmit, setOnSubmit] = useState(false);
    const submitBtnText = onSubmit ? 'ADDING' : 'ADD TO CART';
    const [product, setProduct] = useState();
    const [reviews, setReviews] = useState();
    const [companyInfo, setCompanyInfo] = useState();
    const [wished, setWished] = useState(false);
    const [isClamp, setIsClamp] = useState(false);
    const [isExpand, setIsExpand] = useState(false);
    const userAuth = useSelector((state) => state.user.auth);
    const userProfile = useSelector((state) => state.user.profile);
    const [selectedVar, setSelectedVar] = useState(defaultSelectedVar);
    const [purchaseQuantity, setPurchaseQuantity] = useState(1);
    const onHandleSetWishProduct = useCallback((o) => dispatch(setWishProduct(o)), [dispatch]);
    const onHandleGetNotificationSummary = useCallback(
        (o) => dispatch(getUserNotificationSummary(o)),
        [dispatch]
    );
    const [copyData, setCopyData] = useState();

    const onHandleGetProduct = useCallback(async () => {
        try {
            const response = await api.get.productDetails(productId);
            const reviewsResponse = await api.get.productReviews(productId);
            const results = response.data.result;
            setProduct(results);
            setWished(results.wishToHave);
            setReviews(reviewsResponse.data.result);
            setCompanyInfo({
                companyId: results.companyId,
                companyImage: results.companyImage,
                companyName: results.companyName,
                companyProductQuantity: results.companyProductQuantity,
            });
            console.log(response);
            console.log(reviewsResponse);
        } catch (error) {
        }
    }, [productId, props]);

    const createNotification = (type, message, title) => {
        switch (type) {
            case 'info':
                NotificationManager.info(message);
                break;
            case 'success':
                NotificationManager.success(message);
                break;
            case 'warning':
                NotificationManager.warning(message);
                break;
            case 'error':
                NotificationManager.error(title, message);
                break;
        }
    };

    useEffect(() => {
        if (isMount) {
            window.scrollTo(0, 0);
            onHandleGetProduct();
        }
    }, [isMount, onHandleGetProduct]);

    const onHandleWish = () => {
        if (!product) return;

        if (!userAuth) {
            props.onHandleToggleLogin();
        } else {
            setWished(!wished);
            if (!wished) {
                createNotification('success', 'Product added to wishlist');
            }
            onHandleSetWishProduct({
                userId: userProfile.id,
                productId: product.product.id,
            });
        }
    };

    const onHandleGoToStore = () => {
        const path = pathnames.merchart.replace(':merchantId', companyInfo.companyId);
        history.push(path, { companyInfo, from: location.pathname });
    };

    const onHandleQuantity = useCallback(
        (type) => {
            let totalStock = 0;
            const productItems = product.product;

            if (selectedVar && productItems?.variations) {
                totalStock = selectedVar.product?.stock;
            } else {
                totalStock = productItems?.stock;
            }
            switch (type) {
                case 'decrease':
                    if (purchaseQuantity - 1 > 0)
                        setPurchaseQuantity(parseInt(purchaseQuantity) - 1);
                    break;
                case 'increase':
                    if (purchaseQuantity + 1 <= totalStock)
                        setPurchaseQuantity(parseInt(purchaseQuantity) + 1);
                    break;
                default:
                    break;
            }
        },
        [purchaseQuantity, product, selectedVar]
    );

    const onHandleQuantityInput = useCallback(
        (e) => {
            const value = e.currentTarget.value;
            const selected = selectedVar.product;
            const productStock = product.product?.stock;
            let totalStock = 0;

            if (selected) {
                totalStock = selected?.stock;
            } else {
                totalStock = productStock;
            }

            if (value > totalStock) {
                setPurchaseQuantity(totalStock);
            } else {
                setPurchaseQuantity(value);
            }
        },
        [product, selectedVar]
    );

    const onHandleBlurQuantityInput = useCallback((e) => {
        const value = e.currentTarget.value;
        if (!value) {
            setPurchaseQuantity(1);
        }
    }, []);

    const onHandleBuyNow = async () => {
        if (!product) return;

        if (!userAuth) {
            props.onHandleToggleLogin();
        } else {
            const variationId = selectedVar.product?.id || null;
            const productId = product.product?.id;
            const payload = {
                userId: userProfile.id,
                productId: productId,
                quantity: purchaseQuantity,
                variationId: variationId,
            };

            try {
                const response = await api.post.buyNow(payload);
                const checkoutResponse = response.data.result;
                const cartItem = checkoutResponse.cartItem;
                const checkout = {
                    products: [cartItem],
                    shippingFee: checkoutResponse.shippingFee,
                    total: cartItem.productPrice + checkoutResponse.shippingFee,
                };
                history.push(pathnames.checkout, { checkout });
            } catch (error) {
            }
        }
    };

    const onBtnAddToCart = () => {
        if (!product) return;

        if (!userAuth) {
            props.onHandleToggleLogin();
        } else {
            setOnSubmit(true);
            const variationId = selectedVar.product?.id || null;
            const productId = product.product?.id;
            const payload = {
                userId: userProfile.id,
                productId: productId,
                quantity: purchaseQuantity,
                variationId: variationId,
            };
            props.onHandleUpdateCart(payload, (error) => {
                if (!error) {
                    onHandleGetNotificationSummary(userProfile.id);
                    setTimeout(function () {
                        createNotification('success', 'Added To Cart');
                    }, 1000);
                }
                setOnSubmit(false);
            });
        }
    };

    const onHandleSelectVar = (item, type) => {
        const options = [...product.product?.variations];
        const typeTwoOption = options[0].titles.length === 2;
        const selected = { ...selectedVar };
        const isOptionOne = type === 'variationOne';
        const isOptionTwo = type === 'variationOne';
        selected[type] = item;

        if (type === 'variationOne' && selected.variationTwo) {
            selected.variationTwo = '';
            selected.product = null;
        }

        const variationOne = isOptionOne ? item : selected.variationOne;
        const variationTwo = isOptionTwo ? item : selected.variationTwo;

        if (typeTwoOption && variationOne && variationTwo) {
            const getSelectedProduct = options.filter(
                (o) =>
                    o.variations.variationOne === variationOne &&
                    o.variations.variationTwo === variationTwo
            );

            selected.product = getSelectedProduct[0];
        }

        if (!typeTwoOption) {
            const getSelectedProduct = options.filter(
                (o) => o.variations.variationOne === variationOne
            );
            selected.product = getSelectedProduct[0];
        }

        setPurchaseQuantity(1);
        setSelectedVar(selected);
        getCopyData();
    };

    const onHandlePriceRange = () => {
        if (!product) return;

        if (product.product.variations && !selectedVar.product) {
            const options = [...product.product.variations];
            const prices = options.map((i) => i.price);
            const sorted = prices.slice().sort((a, b) => a - b);
            const lowest = sorted[0];
            const highest = sorted[sorted.length - 1];

            let priceTag = '';

            if (highest === lowest) {
                priceTag = `${highest.toFixed(2)}`;
            } else {
                priceTag = `${lowest.toFixed(2)} - ${highest.toFixed(2)} `;
            }
            return priceTag;
        } else if (product.product.variations && !selectedVar.product) {
            const selected = selectedVar.product;
            return (selected.price * purchaseQuantity).toFixed(2);
        } else {
            const productPrices = product?.product?.price;
            return (productPrices * purchaseQuantity).toFixed(2);
        }
    };

    const isDisabled = () => {
        if (!product || onSubmit) return true;

        if (selectedVar.product || product.product.variations) {
            return !selectedVar?.product?.stock;
        } else {
            return !product?.product?.stock;
        }
    };

    const getCopyData = () => {
        setCopyData("Hey there, I found there is " + product?.product?.name + " with just RM " + onHandlePriceRange() + " at " + window.location.href);
    }

    const onHandleShow = (type) => {
        if(type == 'more'){
            setIsClamp(true);
            setIsExpand(true);
        }
        else if (type == 'less'){
            setIsClamp(false);
            setIsExpand(false);
        }
    }

    return (
        <>
            <NotificationContainer />
            <div className="product">
                <div className="container m-container m-container--center">
                    <div className="m-wrapper">
                        <BreadCrumb
                            paths={[
                                { linkTo: '/', text: 'Home' },
                                { onClick: ()=>history.push(pathnames.productCategory), text: 'Products' },
                                'Product Details',
                            ]}
                        />
                    </div>
                    <div className="text-3xl font-medium m-wrapper">Product details</div>
                    <div className="m-card w-full">
                        <div className="product__section product__section--justify-between product__section--divider p-12 mb-0 pb-8">
                            <div className="product__header">
                                <p className="product__header-txt m-txt m-txt--m m-txt--bold">
                                    {product?.product?.name}
                                </p>
                                <p className="product__header-txt m-txt m-txt--bold m-txt--m m-txt--error">
                                    RM {onHandlePriceRange()}
                                </p>
                            </div>
                            <div className="product__header--action mt-5">
                                <div className="product__header-txt product__rating m-txt m-txt--m m-txt--bold mx-8">
                                    {product?.product?.rating}
                                    <span className="m-txt m-txt--s"> / 5</span>
                                    <RatingPicker value={product?.product?.rating} />
                                </div>
                                
                                <IoHeart
                                    size={30}
                                    className={
                                        wished
                                            ? `product__heart product__heart--active`
                                            : 'product__heart'
                                    }
                                    style={{ cursor: 'pointer', marginRight: '20px' }}
                                    onClick={onHandleWish}
                                />
                                <div onClick={()=>getCopyData()}>
                                <ShareButton url={window.location.href} copyData={copyData}/>
                                </div>
                            </div>
                        </div>

                        <div className="product__card m-card pt-8">
                            <section className="product__carousel-section">
                                {product ? (
                                    <Carousel
                                        className="product__carousel"
                                        showStatus={false}
                                        showArrows={true}
                                        showThumbs={false}
                                    >
                                        {product.product.productImages.map((o, i) => (
                                            <div
                                                key={`${i}-carousel-product`}
                                                className="product__carousel-item"
                                                style={{ backgroundImage: `url(${o})` }}
                                            />
                                        ))}
                                        {product.product.productVideos.map((o, i) => (
                                            <iframe key={i} title="product video" src={o}></iframe>
                                        ))}
                                    </Carousel>
                                ) : null}
                            </section>
                            <section>
                                <div className="product__section product__section--column">
                                    <p className="m-txt product__txt--label">Product description</p>
                                    {/* <ShowMoreText
                                        lines={5}
                                        more="Show more"
                                        less="Show less"
                                        className="m-txt break-all font-medium"
                                        anchorClass="font-bold hover:text-yellow-400"
                                        expanded={false}
                                        truncatedEndingComponent={'... '}
                                    > */}
                                    {!isExpand?
                                        <div
                                            id="productDesc"
                                            className="m-txt break-all font-medium"
                                            dangerouslySetInnerHTML={{
                                                __html: `<span>${product?.product?.description}</span>`,
                                            }}
                                            style={{
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                display: '-webkit-box',
                                                '-webkit-line-clamp': '5',
                                                '-webkit-box-orient': 'vertical',
                                            }}
                                        />
                                        :<>
                                        <div
                                        className="m-txt break-all font-medium"
                                        dangerouslySetInnerHTML={{
                                            __html: `<span>${product?.product?.description}</span>`,
                                        }}
                                    />
                                     <div
                                            id="productDesc"
                                            className="m-txt break-all font-medium"
                                            dangerouslySetInnerHTML={{
                                                __html: `<span>${product?.product?.description}</span>`,
                                            }}
                                            style={{
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                color: 'transparent',
                                                zIndex: '-500',
                                                position: 'absolute',
                                                display: '-webkit-box',
                                                '-webkit-line-clamp': '5',
                                                '-webkit-box-orient': 'vertical',
                                            }}
                                        />
                                    </>}
                                        {!isClamp && document.getElementById('productDesc')?.clientHeight < document.getElementById('productDesc')?.scrollHeight?
                                        <p onClick={()=> onHandleShow('more')} className="cursor-pointer font-bold hover:text-yellow-400">Show more</p>
                                        :isClamp?
                                        <p onClick={()=> onHandleShow('less')} className="cursor-pointer font-bold hover:text-yellow-400">Show less</p>
                                        :null}
                                    {/* </ShowMoreText> */}
                                    <p className="m-txt font-normal mt-4 font-medium">
                                        SKU: <span>{product?.product?.sku.slice(3)}</span>
                                    </p>
                                </div>

                                <div className="product__section product__section--column">
                                    <p className="m-txt product__txt--label">Brand</p>
                                    <p className="m-txt">{product?.product?.brand || '-'}</p>
                                </div>
                                {product && product.product?.variations ? (
                                    <div className="product__section product__section--column product__section--divider">
                                        <div className="product__content product__content">
                                            <ProductVariations
                                                data={product.product.variations}
                                                selected={selectedVar}
                                                type="variationOne"
                                                onSelect={onHandleSelectVar}
                                            />
                                        </div>
                                        <div className="product__content product__content">
                                            {selectedVar.variationOne &&
                                            Object.keys(product.product.variationLevels).length >
                                                1 ? (
                                                <ProductVariations
                                                    data={product.product.variations}
                                                    selected={selectedVar}
                                                    type="variationTwo"
                                                    onSelect={onHandleSelectVar}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}

                                <div className="product__section product__section--divider">
                                    <div className="product__quantity-ctrl">
                                        <p className="m-txt">
                                            Quantity
                                        </p>
                                        <div className="product__quantity-wrapper m-auto">
                                            <button
                                                className="product__quantity-btn product__quantity-btn--outline"
                                                onClick={() => onHandleQuantity('decrease')}
                                                disabled={purchaseQuantity === 1}
                                            >
                                                <IoRemove />
                                            </button>
                                            <input
                                                type="number"
                                                className="product__quantity-input"
                                                value={purchaseQuantity}
                                                onChange={onHandleQuantityInput}
                                                onBlur={onHandleBlurQuantityInput}
                                                disabled={isDisabled()}
                                            />
                                            <button
                                                className="product__quantity-btn"
                                                onClick={() => onHandleQuantity('increase')}
                                                disabled={isDisabled()}
                                            >
                                                <IoAdd />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="product__section product__section--footer">
                                    <Button
                                        label="BUY NOW"
                                        outline
                                        small
                                        disabled={isDisabled()}
                                        onClick={onHandleBuyNow}
                                    />
                                    <Button
                                        label={submitBtnText}
                                        small
                                        disabled={isDisabled()}
                                        onClick={onBtnAddToCart}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>

                    {reviews && companyInfo ? (
                        <div className="product__card product__card--column m-card mt-4">
                            <p className="m-txt m-txt--m m-txt--bold">
                                Ratings & Reviews ({reviews.records && reviews.records.length})
                            </p>
                            
                            <div className="product__header-txt product__rating product__rating--reviews m-txt m-txt--m m-txt--bold">
                                {reviews?.ratings}
                                <span className="m-txt m-txt--s"> / 5</span>
                                <RatingPicker value={product?.product?.rating} />
                                <div className="ml-auto text-lg cursor-pointer" onClick={()=>history.push(
                                            pathnames.productReview.replace(
                                                ':productId',
                                                product.product.id
                                            )
                                        )}>View all <FiChevronRight className="inline-block"/></div>
                            </div>
                            
                            {reviews.records.map((o, i) => {
                                const key = `${i}-reviews`;
                                if (o.image && o.image.includes('[')) {
                                    o.image = o.image.substring(1, o.image.length - 1).split(',');
                                }
                                return (
                                    <>{i < 3?
                                    <div className="product__reviews mb-4" key={key}>
                                        <div className="product__reviews-content">
                                            <CircleAvatar size={50} src={o.userImage} />
                                            <div className="product__reviews-user">
                                                <p className="m-txt font-medium">{o.userName}</p>
                                                {o.productVariation?<p className="m-txt font-normal text-gray-700">{o.productVariation}</p>:null}
                                                <RatingPicker value={o.rating} size={18} />
                                            </div>
                                            <div className="ml-auto">
                                                {moment(o.createdAt).format(orderDateFormat)}
                                            </div>
                                        </div>
                                        <p className="product__reviews-comment m-txt ml-16">{o.review}</p>
                                        {o.image && typeof o.image == 'string'   ? (
                                    <div
                                        className="product__reviews-image"
                                        style={{ backgroundImage: `url(${o.image})` }}
                                    />
                                ) 
                                :o.image &&typeof o.image != 'string'  ? (                                    
                                    <div className="flex">
                                        {o.image.map((imageItem, index) =>(
                                            <div
                                            className="product__reviews-image mx-4"
                                            style={{ backgroundImage: `url(${imageItem})` }}
                                        />
                                        ))}
                                    </div>
                                ) 
                                : 
                                null                                
                                }
                                        {o.replies && o.replies!= ""?
                                        <div className="product__reviews product__reviews--seller ml-24" style={{width: "85%"}}>
                                            <p className="m-txt m-txt--bold inline-block">Seller replied</p>
                                            <div className="ml-auto w-auto inline-block float-right">
                                                {moment(o.replyAt).format(orderDateFormat)}
                                            </div>
                                            <div className="product__reviews-replies">
                                                <p className="m-0 font-medium text-black">{o.replies}</p>
                                            </div>                                           
                                        </div>:null}
                                    </div>:null}</>
                                );
                            })}
                        </div>
                    ) : null}

                    {companyInfo ? (
                        <div className="product__card m-card">
                            <div className="product__company">
                                <p className="m-txt m-txt--m m-txt--bold">Seller</p>
                                <div className="product__company-content">
                                    <div className="product__company-wrapper">
                                        <div
                                            className="product__company-image"
                                            style={{
                                                backgroundImage: `url(${companyInfo.companyImage})`,
                                            }}
                                        />
                                        <p className="m-txt m-txt--bold">
                                            {companyInfo.companyName}
                                        </p>
                                        <p className="m-txt m-txt--grey">
                                            {companyInfo.companyProductQuantity} products
                                        </p>
                                    </div>
                                    <Button
                                        label="Visit Store"
                                        gold
                                        small
                                        onClick={onHandleGoToStore}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="container m-container m-container--center">
                    {product && product.similarProducts ? (
                        <div className="m-wrapper">
                            <div className="product__card-header">
                                <p className="m-txt m-txt--xl m-txt--bold">Similar Products</p>
                                <Button
                                    label="SHOP MORE"
                                    onClick={() =>
                                        history.push(
                                            pathnames.productCategory.replace(
                                                ':categoryId',
                                                product.product.category
                                            )
                                        )
                                    }
                                />
                            </div>
                            <div className="product__card-wrapper">
                                <ProductCard
                                    title="Similar Products"
                                    products={product.similarProducts}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default withLogin(withCart(ProductPage));

const ProductVariations = ({ data, type, selected, onSelect }) => {
    if (!data) return null;

    const options = _.uniq(data.map((o) => o.variations[type]));
    const typeTwoOption = data[0].titles.length === 2;

    return (
        <div className="product__vars">
            <p className="m-txt product__txt--label">
                {data[0].titles[type === 'variationOne' ? 0 : 1]}
            </p>
            <div className="product__vars-wrapper">
                {options.map((item, i) => {
                    let disabled = false;
                    const selectedProduct = data.filter(
                        (o) => o.variations.variationOne === selected.variationOne
                    );

                    if (typeTwoOption && selected.variationOne) {
                        const equalToCurrentItem = selectedProduct.filter(
                            (o) => o.variations.variationTwo === item
                        );

                        if (equalToCurrentItem[0] && equalToCurrentItem[0].stock === 0) {
                            disabled = true;
                        }
                    } else {
                        if (selectedProduct[0] && selectedProduct[0].stock === 0) {
                            disabled = true;
                        }
                        if (!typeTwoOption && data[i].stock === 0) {
                            disabled = true;
                        }
                    }

                    const optionsClassName =
                        selected[type] === item && !disabled
                            ? `product__var-item product__var-item--active`
                            : !disabled
                            ? 'product__var-item'
                            : `product__var-item product__var-item--disabled`;

                    return (
                        <div
                            key={`${i}-v-${type}`}
                            className={optionsClassName}
                            onClick={() => onSelect(item, type)}
                        >
                            <p className="m-txt m-txt--s">{item}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
