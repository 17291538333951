import React, { Fragment, useRef, useCallback, useMemo, memo, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    IoPersonOutline,
    IoSearchOutline,
    IoCartOutline,
    IoNotificationsOutline,
    IoChevronDownOutline,
    IoSettingsOutline,
    IoLogOutOutline,
    IoNotifications,
} from 'react-icons/io5';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import logo from 'assets/navbar/logo.png';
import { setUserLogout, getUserNotificationSummary } from 'redux/actions/user-actions';
import { pathnames, routes } from 'routes/routes';
import usePrevious from 'hooks/use-previous';
import api from 'services/api';
import { formatDate } from 'helpers/utility';
import Popup from 'components/popup/popup';
import { isMobile } from 'react-device-detect';

const NavBar = memo(() => {
    const scrollRef = useRef();
    const history = useHistory();
    const pagesRef = useRef({ size: 100, page: 0 });
    var totalPages = 0;
    const dispatch = useDispatch();
    const location = useLocation();
    const [hide, setHide] = useState(true);
    const previousPath = usePrevious(location.pathnames);
    const { auth, profile, notifications } = useSelector((state) => state.user);
    const onHandleGetNotificationSummary = useCallback(
        (o) => dispatch(getUserNotificationSummary(o)),
        [dispatch]
    );
    const [notification, setNotifications] = useState([]);
    const [notificationHover, setNotificationHover] = useState(false);
    const [showAnnouncement, setShowAnnouncement] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState();

    const getActiveNav = useCallback(
        (path) => {
            if (Array.isArray(path)) {
                return !path.includes(location.pathname)
                    ? 'nav-bar__link'
                    : 'nav-bar__link nav-bar__link--active';
            } else {
                return location.pathname !== path
                    ? 'nav-bar__link'
                    : 'nav-bar__link nav-bar__link--active';
            }
        },
        [location]
    );

    useEffect(() => {
        // Hide And Show Nav Features
        if (previousPath !== location.pathname) {
            const current = routes.filter((o) => o.path === location.pathname);
            if (current[0]) {
                setHide(!current[0].nav);
            }
        }
    }, [previousPath, location]);

    useEffect(() => {
        // Get Summary Notifications
        if (previousPath !== location.pathname && profile) {
            onHandleGetNotificationSummary(profile.id);
        }
    }, [profile, previousPath, location, onHandleGetNotificationSummary]);

    useEffect(() => {
        if (auth) {
            fetchNotifications();
        }
    }, []);

    const fetchNotifications = async () => {
        try {
            let payload = {
                ...pagesRef.current,
            };
            const resp = await api.get.notifications(profile.id, payload);
            totalPages = resp.data.result.totalPage;

            if (pagesRef.current.page == 0) {
                setNotifications(resp.data.result.notifications);
            } else {
                setNotifications((o) => {
                    return [...o, ...resp.data.result.notifications];
                });
            }
        } catch (error) {
            alert(error);
            console.log(error);
            setNotifications([]);
        }
    };

    const onHandleScrollGetNotification = () => {
        if (pagesRef.current.page + 1 < totalPages) {
            pagesRef.current.page += 1;
            fetchNotifications();
        }
    };

    useEffect(() => {
        const onHandleScroll = (e) => {
            const target = e.target;
            const scrollHeight = target.scrollHeight - target.clientHeight;
            const currentScroll = Math.round(target.scrollTop);

            if (scrollHeight <= currentScroll) {
                onHandleScrollGetNotification();
            }
        };
        scrollRef?.current?.addEventListener('scroll', onHandleScroll);
    }, []);

    const notificationDropdown = () => {
        return (
            <div className="nav-bar__notification--dropdown" ref={scrollRef}>
                <ul>
                    {notification?.map((o, i) => {
                        return (
                            <li key={i} onClick={() => handleNotificationRedirect(o)}>
                                <div
                                    className={
                                        o.status == 'unread'
                                            ? 'nav-bar__notification--item'
                                            : 'nav-bar__notification--item nav-bar__notification--read'
                                    }
                                >
                                    <div className="nav-bar__notification--content">
                                        <p className="m-txt m-txt--bold">{o.title}</p>
                                        {o.notificationType == 'order' ? (
                                            <p className="m-txt m-txt--xs">
                                                Order no:
                                                <span className="m-txt m-txt--bold">
                                                    {' '}
                                                    {o.orderNumber}
                                                </span>
                                            </p>
                                        ) : (
                                            <p
                                                className="m-txt m-txt--xs"
                                                style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    display: '-webkit-box',
                                                    '-webkit-line-clamp': '2',
                                                    '-webkit-box-orient': 'vertical',
                                                }}
                                            >
                                                {o.description}
                                            </p>
                                        )}
                                    </div>
                                    <div className="nav-bar__notification--date">
                                        <p className="m-txt m-txt--xs">{formatDate(o.updatedAt)}</p>
                                        {o.status == 'unread' ? (
                                            <span className="greenDot"></span>
                                        ) : null}
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    const handleNotificationRedirect = async (item) => {
        try {
            const payload = {
                adminId: null,
                customerId: profile.id,
                merchantId: null,
                notificationId: item.id,
                notificationType: item.notificationType,
            };
            await api.post.readNotification(payload);
            fetchNotifications();
        } catch (error) {
            console.log(error);
        }

        switch (item.notificationType) {
            case 'order':
                history.push(pathnames.accountOrderDetails, { orderNumber: item.orderNumber, editMode: true });
                window.location.reload();
                break;
            case 'announcement':
                setSelectedAnnouncement(item);
                setShowAnnouncement(true);
                break;
            default:
                break;
        }
    };
    return (
        <>
            {!isMobile ? (
                <nav className="nav-bar">
                    <div className="nav-container">
                        <Link to="/">
                            <img className="nav-bar__logo" src={logo} alt="" />
                        </Link>
                        {!hide ? (
                            <Fragment>
                                <Link className={getActiveNav('/')} to="/">
                                    Shopping
                                </Link>
                                <div className="nav-bar__divider" />
                                <Link className={getActiveNav('/live-stream')} to="/live-stream">
                                    Live Stream
                                </Link>
                                <SearchInput />
                                {auth ? (
                                    <Fragment>
                                        <Link className={getActiveNav('/cart')} to="/cart">
                                            {notifications && notifications.cartsCount ? (
                                                <div className="nav-bar__badge">
                                                    {notifications.cartsCount}
                                                </div>
                                            ) : null}
                                            <IoCartOutline size={20} />
                                        </Link>
                                        <div className="nav-bar__divider" />
                                        <div
                                            className="nav-bar__notification"
                                            onMouseEnter={() => setNotificationHover(true)}
                                            onMouseLeave={() => setNotificationHover(false)}
                                        >
                                            <span className="redDot"></span>
                                            {notificationHover ? (
                                                <IoNotifications size={20} color={'white'} />
                                            ) : (
                                                <IoNotificationsOutline size={20} color={'white'} />
                                            )}
                                            {notificationDropdown()}
                                        </div>

                                        <Popup
                                            open={showAnnouncement}
                                            onPopupClose={() => setShowAnnouncement(false)}
                                        >
                                            <div className="announcement__popup bg-white">
                                                <div className="announcement__popup--msg">
                                                    <p className="m-txt m-txt--m m-txt--bold">
                                                        Announcement
                                                    </p>
                                                    <p className="m-txt m-txt--xs">
                                                        {formatDate(
                                                            selectedAnnouncement?.updatedAt
                                                        )}
                                                    </p>
                                                    <p className="m-txt">
                                                        {selectedAnnouncement?.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </Popup>
                                    </Fragment>
                                ) : null}
                                <div className="nav-bar__divider" />
                                {auth ? (
                                    <div className="nav-bar__account-wrapper">
                                        <Link
                                            to="/accounts"
                                            className={`${getActiveNav([
                                                '/accounts',
                                                '/account/settings/profile',
                                                '/account/settings/address',
                                                '/account/settings/resetPassword',
                                            ])} nav-bar__link--account`}
                                        >
                                            <IoPersonOutline size={18} />
                                            <span>My Account</span>
                                            <IoChevronDownOutline size={18} />
                                        </Link>
                                        <AccountDropdown getActiveNav={getActiveNav} />
                                    </div>
                                ) : (
                                    <Fragment>
                                        <Link className="nav-bar__link" to={pathnames.register}>
                                            Sign up
                                        </Link>
                                        <div className="nav-bar__divider" />
                                        <Link className="nav-bar__link" to={pathnames.login}>
                                            Login
                                        </Link>
                                    </Fragment>
                                )}
                            </Fragment>
                        ) : null}
                    </div>
                </nav>
            ) : null}
        </>
    );
});

export default NavBar;

const AccountDropdown = memo(({ getActiveNav }) => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const logout = useCallback(() => dispatch(setUserLogout()), [dispatch]);
    const history = useHistory();
    const onHandleLogout = () => {
        logout();
        localStorage.clear();
        history.push(pathnames.home);
    };

    const redirectNav = (path, label) => {
        if(label == 'Profile' || label == 'Account Settings'){
            history.push(path, { currentTab: 'profile'});
           // window.location.reload();
           console.log(JSON.stringify(state));
        }
    }

    const accountSettings = [
        {
            to: '/account/settings/profile',
            label: 'Profile',
            icon: <IoPersonOutline size={18} />,
        },
        {
            to: '/account/settings/profile',
            label: 'Account Settings',
            icon: <IoSettingsOutline size={18} />,
        },
        {
            label: 'Logout',
            to: '',
            icon: <IoLogOutOutline size={18} />,
            onClick: onHandleLogout,
        },
    ];

    return (
        <div className="nav-bar__dropdown">
            <ul>
                {accountSettings.map((o, i) => {
                    return (
                        <li key={`${i}-${o.to}`} onClick={o.onClick} className="cursor-pointer">
                            <div className={getActiveNav(o.to)} onClick={()=>redirectNav(o.to, o.label)}>
                                {o.icon}
                                <span>{o.label}</span>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});

const SearchInput = memo(() => {
    const history = useHistory();
    const initialValues = useMemo(() => ({ query: '' }), []);
    const formikRef = useRef();

    const onHandleSubmit = (values) => {
        formikRef.current.resetForm();
        history.push(pathnames.productCategory, { query: values.query });
    };

    return (
        <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onHandleSubmit}>
            {() => (
                <Form className="nav-bar__search">
                    <IoSearchOutline size={20} />
                    <Field type="text" placeholder="Search" name="query" />
                </Form>
            )}
        </Formik>
    );
});
