import React from 'react';
import api from 'services/api';

const withCart = (WrappedComponent) => ({ ...props }) => {

    const onHandleUpdateCart = async (data, cb) => {
        try {
            await api.post.updateCart(data);
            if (typeof cb === "function") {
                cb();
            }
        }
        catch (error) {
            if (typeof cb === "function") {
                cb(error);
            }
        }
    }

    return <WrappedComponent {...props} onHandleUpdateCart={onHandleUpdateCart} />
};
export default withCart;
