import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import Button from 'components/button/button';
import Error from 'components/error/error';
import TextInput from 'components/inputs/text-input/text-input';
import overviewImage from 'assets/login/overview.svg';

import api from 'services/api';
import { pathnames } from 'routes/routes';

const initialValues = {
    email: '',
};
const validationSchema = yup.object().shape({
    email: yup.string().email().required('Email is required.'),
});

const ForgotPasswordPage = (props) => {
    const [error, setError] = useState('');
    const [onSubmit, setOnSubmit] = useState(false);
    const submitBtnText = onSubmit ? "SUBMITING" : "SUBMIT";


    const onHandleSubmit = async (values) => {
        setOnSubmit(true);
        try {
            await api.post.forgetPassword(values);
            props.history.push(pathnames.forgotPasswordVerification, values);
        } catch (error) {
            setError(error.response.data.message);
        } finally {
            setOnSubmit(false);
        }
    };

    return (
        <div className="forgot-password m-full-h">
            <div className="container m-container m-container--center">
                <div className="forgot-password__card m-card">

                    <section className="forgot-password__section">
                        <img src={overviewImage} alt="" />
                    </section>

                    <div className="forgot-password__divider" />
                    <section className="forgot-password__section">
                        <p className="forgot-password__headline m-txt m-txt--xl m-txt">Forget Password</p>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onHandleSubmit}
                        >
                            {() => (
                                <Form>
                                    <TextInput
                                        type="text"
                                        name="email"
                                        placeholder="Email address"
                                        disabled={onSubmit}
                                    />

                                    <Error msg={error} />

                                    <div className="forgot-password__submit">
                                        <Button label={submitBtnText} type="submit" disabled={onSubmit} />
                                    </div>
                                </Form>
                            )}

                        </Formik>

                        <p className="forgot-password__login m-txt m-txt--s">
                            Back to{' '}
                            <Link to={pathnames.login} className="m-link">
                                <b>login</b>
                            </Link>
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
