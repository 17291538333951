import React from 'react';
import ShareButton from 'components/share-button/share-button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import toPay from 'assets/icons/icon_to_pay.svg';
import toShip from 'assets/icons/icon_to_ship.svg';
import toReceive from 'assets/icons/icon_to_receive.svg';
import toReview from 'assets/icons/icon_to_review.svg';
import { pathnames } from 'routes/routes';
import { FiChevronRight } from 'react-icons/fi';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import config from 'helpers/config';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { IoLogoWhatsapp, IoLogoFacebook, IoLogoTwitter } from 'react-icons/io5';
import Button from 'components/button/button';

const AccountPage = () => {
    const history = useHistory();
    const { profile, notifications } = useSelector((state) => state.user);
    const url = window.location.href;
    const onHandleNavigate = (path, status) => {
        localStorage.setItem("myOrderStatus", status);
        history.push(path);
    };

    return (
        <div className="account">
            <div className="m-container m-container--center">
                <div className="account__wrapper m-wrapper">
                    <div className="account__header">
                        <CircleAvatar src={profile.image} size={120} />
                        <p className="account__name">{profile.displayName}</p>
                    </div>
                    <div className="account__body" style={{width:"850px"}}>
                        <p className="m-txt m-txt--bold inline-block">My Orders</p>
                        <p className="m-txt m-txt--bold inline-block float-right pr-10 underline cursor-pointer"  onClick={() =>
                                    onHandleNavigate(
                                        pathnames.accountOrders, "all"
                                    )
                                }>View All</p>
                        <div className="account__card-items">
                            <div
                                className="account__card"
                                onClick={() =>
                                    onHandleNavigate(
                                        pathnames.accountOrders, "pendingPayment"
                                    )
                                }
                            >
                                <div className="account__card-wrapper">
                                    <img src={toPay} alt="to_pay" />
                                    <p className="m-txt m-txt--bold m-txt--s">To Pay</p>
                                    {notifications && notifications.toPayCount ? (
                                        <div className="account__badge">
                                            {notifications.toPayCount}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div
                                className="account__card"
                                onClick={() =>
                                    onHandleNavigate(
                                        pathnames.accountOrders, "packing"
                                    )
                                }
                            >
                                <div className="account__card-wrapper">
                                    <img src={toShip} alt="to_ship" />
                                    <p className="m-txt m-txt--bold m-txt--s">To Ship</p>
                                    {notifications && notifications.toShipCount ? (
                                        <div className="account__badge">
                                            {notifications.toShipCount}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div
                                className="account__card"
                                onClick={() =>
                                    onHandleNavigate(
                                        pathnames.accountOrders, "shipped"
                                    )
                                }
                            >
                                <div className="account__card-wrapper">
                                    <img src={toReceive} alt="to_receive" />
                                    <p className="m-txt m-txt--bold m-txt--s">To Receive</p>
                                    {notifications && notifications.toReceiveCount ? (
                                        <div className="account__badge">
                                            {notifications.toReceiveCount}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div
                                className="account__card"
                                onClick={() => onHandleNavigate(pathnames.accountOrders, "delivered")}
                            >
                                <div className="account__card-wrapper">
                                    <img src={toReview} alt="to_review" />
                                    <p className="m-txt m-txt--bold m-txt--s">To Review</p>
                                    {notifications && notifications.toReviewCount ? (
                                        <div className="account__badge">
                                            {notifications.toReviewCount}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="account__footer">
                        <div
                            className="bg-white p-8 cursor-pointer mb-4 acc-bar-width"
                            onClick={() => history.push('/account/wishlist')}
                        >
                            <Link to="/account/wishlist">My Wishlist</Link>
                            <div className="inline-block float-right">
                                <FiChevronRight className="text-gray-500 text-2xl" />
                            </div>
                        </div>
                        <div
                            className="bg-white p-8 cursor-pointer mb-4 acc-bar-width"
                            onClick={() => history.push('/account/recent-viewed')}
                        >
                            <Link to="/account/recent-viewed">My Recent Viewed</Link>
                            <div className="inline-block float-right">
                                <FiChevronRight className="text-gray-500 text-2xl" />
                            </div>
                        </div>
                        <div
                            className="bg-white p-8 cursor-pointer mb-4 acc-bar-width"
                            onClick={() => history.push('/account/reviews?to=2')}
                        >
                            <Link to="/account/reviews?to=2">My Reviews</Link>
                            <div className="inline-block float-right">
                                <FiChevronRight className="text-gray-500 text-2xl" />
                            </div>
                        </div>
                        <div className="bg-white p-8 cursor-pointer mb-4 acc-bar-width">
                            <p className="inline-block pt-3">Share To Friend</p>
                            <div className="inline-block float-right">
                                <div className="inline-block">
                                    <p className="m-txt m-txt--bold inline-block mr-2">Share via:</p>
                                    <FacebookShareButton url={url} className="align-middle mx-2">
                                        <IoLogoFacebook size={24} color="#4267B2" className="m-0" />
                                    </FacebookShareButton>
                                    <TwitterShareButton url={url} className="align-middle mx-2">
                                        <IoLogoTwitter size={24} color="#1DA1F2" className="m-0" />
                                    </TwitterShareButton>
                                    <WhatsappShareButton url={url} className="align-middle mx-2">
                                        <IoLogoWhatsapp size={24} color="#25D366" className="m-0" />
                                    </WhatsappShareButton>
                                    <p className="inline-block mx-8">or</p>
                                    <div className="inline-block">
                                        <CopyToClipboard
                                            text={
                                                'Download e-commerce now to enjoy free shipping and lowest price guaranteed products:' +
                                                '\n' +
                                                'Apple AppStore: ' +
                                                '\n' +
                                                config.link.app.ios +
                                                '\n' +
                                                'Google Play store: ' +
                                                '\n' +
                                                config.link.app.android
                                            }
                                        >
                                            <Button label="Copy link" textWithBorder small copy style={{width: "200%", marginRight: "50px"}}/>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountPage;
