import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import rootSaga from './sagas';
import rootReducers from './reducers';

const sagaMiddleware = createSagaMiddleware();
const reduxLoggerOptions = { collapsed: true };
const reduxLogger = createLogger(reduxLoggerOptions);

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);
let store = createStore(persistedReducer, applyMiddleware(sagaMiddleware, reduxLogger));
let persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export { store, persistor };
