export const info = {
    INFO_SHOW: 'INFO_SHOW',
    INFO_HIDE: "INFO_HIDE",
}

export const setInfoShow = (msg, error) => {
    alert("k")
    return {
        type: info.INFO_SHOW,
        data: {
            msg,
            error,
            success: !error
        }
    };
}

export const setInfoHide = () => {
    return {
        type: info.INFO_HIDE,
    };
}