import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import { useIsMount } from 'hooks/use-mount';
import api from 'services/api';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { FiChevronRight } from 'react-icons/fi';
import { orderStatusFormat, formatDate } from 'helpers/utility';
import { pathnames } from 'routes/routes';
import Button from 'components/button/button';
import noProductIcon from 'assets/notfound/empty-cart-icon.png';
import Modal from 'react-responsive-modal';
import orderCancelIcon from 'assets/products/order-cancel-icon.png';
import RatingPicker from 'components/rating-picker/rating-picker';
import ReviewSection from 'pages/common/reviewSection/reviewSection';
import { Scrollbars } from 'rc-scrollbars';
import config from 'helpers/config';
import { IoClose, IoAdd, IoRemove } from 'react-icons/io5';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
const orderDateFormat = 'DD MMM YYYY';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const getStatus = (params) => {
    switch (params) {
        case 'all':
            return {
                id: 0,
                title: 'All',
            };
        case 'pendingPayment':
            return {
                id: 1,
                title: 'To Pay',
            };
        case 'toPack':
            return {
                id: 2,
                title: 'To Pack',
            };
        case 'packing':
            return {
                id: 3,
                title: 'To Ship',
            };
        case 'shipped':
            return {
                id: 4,
                title: 'To Receive',
            };
        case 'delivered':
            return {
                id: 5,
                title: 'To Review',
            };
        default:
            break;
    }
};

const getTab = (params) => {
    switch (params) {
        case 0:
            return {
                status: 'all',
            };
        case 1:
            return {
                status: 'pendingPayment',
            };
        case 2:
            return {
                status: 'toPack',
            };
        case 3:
            return {
                status: 'packing',
            };
        case 4:
            return {
                status: 'shipped',
            };
        case 5:
            return {
                status: 'delivered',
            };
        default:
            break;
    }
};

const EmptyCard = () => {
    return (
        <div className="home__products  container m-container m-container--center mt-0">
            <div className="m-wrapper">
                <img src={noProductIcon} className="m-auto mt-40 mb-4 w-12" />
                <p className="text-center mb-4 text-gray-400 mb-40">Sorry, no any order yet</p>
            </div>
        </div>
    );
};

const AccountOrdersPage = (props) => {
    const { id } = useSelector((state) => state.user.profile);
    const history = useHistory();
    const [orders, setOrders] = useState([]);
    const status = localStorage.getItem('myOrderStatus')
        ? localStorage.getItem('myOrderStatus')
        : 'all';
    const isMount = useIsMount();
    const [value, setValue] = React.useState(getStatus(status).id);
    const onHandleGetStatus = useCallback(async () => {
        try {
            const response = await api.get.orders({
                id,
                status: localStorage.getItem('myOrderStatus'),
            });
            const deliveredResponse = await api.get.toReview(id);
            setOrders(response.data.result);
            localStorage.setItem('toReviewAmount', deliveredResponse.data.result.length);
            console.log(response.data);
        } catch (error) {}
    }, [id, props]);

    useEffect(() => {
        if (isMount) {
            onHandleGetStatus();
        }
    }, [isMount, onHandleGetStatus]);

    const handleChange = (event, newValue) => {
        localStorage.setItem('myOrderStatus', getTab(newValue).status);
        setValue(newValue);
        onHandleGetStatus();
    };

    const onHandleModalAction = useCallback(async (id, action) => {
        if (action == 'cancel') {
            try {
                let payload = {
                    id: id,
                    cancellationType: 1,
                    cancellationReason: '',
                };
                await api.post.cancelOrder(payload);
                onHandleGetStatus();
            } catch (error) {
                console.log(error.response);
                props.showPopupMessage(false, error.response.data.message);
            } finally {
                onHandleGetStatus();
            }
        }
    });

    return (
        <div className="account-orders">
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[
                            { onClick: () => history.push(pathnames.accounts), text: 'My Account' },
                            'My orders',
                        ]}
                    />
                </div>
            </div>
            <div className="m-container m-container--center">
                <div className="account-orders__wrapper m-wrapper">
                    <h1 className="m-txt m-txt--xl m-txt--bold">My Orders</h1>
                    <AppBar
                        position="static"
                        style={{
                            background: 'none',
                            boxShadow: 'none',
                            color: 'black',
                            marginTop: 20,
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                            TabIndicatorProps={{
                                style: {
                                    background: '#000000',
                                    height: '3px',
                                    width: '140px',
                                    marginLeft: '10px',
                                },
                            }}
                        >
                            <Tab
                                disableRipple
                                label="All"
                                {...a11yProps(0)}
                                style={{ boxShadow: 'none' }}
                            />
                            <Tab
                                disableRipple
                                label="To pay"
                                {...a11yProps(1)}
                                style={{ boxShadow: 'none' }}
                                className="myOrderTabs"
                            />
                            <Tab
                                disableRipple
                                label="To pack"
                                {...a11yProps(2)}
                                style={{ boxShadow: 'none' }}
                                className="myOrderTabs"
                            />
                            <Tab
                                disableRipple
                                label="To Ship"
                                {...a11yProps(3)}
                                style={{ boxShadow: 'none' }}
                                className="myOrderTabs"
                            />
                            <Tab
                                disableRipple
                                label="To receive"
                                {...a11yProps(4)}
                                style={{ boxShadow: 'none' }}
                                className="myOrderTabs"
                            />
                            <Tab
                                disableRipple
                                label={`To review (` + localStorage.getItem('toReviewAmount') + `)`}
                                {...a11yProps(4)}
                                style={{ boxShadow: 'none' }}
                                className="myOrderTabs"
                            />
                        </Tabs>
                    </AppBar>
                    <TabPanel
                        value={value}
                        index={0}
                        className="bg-white rounded-md mt-1"
                        className="singleOrderTab"
                    >
                        {orders?.length > 0 ? (
                            <>
                                {/* <Scrollbars style={{ height: 400 }}>  scrollbar will be implement if requested*/}
                                {orders.map((o, i) => (
                                    <GeneralOrderCard
                                        key={`${i}-${status}`}
                                        section={status}
                                        data={o}
                                        id={onHandleModalAction}
                                    />
                                ))}
                                {/* </Scrollbars> */}
                            </>
                        ) : (
                            <EmptyCard />
                        )}
                    </TabPanel>
                    <TabPanel
                        value={value}
                        index={1}
                        className="bg-white rounded-md mt-1"
                        className="singleOrderTab"
                    >
                        {orders?.length > 0 ? (
                            <>
                                {orders.map((o, i) => (
                                    <GeneralOrderCard
                                        key={`${i}-${status}`}
                                        section={status}
                                        data={o}
                                        id={onHandleModalAction}
                                    />
                                ))}
                            </>
                        ) : (
                            <EmptyCard />
                        )}
                    </TabPanel>
                    <TabPanel
                        value={value}
                        index={2}
                        className="bg-white rounded-md mt-1"
                        className="singleOrderTab"
                    >
                        {orders?.length > 0 ? (
                            <>
                                {orders.map((o, i) => (
                                    <GeneralOrderCard
                                        key={`${i}-${status}`}
                                        section={status}
                                        data={o}
                                        id={onHandleModalAction}
                                    />
                                ))}
                            </>
                        ) : (
                            <EmptyCard />
                        )}
                    </TabPanel>
                    <TabPanel
                        value={value}
                        index={3}
                        className="bg-white rounded-md mt-1"
                        className="singleOrderTab"
                    >
                        {orders?.length > 0 ? (
                            <>
                                {orders.map((o, i) => (
                                    <GeneralOrderCard
                                        key={`${i}-${status}`}
                                        section={status}
                                        data={o}
                                        id={onHandleModalAction}
                                    />
                                ))}
                            </>
                        ) : (
                            <EmptyCard />
                        )}
                    </TabPanel>
                    <TabPanel
                        value={value}
                        index={4}
                        className="bg-white rounded-md mt-1"
                        className="singleOrderTab"
                    >
                        {orders?.length > 0 ? (
                            <>
                                {orders.map((o, i) => (
                                    <GeneralOrderCard
                                        key={`${i}-${status}`}
                                        section={status}
                                        data={o}
                                    />
                                ))}
                            </>
                        ) : (
                            <EmptyCard />
                        )}
                    </TabPanel>
                    <TabPanel
                        value={value}
                        index={5}
                        className="bg-white rounded-md mt-1"
                        className="singleOrderTab"
                    >
                        <ReviewSection />
                    </TabPanel>
                </div>
            </div>
        </div>
    );
};

export default AccountOrdersPage;

const GeneralOrderCard = ({ section, data, id }) => {
    const isMount = useIsMount();
    const uploadInputRef = useRef();
    const history = useHistory();
    const location = useLocation();
    const [orderCancelModalVisibility, setOrderCancelModalVisibility] = useState(false);
    const [paymentModalVisibility, setPaymentModalVisibility] = useState(false);
    const [reviewModalVisibility, setReviewModalVisibility] = useState(false);
    const [reviewModaldata, setReviewModalData] = useState();

    const onHandleGoToStore = () => {
        const path = pathnames.merchart.replace(':merchantId', data.order.companyId);
        history.push(path, { from: location.pathname });
    };

    const onHandleNavigateProduct = (id, event) => {
        const path = pathnames.productDetails.replace(':productId', id);
        history.push(path, { from: location.pathname });
        event.stopPropagation();
    };

    const onHandleNavigateOrder = (orderNumber) => {
        history.push(pathnames.accountOrderDetails, { orderNumber: orderNumber, editMode: true });
    };

    const onHandleReviewModal = (event, data) => {
        // setReviewModalVisibility(!reviewModalVisibility);
        // setReviewModalData(data);
        history.push('/account/reviews?to=1');
        event.stopPropagation();
    };

    const onHandleShowConfirmModal = (o, cancel) => {
        try {
            if (cancel) {
                id(data.order.id, 'cancel');
                setOrderCancelModalVisibility(false);
            } else {
                setOrderCancelModalVisibility(o);
            }
        } catch (error) {
            alert(error);
        }
    };

    const fileErrors = [
        { type: 'fileSize', error: 'File Size is too large' },
        { type: 'fileType', error: 'Unsupported File Format' },
    ];

    const initialValues = {
        rating: 0,
        review: '',
        image: null,
    };

    const validationSchema = yup.object().shape({
        rating: yup.number().min(1, 'Please rate the product.'),
        image: yup
            .mixed()
            .test(fileErrors[0].type, fileErrors[0].error, function (value) {
                const { image } = this.parent;
                return value && image?.size <= config.file.size ? true : !value ? true : false;
            })
            .test(fileErrors[1].type, fileErrors[1].error, function (value) {
                const { image } = this.parent;
                return image && config.file.types.includes(image.type)
                    ? true
                    : !value
                    ? true
                    : false;
            }),
    });

    const onHandleOnSubmit = async (values) => {
        try {
            const formData = new FormData();

            if (!values.ratingId) {
                formData.append('ratingId', reviewModaldata.id);
            }

            for (var key in values) {
                formData.append(key, values[key]);
            }
            await api.post.rateProduct(formData);
            setReviewModalVisibility(false);
        } catch (error) {}
    };

    const onHandleTriggerUpload = () => {
        if (uploadInputRef) {
            uploadInputRef.current.click();
        }
    };

    const payNow = (orderNumber) => {
        // history.push('payment', { orderNumber: orderNumber, type: 'repay' });
    };

    return (
        <div className="bg-white mt-4" style={{ border: '1px solid lightGrey' }}>
            <OrderCancelModal
                isOpen={orderCancelModalVisibility}
                onHandleVisible={onHandleShowConfirmModal}
            />
            <Modal
                center
                open={reviewModalVisibility}
                onClose={() => setReviewModalVisibility(false)}
                showCloseIcon={false}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onHandleOnSubmit}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <div className="m-card">
                                <div className="account-reviews__input-wrapper pb-0">
                                    <button
                                        className="account-reviews__close"
                                        type="button"
                                        onClick={() => setReviewModalVisibility(false)}
                                        style={{ boxShadow: 'none' }}
                                    >
                                        <IoClose size={30} color="lightGrey" />
                                    </button>

                                    <div className="product__reviews-content mr-auto">
                                        <div className="mb-4">
                                            <CircleAvatar src={reviewModaldata.productImages} />
                                        </div>
                                        <div className="product__reviews-user">
                                            <p className="m-txt font-medium mb-0 -mt-8 text-lg truncate">
                                                {reviewModaldata.name}
                                            </p>
                                            {reviewModaldata.variations ? (
                                                <p
                                                    className="text-xs mb-2"
                                                    style={{ color: '#888888' }}
                                                >
                                                    {reviewModaldata.variations[0].titles[0]
                                                        ? reviewModaldata.variations[0].titles[0] +
                                                          ': ' +
                                                          reviewModaldata.variations[0].variations
                                                              .variationOne
                                                        : null}
                                                    {reviewModaldata.variations[0].titles[1]
                                                        ? ', ' +
                                                          reviewModaldata.variations[0].titles[1] +
                                                          ': ' +
                                                          reviewModaldata.variations[0].variations
                                                              .variationTwo
                                                        : null}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="account-reviews__input-wrapper items-start py-0 mb-4">
                                    <p className="text-sm ml-2 mb-2">Make a rate</p>
                                    <RatingPicker
                                        value={values.rating}
                                        onChange={(value) => setFieldValue('rating', value)}
                                    />
                                    <p className="m-txt m-txt--small m-txt--error">
                                        <ErrorMessage name="rating" />
                                    </p>
                                </div>
                                <div className="account-reviews__input-wrapper mx-2">
                                    <textarea
                                        className="account-reviews__textarea bg-white rounded-none"
                                        style={{ border: '1px solid lightGrey' }}
                                        placeholder="How do you feel about this product, share your comment with others!"
                                        onChange={(e) => setFieldValue('review', e.target.value)}
                                    />
                                </div>
                                <div className="account-reviews__input-wrapper items-start py-0 mt-0">
                                    <p className="text-sm ml-2 mb-2">Click to upload photo</p>
                                </div>
                                <div className="account-reviews__input-wrapper account-reviews__input-wrapper--start">
                                    <button
                                        className="account-reviews__upload-btn shadow-none"
                                        type="button"
                                        onClick={
                                            !values.image
                                                ? onHandleTriggerUpload
                                                : () => setFieldValue('image', null)
                                        }
                                    >
                                        <span className="m-txt">
                                            {!values.image ? (
                                                <div
                                                    style={{
                                                        padding: '25px',
                                                        border: '2px dotted lightgray',
                                                    }}
                                                    className="rounded"
                                                >
                                                    <IoAdd size={30} style={{ color: '#BBBBBB' }} />
                                                </div>
                                            ) : (
                                                <>
                                                    {values.image ? (
                                                        <div className="account-reviews__input-wrapper">
                                                            <div className="account-reviews__image">
                                                                <div
                                                                    className="nav-bar__badge"
                                                                    style={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        borderRadius: '50px',
                                                                    }}
                                                                >
                                                                    <IoRemove
                                                                        size={25}
                                                                        color="white"
                                                                    />
                                                                </div>
                                                                {!errors.image ? (
                                                                    <img
                                                                        src={URL.createObjectURL(
                                                                            values.image
                                                                        )}
                                                                        alt={'review_image'}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                            <p className="m-txt m-txt--small m-txt--error">
                                                                {errors.image}
                                                            </p>
                                                        </div>
                                                    ) : null}
                                                </>
                                            )}
                                        </span>
                                    </button>
                                    <input
                                        hidden
                                        type="file"
                                        name="image"
                                        ref={uploadInputRef}
                                        onChange={(e) => {
                                            setFieldValue('image', e.target.files[0]);
                                        }}
                                    />
                                </div>
                                <div className="account-reviews__button-wrapper">
                                    <Button
                                        text
                                        bold
                                        type="button"
                                        label="CANCEL"
                                        style={{ boxShadow: 'none' }}
                                        onClick={() => setReviewModalVisibility(false)}
                                    />
                                    <Button
                                        gold
                                        text
                                        bold
                                        label="SUBMIT"
                                        type="submit"
                                        style={{ boxShadow: 'none' }}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <div className="account-orders__header generalOrderCardHeader">
                <div className="table">
                    <p
                        className="account-orders__label font-medium table-cell cursor-pointer"
                        onClick={onHandleGoToStore}
                    >
                        {data.order.companyName}
                        <FiChevronRight className="inline-block" />
                    </p>
                </div>
                <div className="table">
                    {data.order.status == 'delivered' ? (
                        <p className="mr-2 font-medium text-grey2-500 text-sm">
                            {formatDate(data.order.updatedAt)}
                        </p>
                    ) : null}
                    <p
                        className={`account-orders__label font-medium table-cell text-${
                            orderStatusFormat(data.order.status).color
                        }-500`}
                    >
                        {orderStatusFormat(data.order.status).label}
                    </p>
                </div>
            </div>
            {data.products.map((o, i) => (
                <div
                    className={
                        data.order.status == 'delivered'
                            ? 'generalOrderCardContent_review'
                            : 'generalOrderCardContent'
                    }
                    style={{ borderTop: '1px solid lightGrey', cursor: 'pointer' }}
                    onClick={() => onHandleNavigateOrder(data.order.orderNumber)}
                >
                    <div className="account-orders__card-wrapper--item" key={`${i}-item`}>
                        <CircleAvatar
                            src={o.product.productImages[0]}
                            size={50}
                            onClick={(e) => onHandleNavigateProduct(o.product.id, e)}
                            className="cursor-pointer"
                        />
                        <div className="account-orders__item ml-6">
                            <p
                                className="m-txt m-txt--bold truncate cursor-pointer"
                                onClick={(e) => onHandleNavigateProduct(o.product.id, e)}
                            >
                                {o.product.name}
                            </p>
                            {o.product.variations ? (
                                <p className="text-xs mb-2" style={{ color: '#888888' }}>
                                    {o.product.variations[0].titles[0]
                                        ? o.product.variations[0].titles[0] +
                                          ': ' +
                                          o.product.variations[0].variations.variationOne
                                        : null}
                                    {o.product.variations[0].titles[1]
                                        ? ', ' +
                                          o.product.variations[0].titles[1] +
                                          ': ' +
                                          o.product.variations[0].variations.variationTwo
                                        : null}
                                </p>
                            ) : null}
                            <p className="font-medium text-sm" style={{ color: '#666666' }}>
                                RM {o.product.price.toFixed(2)}
                            </p>
                        </div>
                    </div>

                    <p className="m-txt m-txt--grey w-1/5">
                        <span className="font-medium text-black block text-right mb-4">
                            Qty: {o.quantity}
                        </span>
                        <span
                            className="m-txt m-txt--bold float-right"
                            style={{ color: '#666666' }}
                        >
                            RM {(o.product.price * o.quantity).toFixed(2)}
                        </span>
                        {data.order.status == 'delivered' ? (
                            <div className="float-right mt-3 -mr-3 ml-3 clear-right">
                                <Button
                                    label="Review"
                                    tiny
                                    gold
                                    style={{ boxShadow: 'none' }}
                                    onClick={(e) => onHandleReviewModal(e, o.product)}
                                />
                            </div>
                        ) : null}
                    </p>
                </div>
            ))}
            {data.order.status == 'toPack' || data.order.status == 'packing' ? (
                <div
                    className="generalOrderCardContentButton"
                    style={{ borderTop: '1px solid lightGrey' }}
                >
                    <div className="ml-auto">
                        <Button
                            label="Cancel"
                            tiny
                            textWithGoldBorder
                            style={{ boxShadow: 'none' }}
                            onClick={() => setOrderCancelModalVisibility(true)}
                        />
                    </div>
                </div>
            ) : data.order.status == 'pendingPayment' ? (
                <div
                    className="generalOrderCardContentButton"
                    style={{ borderTop: '1px solid lightGrey' }}
                >
                    <div className="ml-auto">
                        <div className="inline-block mr-4">
                            <Button
                                label="Cancel all"
                                tiny
                                textWithGoldBorder
                                style={{ boxShadow: 'none' }}
                                onClick={() => setOrderCancelModalVisibility(true)}
                            />
                        </div>
                        <div className="inline-block">
                            <Button
                                label="Pay Now"
                                tiny
                                gold
                                style={{ boxShadow: 'none' }}
                                onClick={() => payNow(data.order.orderNumber)}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const OrderCancelModal = ({ isOpen, onHandleVisible }) => {
    return (
        <Modal center showCloseIcon={false} open={isOpen} onClose={() => onHandleVisible(false)}>
            <div className="cart__card bg-white h-auto">
                <img src={orderCancelIcon} className="h-16 mt-4 mb-4 ml-auto mr-auto" />
                <p className="m-txt cart__modal-label text-black font-medium font-sans">
                    Confirm to cancel this order?
                </p>
                <div className="cart__button-wrapper">
                    <Button
                        label="CANCEL"
                        text
                        style={{ boxShadow: 'none' }}
                        onClick={() => onHandleVisible(false)}
                    />
                    <Button
                        label="CONFIRM"
                        text
                        gold
                        bold
                        style={{ boxShadow: 'none' }}
                        onClick={() => onHandleVisible(false, true)}
                    />
                </div>
            </div>
        </Modal>
    );
};
