const config = {
    malaysia: {
        state: [
            { label: 'Johor', value: 'Johor' },
            { label: 'Kedah', value: 'Kedah' },
            { label: 'Kelantan', value: 'Kelantan' },
            { label: 'Kuala Lumpur', value: 'Kuala Lumpur' },
            { label: 'Labuan', value: 'Labuan' },
            { label: 'Malacca', value: 'Malacca' },
            { label: 'Negeri Sembilan', value: 'Negeri Sembilan' },
            { label: 'Pahang', value: 'Pahang' },
            { label: 'Perak', value: 'Perak' },
            { label: 'Perlis', value: 'Perlis' },
            { label: 'Penang', value: 'Penang' },
            { label: 'Sabah', value: 'Sabah' },
            { label: 'Sarawak', value: 'Sarawak' },
            { label: 'Selangor', value: 'Selangor' },
            { label: 'Terengganu', value: 'Terengganu' },
        ],
    },
    file: {
        types: ["image/jpg", "image/jpeg", "image/png"],
        size: 20000000,
    },
    link:{
		app:{
			android: 'https://play.google.com/store/apps/',
			ios: 'https://apps.apple.com/my/app/',
		}
	}
}

export default config;