import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useIsMount } from 'hooks/use-mount';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import { IoHeart, IoPersonSharp } from 'react-icons/io5';
import ShareButton from 'components/share-button/share-button';
import api from 'services/api';
import { pathnames } from 'routes/routes';
import noStreamIcon from 'assets/notfound/live-stream.png';
import Button from 'components/button/button';
import { FiChevronRight, FiRadio } from 'react-icons/fi';
import { RiAlarmLine } from 'react-icons/ri';
import { BsPersonFill } from 'react-icons/bs';
import { formatStreamDate } from 'helpers/utility';

const EmptyCard = () => {
    const history = useHistory();
    return(
        <div className="home__products  container m-container m-container--center mt-0  mb-40">
            <div className="m-wrapper">
                <img src={noStreamIcon} className="m-auto mt-40 mb-4 w-12" />
                <p className="text-center text-gray-400 mb-4">Sorry, no live stream at{'\n'}the moment</p>
                <div className="text-center">
                <Button 
                label="View upcoming"
                tiny
                gold
                style={{ boxShadow: 'none' }}
                onClick={()=> history.push()}/>
                </div>
               
            </div>
        </div>
    )
};

const LiveStreamsPage = (props) => {
    const history = useHistory();
    const isMount = useIsMount();
    const [onGoingStreams, setOnGoingStreams] = useState([]);
    const [upcomingStreams, setUpcomingStreams] = useState([]);

    const onHandleGetOnGoingStreams = useCallback(async () => {
        try {
            const payload = {
				screen: 'all',
			};	
            const response = await api.get.announcement(payload);
            setOnGoingStreams(response.data.result.streamAnnouncements);
        }
        catch (error) {
        }
    }, [props]);

    const onHandleGetUpcomingStreams = useCallback(async () => {
        try {
            const payload = {
				screen: 'upcoming',
			};	
            const response = await api.get.announcement(payload);
            setUpcomingStreams(response.data.result.streamAnnouncements);
        }
        catch (error) {
        }
    }, [props]);

    useEffect(() => {
        if (isMount) {
            onHandleGetOnGoingStreams();
        }
    }, [isMount, onHandleGetOnGoingStreams]);

    useEffect(() => {
        if (isMount) {
            onHandleGetUpcomingStreams();
        }
    }, [isMount, onHandleGetUpcomingStreams]);

    const onHandleStreamNow = (o) => {
        const path = pathnames.streaming.replace(":streamingKey", o.streamKey)
        history.push(path, { data: o })
    }

    const onHandleRedirectStreamTabs = (type) => {
        switch(type){
            case 'onGoing':{
                localStorage.setItem('streamTab', 0);
                history.push(pathnames.liveStreamTabs);
                break;
            }
            case 'upComing':{
                localStorage.setItem('streamTab', 1);
                history.push(pathnames.liveStreamTabs);
                break;
            }
        }
    }

    const onHandleGoToStore = (id) => {
        const path = pathnames.merchart.replace(':merchantId', id);
        history.push(path);
    };

    return (
        <div className="live-stream">
            <div className="m-container m-container--center">
                <div className="m-wrapper my-6">
                    <p className="m-txt m-txt--bold m-txt--l">Live Stream</p>
                </div>
            </div>
            <div className="m-container m-container--center">
            <div className="account-orders__wrapper m-wrapper">
                {onGoingStreams.length > 0 || upcomingStreams.length > 0 ? 
                <div className="live-stream__wrapper m-wrapper bg-white rounded p-8" style={{ border: '1px solid lightgrey' }}>
                    {onGoingStreams.length > 0?
                        <div className="w-full" style={upcomingStreams.length > 0?{ borderBottom: '1px solid lightgrey', paddingBottom: '2rem', marginBottom: '2rem' }:null}>
                            <div className="w-full flex justify-between">
                                <div className="mb-4 inline-block">
                                    <p className="font-medium" style={{fontSize: '26px'}}>Live Now</p>
                                </div>
                                <div className="cursor-pointer">
                                    <p className="inline-block" onClick={()=> onHandleRedirectStreamTabs('onGoing')}>View all</p>
                                    <FiChevronRight className="inline-block" />
                                </div>
                            </div>
                            <div className="flex">                        
                            {onGoingStreams.map((o, i) => {
                                const key = `${i}-stream-item`;
                                return (
                                    <>
                                {i < 4? 
                                    <div style={{width:"25%"}}>
                                        <div className="live-stream__card" key={key} >
                                            <div onClick={() => history.push(pathnames.streaming.replace(':streamingKey', o.streamKey))}>
                                                <div className="live-stream__image" style={{ backgroundImage: `url(${o.image})` }}>  
                                                    <p className="text-white absolute mt-3 ml-4 flex pr-4" style={{padding:"3px", backgroundColor: "#C19E66", fontSize: "14px"}}><FiRadio className="mt-auto mb-auto mx-2" size={18}/>Live</p>
                                                </div>                                    
                                            </div>                                                                                
                                        </div>
                                        <div>
                                            <div className="live-stream__content-wrapper cursor-pointer" style={{height: "48px"}} onClick={() => history.push(pathnames.streaming.replace(':streamingKey', o.id))}>
                                                <p className="font-medium mx-2" 
                                                style={{
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-word',
                                                    overflow: 'hidden',
                                                    display: '-webkit-box',
                                                    '-webkit-line-clamp': '2',
                                                    '-webkit-box-orient': 'vertical',
                                                }}>{o.title}</p>
                                            </div>
                                        </div>
                                        <div className="live-stream__content">
                                            <CircleAvatar src={o.companyImage} size={25}  onClick={() => onHandleGoToStore(o.companyId)}/>
                                            <div className="live-stream__content-wrapper truncate">
                                                <p>{o.companyName}</p>
                                            </div>
                                        </div>
                                        <div className="live-stream__content pt-0">
                                            <BsPersonFill size={25} style={{color: "#C19E66"}}/>
                                            <div className="live-stream__content-wrapper truncate" style={{marginTop:"2px"}}>
                                                <p className="font-medium" style={{color:"#666666"}}>{o.viewCount? o.viewCount : 0} viewing</p>
                                            </div>
                                        </div>    
                                    </div>
                                :null}
                                </>
                                )                                
                            })}
                            </div>   
                        </div>
                    :null}
                    {upcomingStreams.length > 0?
                        <div className="w-full">
                            <div className="w-full flex justify-between">
                                <div className="mb-4 inline-block">
                                    <p className="font-medium" style={{fontSize: '20px'}}>Upcoming</p>
                                </div>
                                <div className="cursor-pointer mt-2">
                                    <p className="inline-block"onClick={()=> onHandleRedirectStreamTabs('upComing')}>View all upcoming</p>
                                    <FiChevronRight className="inline-block" />
                                </div>  
                            </div>   
                            <div className="flex">                        
                            {upcomingStreams.map((o, i) => {
                                const key = `${i}-stream-item`;
                                return (
                                    <>
                                {i < 4? 
                                    <div style={{width:"25%"}}>
                                        <div className="live-stream__card" key={key} >
                                            <div onClick={() => history.push(pathnames.streaming.replace(':streamingKey', o.streamKey))}>
                                                <div className="live-stream__image" style={{ backgroundImage: `url(${o.image})` }}>  
                                                    <p className="text-white absolute mt-3 ml-4 flex pr-4" style={{padding:"3px", backgroundColor: "#333333", fontSize: "12px"}}><RiAlarmLine className="mt-auto mb-auto mx-2"/>{formatStreamDate(o.streamDate)}</p>
                                                </div>                                    
                                            </div>                                                                                
                                        </div>
                                        <div>
                                            <div className="live-stream__content-wrapper cursor-pointer" style={{height: "48px"}} onClick={() => history.push(pathnames.streaming.replace(':streamingKey', o.id))}>
                                                <p className="font-medium mx-2" 
                                                style={{
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-word',
                                                    overflow: 'hidden',
                                                    display: '-webkit-box',
                                                    '-webkit-line-clamp': '2',
                                                    '-webkit-box-orient': 'vertical',
                                                }}>{o.title}</p>
                                            </div>
                                        </div>
                                        <div className="live-stream__content">
                                            <CircleAvatar src={o.companyImage} size={25}  onClick={() => onHandleGoToStore(o.companyId)}/>
                                            <div className="live-stream__content-wrapper truncate">
                                                <p>{o.companyName}</p>
                                            </div>
                                        </div>
                                        <div className="live-stream__content pt-0">
                                            <BsPersonFill size={25} style={{color: "#C19E66"}}/>
                                            <div className="live-stream__content-wrapper truncate" style={{marginTop:"2px"}}>
                                                <p className="font-medium" style={{color:"#666666"}}>{o.viewCount? o.viewCount : 0} views</p>
                                            </div>
                                        </div>    
                                    </div>
                                :null}
                                </>
                                )                                
                            })}
                            </div>   
                        </div>
                    :null}                   
                </div>:
                <EmptyCard/>}
                </div>
            </div>
        </div>
    );
};

export default LiveStreamsPage;