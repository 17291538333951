import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { pathnames } from 'routes/routes';
import allProduct from 'assets/products/all-products.png';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 800 },
        items: 8,
        slidesToSlide: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 5,
        slidesToSlide: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1,
    },
};

const CategoryPicker = ({ categories }) => {
    const [allProductSection, setAllProductSection] = useState();
    useEffect(() => {
        const allSection = document.getElementById('allProductRow');
        if (allSection) {
            setAllProductSection(allSection);
        }
    });

    const redirectToAll = () => {
        if (allProductSection) {
            allProductSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="category-picker">
            <Carousel
                responsive={responsive}
                className="category-picker__carousel"
                renderButtonGroupOutside={true}
            >
                <div
                    className="category-picker__link cursor-pointer"
                    onClick={() => redirectToAll()}
                >
                    <div className="category-picker__wrapper">
                        <img src={allProduct} className="category-picker__image" />
                        <p className="m-txt m-txt--s m-txt--bold">All</p>
                    </div>
                </div>

                {categories.map((o, i) => {
                    const key = `${i}-category-${o.name}`;
                    const path = pathnames.productCategory.replace(':categoryId', o.id);
                    if (!o.active) return null;

                    return (
                        <Link className="category-picker__link" key={key} to={path}>
                            <div className="category-picker__wrapper">
                                <div
                                    className="category-picker__image"
                                    style={{ backgroundImage: `url(${o.image})` }}
                                />
                                <p className="m-txt m-txt--s m-txt--bold">{o.name}</p>
                            </div>
                        </Link>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default CategoryPicker;
