import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import CircleAvatar from 'components/circle-avatar/circle-avatar';
import ProductCard from 'components/product-card/product-card';
import BreadCrumb from 'components/breadcrumb/breadcrumb';

import api from 'services/api';
import { useIsMount } from 'hooks/use-mount';

const MerchantPage = (props) => {
    const isMount = useIsMount();
    const history = useHistory();
    const [company, setCompany] = useState();
    const { merchantId } = useParams();

    const onHandleGetMerchantProducts = useCallback(async () => {
        try {
            const response = await api.get.visitStore(merchantId);
            setCompany(response.data.result);
        }
        catch (error) {
        }
    }, [merchantId, props])

    useEffect(() => {
        if (isMount) {
            onHandleGetMerchantProducts();
        }
    }, [isMount, onHandleGetMerchantProducts]);

    return (
        <div className="merchant">
            {company ?
                <Fragment>
                    <div className="m-container m-container--center">
                        <div className="m-wrapper">
                            <BreadCrumb
                                paths={[{ onClick: history.goBack, text: 'Back' }, company.companyName]}
                            />
                        </div>
                    </div>
                    <div className="m-container m-container--center">
                        <div className="m-wrapper">
                            <div className="m-card merchant__wrapper">
                                <CircleAvatar src={company.companyImage} size={60} />
                                <div className="merchant__header">
                                    <p className="m-txt m-txt--bold m-txt--l">{company.companyName}</p>
                                    <p className="m-txt m-txt--grey">
                                        {company.companyProductQuantity} products
                                    </p>
                                </div>
                            </div>

                            <div className="merchant__wrapper m-wrapper">
                                {company && company.products ? <ProductCard title="" products={company.products} /> : null}
                            </div>
                        </div>
                    </div>
                </Fragment>
                : null}
        </div>
    );
};

export default MerchantPage;
