import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import _ from 'lodash';
import { IoHeart, IoAdd, IoRemove } from 'react-icons/io5';

import BreadCrumb from 'components/breadcrumb/breadcrumb';
import ProductCard from 'components/product-card/product-card';
import ShareButton from 'components/share-button/share-button';
import RatingPicker from 'components/rating-picker/rating-picker';
import Button from 'components/button/button';
import { setWishProduct } from 'redux/actions/user-actions';
import { getUserNotificationSummary } from 'redux/actions/user-actions';
import { pathnames } from 'routes/routes';
import api from 'services/api';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import withLogin from 'context/with-login';
import withCart from 'context/with-cart';
import { useIsMount } from 'hooks/use-mount';
import ShowMoreText from 'react-show-more-text';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import moment from 'moment';
import { FiChevronRight } from 'react-icons/fi';
const orderDateFormat = 'DD MMM YYYY';

const defaultSelectedVar = {
    variationOne: '',
    variationTwo: '',
    product: null,
};

const ProductReviewPage = (props) => {
    const isMount = useIsMount();
    const history = useHistory();
    const location = useLocation();
    const { productId } = useParams();
    const dispatch = useDispatch();
    const [onSubmit, setOnSubmit] = useState(false);
    const [product, setProduct] = useState();
    const [reviews, setReviews] = useState();
    const [companyInfo, setCompanyInfo] = useState();
    const userAuth = useSelector((state) => state.user.auth);
    const userProfile = useSelector((state) => state.user.profile);
    const [selectedVar, setSelectedVar] = useState(defaultSelectedVar);
    const [purchaseQuantity, setPurchaseQuantity] = useState(1);
    const onHandleGetNotificationSummary = useCallback(
        (o) => dispatch(getUserNotificationSummary(o)),
        [dispatch]
    );
    const [copyData, setCopyData] = useState();
    const [selectedFilter, setSelectedFilter] = useState(0);

    const onHandleGetProduct = useCallback(async () => {
        try {
            const response = await api.get.productDetails(productId);
            const reviewsResponse = await api.get.productReviews(productId);
            const results = response.data.result;
            setProduct(results);
            setReviews(reviewsResponse.data.result);
            setCompanyInfo({
                companyId: results.companyId,
                companyImage: results.companyImage,
                companyName: results.companyName,
                companyProductQuantity: results.companyProductQuantity,
            });
            console.log(response);
            console.log(reviewsResponse);
        } catch (error) {}
    }, [productId, props]);

    const createNotification = (type, message, title) => {
        switch (type) {
            case 'info':
                NotificationManager.info(message);
                break;
            case 'success':
                NotificationManager.success(message);
                break;
            case 'warning':
                NotificationManager.warning(message);
                break;
            case 'error':
                NotificationManager.error(title, message);
                break;
        }
    };

    useEffect(() => {
        if (isMount) {
            window.scrollTo(0, 0);
            onHandleGetProduct();
        }
    }, [isMount, onHandleGetProduct]);

    const onHandlePriceRange = () => {
        if (!product) return;

        if (product.product.variations && !selectedVar.product) {
            const options = [...product.product.variations];
            const prices = options.map((i) => i.price);
            const sorted = prices.slice().sort((a, b) => a - b);
            const lowest = sorted[0];
            const highest = sorted[sorted.length - 1];

            let priceTag = '';

            if (highest === lowest) {
                priceTag = `${highest.toFixed(2)}`;
            } else {
                priceTag = `${lowest.toFixed(2)} - ${highest.toFixed(2)} `;
            }
            return priceTag;
        } else if (product.product.variations && !selectedVar.product) {
            const selected = selectedVar.product;
            return (selected.price * purchaseQuantity).toFixed(2);
        } else {
            const productPrices = product?.product?.price;
            return (productPrices * purchaseQuantity).toFixed(2);
        }
    };

    const isDisabled = () => {
        if (!product || onSubmit) return true;

        if (selectedVar.product || product.product.variations) {
            return !selectedVar?.product?.stock;
        } else {
            return !product?.product?.stock;
        }
    };

    const getCopyData = () => {
        setCopyData(
            'Hey there, I found there is ' +
                product?.product?.name +
                ' with just RM ' +
                onHandlePriceRange() +
                ' at ' +
                window.location.href
        );
    };

    const filterRating = useCallback(async (i) => {
        setSelectedFilter(i);
        switch (i) {
            case 0:
                const reviewsResponse0 = await api.get.productReviews(productId);
                setReviews(reviewsResponse0.data.result);
                break;
            case 1:
                const payload1 = {
                    withImageOrVideo: true,
                };
                const reviewsResponse1 = await api.get.productReviews(productId, payload1);
                setReviews(reviewsResponse1.data.result);
                break;
            case 2:
                const payload2 = {
                    fiveStar: true,
                };
                const reviewsResponse2 = await api.get.productReviews(productId, payload2);
                setReviews(reviewsResponse2.data.result);
                break;
            case 3:
                const payload3 = {
                    fourStar: true,
                };
                const reviewsResponse3 = await api.get.productReviews(productId, payload3);
                setReviews(reviewsResponse3.data.result);
                break;
            case 4:
                const payload4 = {
                    threeStarAndBelow: true,
                };
                const reviewsResponse4 = await api.get.productReviews(productId, payload4);
                setReviews(reviewsResponse4.data.result);
                break;
        }
    });

    return (
        <>
            <NotificationContainer />
            <div className="product">
                <div className="container m-container m-container--center">
                    <div className="m-wrapper">
                        <BreadCrumb
                            paths={[
                                { linkTo: '/', text: 'Home' },
                                {
                                    onClick: () => history.push(pathnames.productCategory),
                                    text: 'Products',
                                },
                                {
                                    onClick: () =>
                                        history.push(
                                            pathnames.productDetails.replace(
                                                ':productId',
                                                productId
                                            )
                                        ),
                                    text: 'Product details',
                                },
                                'Product reviews',
                            ]}
                        />
                    </div>
                    <div className="text-3xl font-medium m-wrapper">Product reviews</div>
                    <div
                        className="m-card w-full mt-6 rounded-none"
                        style={{ border: '1px solid lightgrey' }}
                    >
                        <div className="product__section product__section--justify-between p-12 mb-0 pb-4 pt-4 flex">
                            <div>
                                <p className="text-gray-600">Product name</p>
                                <div
                                    className="product__company-image inline-block mt-1"
                                    style={{
                                        width: '35px',
                                        height: '35px',
                                        backgroundImage: `url(${product?.product?.productImages[0]})`,
                                    }}
                                />
                                <div className="inline-block" style={{ verticalAlign: 'super' }}>
                                    {product?.product?.name}
                                </div>
                            </div>
                            <div>
                                <p className="text-gray-600">Product SKU</p>
                                <p className="mt-4">{product?.product?.sku.slice(3)}</p>
                            </div>
                            <div>
                                <p className="text-gray-600">No. reviews</p>
                                <p className="mt-4">{reviews?.totalReviewsNumber}</p>
                            </div>
                            <div>
                                <p className="text-gray-600">Average ratings</p>
                                <div className="product__header-txt product__rating m-txt m-txt--m m-txt--bold mt-4">
                                    {product?.product?.rating}
                                    <span className="m-txt m-txt--s"> / 5</span>
                                    <RatingPicker value={product?.product?.rating} />
                                </div>
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <div className="m-wrapper mt-16 flex">
                        <div className="mx-4 cursor-pointer" onClick={() => filterRating(0)}>
                            <p style={selectedFilter == 0 ? { fontWeight: '600' } : null}>All</p>
                        </div>{' '}
                        |
                        <div className="mx-4 cursor-pointer" onClick={() => filterRating(1)}>
                            <p style={selectedFilter == 1 ? { fontWeight: '600' } : null}>
                                With Image & Video
                            </p>
                        </div>{' '}
                        |
                        <div
                            className="mx-4 cursor-pointer flex"
                            onClick={() => filterRating(2)}
                            style={
                                reviews?.fiveStarReviewsNumber == '0'
                                    ? { pointerEvents: 'none', opacity: 0.5 }
                                    : null
                            }
                        >
                            <RatingPicker value={5} />
                            <p
                                className="ml-2"
                                style={selectedFilter == 2 ? { fontWeight: '600' } : null}
                            >
                                {' '}
                                ({reviews?.fiveStarReviewsNumber})
                            </p>
                        </div>{' '}
                        |
                        <div
                            className="mx-4 cursor-pointer flex"
                            onClick={() => filterRating(3)}
                            style={
                                reviews?.fourStarReviewsNumber == '0'
                                    ? { pointerEvents: 'none', opacity: 0.5 }
                                    : null
                            }
                        >
                            <RatingPicker value={4} />
                            <p
                                className="ml-2"
                                style={selectedFilter == 3 ? { fontWeight: '600' } : null}
                            >
                                {' '}
                                ({reviews?.fourStarReviewsNumber})
                            </p>
                        </div>{' '}
                        |
                        <div
                            className="mx-4 cursor-pointer flex"
                            onClick={() => filterRating(4)}
                            style={
                                reviews?.threeStarAndBelowReviewsNumber == '0'
                                    ? { pointerEvents: 'none', opacity: 0.5 }
                                    : null
                            }
                        >
                            <RatingPicker value={3} />
                            <p
                                className="ml-2"
                                style={selectedFilter == 4 ? { fontWeight: '600' } : null}
                            >
                                & below ({reviews?.threeStarAndBelowReviewsNumber})
                            </p>
                        </div>
                    </div>

                    {reviews && companyInfo ? (
                        <div
                            className="product__card product__card--column m-card mt-4 rounded-none p-0"
                            style={{ border: '1px solid lightgrey' }}
                        >
                            {reviews.records.map((o, i) => {
                                const key = `${i}-reviews`;
                                if (o.image && o.image.includes('[')) {
                                    o.image = o.image.substring(1, o.image.length - 1).split(',');
                                }
                                return (
                                    <div
                                        className="product__reviews pb-8 product__section--divider mb-0"
                                        key={key}
                                        style={{ padding: '0 35px' }}
                                    >
                                        <div className="product__reviews-content">
                                            <CircleAvatar size={50} src={o.userImage} />
                                            <div className="product__reviews-user">
                                                <p className="m-txt">{o.userName}</p>
                                                {o.productVariation ? (
                                                    <p className="m-txt font-normal text-gray-700">
                                                        {o.productVariation}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="ml-auto">
                                                {moment(o.createdAt).format(orderDateFormat)}
                                            </div>
                                        </div>
                                        <div style={{ marginLeft: '25px' }}>
                                            <p className="font-medium mt-4">Review :</p>
                                            <div className="-ml-2 mt-3">
                                                <RatingPicker value={o.rating} size={24} />
                                            </div>
                                            <p className="product__reviews-comment m-txt font-medium">
                                                {o.review}
                                            </p>
                                            {o.image && typeof o.image == 'string' ? (
                                                <div
                                                    className="product__reviews-image"
                                                    style={{ backgroundImage: `url(${o.image})` }}
                                                />
                                            ) : o.image && typeof o.image != 'string' ? (
                                                <div className="flex">
                                                    {o.image.map((imageItem, index) => (
                                                        <div
                                                            className="product__reviews-image mx-4"
                                                            style={{
                                                                backgroundImage: `url(${imageItem})`,
                                                            }}
                                                        />
                                                    ))}
                                                </div>
                                            ) : null}
                                        </div>
                                        {o.replies && o.replies != '' ? (
                                            <div
                                                className="product__reviews product__reviews--seller"
                                                style={{ width: '85%' }}
                                            >
                                                <p className="m-txt m-txt--bold inline-block">
                                                    Seller replied
                                                </p>
                                                <div className="ml-auto w-auto inline-block float-right">
                                                    {moment(o.replyAt).format(orderDateFormat)}
                                                </div>
                                                <div className="product__reviews-replies">
                                                    <p className="m-0 font-medium text-black">
                                                        {o.replies}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default withLogin(withCart(ProductReviewPage));
