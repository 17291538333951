import React, { useCallback, useEffect, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-responsive-modal';

import { setInfoHide } from 'redux/actions/info-actions'


const ShortDurationModal = () => {
    const { msg, error, success } = useSelector((state) => state.info);

    return (
        <Popup>

            {success ? (
                <div className="m-txt m-txt--valid">
                    <FiCheck size={45} />
                </div>
            ) : null}

            {error ? (
                <div className="m-txt m-txt--error">
                    <IoMdClose size={45} />
                </div>
            ) : null}
            <p className="short-duration-modal__msg m-txt">{msg}</p>
        </Popup>
    )
};

export default ShortDurationModal;


const Popup = ({ children }) => {
    const dispatch = useDispatch();
    const { msg } = useSelector((state) => state.info);
    const onHandleClearMessage = useCallback(() => dispatch(setInfoHide()), [dispatch])
    const activeDuration = 1500;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (msg) {
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
                onHandleClearMessage();
            }, activeDuration);
        }
    }, [msg, onHandleClearMessage]);

    return (
        <Modal
            center
            open={open}
            classNames={{ root: "short-duration-modal", overlay: 'short-duration-modal__overlay' }}
            showCloseIcon={false}
            onClose={() => setOpen(false)}
        >
            <div className="short-duration-modal__card">{children}</div>
        </Modal>
    );
};