import React, { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { useIsMount } from 'hooks/use-mount';

const PaymentPage = (props) => {
    const isMount = useIsMount();
    const [url, seturl] = useState('');
    const [loading, setloading] = useState(false);    

    const onHandlePayment = () => {
        if(props.history.location.state.type=='default'){
            const resp = api.get.initiatePayment(props.history.location.state.paymentReferenceNumber);
            // seturl(api.get.initiatePayment(props.history.location.state.paymentReferenceNumber));
        }
        else{
            const resp = api.get.secondPayment(props.history.location.state.orderNumber);
            // seturl(api.get.secondPayment(props.history.location.state.orderNumber));
        }
    }

    useEffect(() => {
        if (isMount) {
            onHandlePayment();
        }
    }, [isMount, onHandlePayment]);
    

    return (
        <div>
            
        </div>
    )

};

export default PaymentPage;