import React, { useCallback, useEffect, useState, Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import { useIsMount } from 'hooks/use-mount';
import api from 'services/api';
import { orderStatusFormat, formatDate, changeAddressToString } from 'helpers/utility';
import { FiEdit2, FiMail, FiMapPin, FiPhoneCall } from 'react-icons/fi';
import config from 'helpers/config';
import { IoClose, IoAdd, IoRemove } from 'react-icons/io5';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import Modal from 'react-responsive-modal';
import { pathnames } from 'routes/routes';
import Button from 'components/button/button';
import RatingPicker from 'components/rating-picker/rating-picker';
import { FiChevronRight } from 'react-icons/fi';
import orderCancelIcon from 'assets/products/order-cancel-icon.png';

const AccountOrderDetailsPage = () => {
    const isMount = useIsMount();
    const history = useHistory();
    const { state } = useLocation();
    const [order, setOrder] = useState();
    const [product, setProduct] = useState([]);

    const onHandleGetOrderDetail = useCallback(async () => {
        try {
            const response = await api.get.getOrderDetails(state.orderNumber);
            if (isMount){
                setOrder(response.data.result.orders);
                setProduct(response.data.result.products);
            }            
        } catch (error) {
            console.log(error);
        }
    });

    useEffect(() => {
        if (isMount) {
            onHandleGetOrderDetail();
        }
    }, [isMount, onHandleGetOrderDetail]);

    const onHandleModalAction = useCallback(async (id, action) => {
        if (action == 'cancel') {
            try {
                let payload = {
                    id: id,
                    cancellationType: 1,
                    cancellationReason: '',
                };
                await api.post.cancelOrder(payload);
            } catch (error) {
                console.log(error.response);
            } finally {
                onHandleGetOrderDetail();
            }
        }
    });

    return (
        <>
        {order?
        <div className="account-reviews">
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[
                            { onClick: ()=>history.push(pathnames.accounts), text: 'My Account' },
                            { onClick: ()=>history.push(pathnames.accountOrders), text: 'My orders' },
                            'Order details',
                        ]}
                    />
                </div>
            </div>
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <h1 className="m-txt m-txt--l m-txt--bold mb-8">Order details</h1>
                    <StatusBar status={order?.status} reason={order?.cancellationReason} />
                    {order?.trackingNumber ? (
                        <div className="ml-auto font-medium mt-4" style={{ color: '#888888' }}>
                            Tracking number: {order.trackingNumber}
                        </div>
                    ) : null}
                    <div className="checkout__shipping-details">
                        <div className="checkout__shipping-content">
                            <FiMapPin size={30} className="mt-auto mb-auto" />
                            <div>
                                <p className="font-medium">{order?.deliveryName}</p>
                                <p className="m-txt capitalize">
                                    {order?.deliveryAddress?.Address},{' '}
                                    {order?.deliveryAddress?.postcode},{' '}
                                    {order?.deliveryAddress?.city}, {order?.deliveryAddress?.state}
                                </p>
                            </div>
                            <div className="ml-auto font-medium" style={{ color: '#888888' }}>
                                Order no.: {state.orderNumber}
                            </div>
                        </div>
                    </div>
                    {product.length > 0 ? (
                        <div>
                            <GeneralOrderCard
                                order={order}
                                product={product}
                                id={onHandleModalAction}
                            />
                            <div className="checkout__total">
                        <div className="checkout__total" />
                        <div className="checkout__total" />
                        <div className="checkout__total" />
                        <div className="checkout__total-price text-right">
                            <p className="font-medium" style={{color:"#666666", fontSize:"16px"}}>
                                Subtotal ({product.length} {product.length > 1 ? 'items' : 'item'}
                                ):
                            </p>
                            <p className="font-medium" style={{color:"#666666", fontSize:"16px"}}>Shipping fee:</p>
                        </div>
                        <div className="checkout__total-price">
                            <p className="m-txt m-txt--bold">
                                RM{' '}
                                {(order.totalAmount - order.shippingFee).toFixed(2)}
                            </p>
                            <p className="m-txt m-txt--bold">
                                RM {order.shippingFee.toFixed(2)}
                            </p>
                        </div>
                    </div>
                    <div className="checkout__total checkout__total--white">
                        <div className="checkout__total" />
                        <div className="checkout__total" />
                        <div className="checkout__total" />
                        <div className="checkout__total-price">
                            <p className="m-txt m-txt--bold m-txt--grey" style={{fontSize:"18px"}}>
                                Total (Inc SST):
                            </p>
                        </div>
                        <div className="checkout__total-price">
                            <p className="m-txt m-txt--bold" style={{fontSize:"18px"}}>
                                <span>
                                    RM {order.totalAmount.toFixed(2)}
                                </span>
                            </p>
                        </div>
                    </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
        :
        <div className="account-reviews">
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[
                            { onClick: ()=>history.push(pathnames.accounts), text: 'My Account' },
                            { onClick: ()=>history.push(pathnames.accountOrders), text: 'My orders' },
                            'Order details',
                        ]}
                    />
                </div>
            </div>
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <h1 className="m-txt m-txt--l m-txt--bold mb-64">Order details</h1>
                    </div>
            </div>
        </div>
        }
        </>
    );
};
export default AccountOrderDetailsPage;

const StatusBar = ({ status, reason }) => {
    return (
        <div
            className={
                status == 'cancelled' || status == 'cancelledByCustomer'
                    ? 'cancelBar'
                    : 'defaultBar'
            }
        >
            {orderStatusFormat(status).barTitle} {reason != '' ? 'Reason: ' + reason : null}
        </div>
    );
};

const GeneralOrderCard = ({ order, product, id }) => {
    const isMount = useIsMount();
    const uploadInputRef = useRef();
    const history = useHistory();
    const location = useLocation();
    const [orderCancelModalVisibility, setOrderCancelModalVisibility] = useState(false);
    const [paymentModalVisibility, setPaymentModalVisibility] = useState(false);
    const [reviewModalVisibility, setReviewModalVisibility] = useState(false);
    const [reviewModaldata, setReviewModalData] = useState();

    const onHandleGoToStore = () => {
        const path = pathnames.merchart.replace(':merchantId', order.companyId);
        history.push(path, { from: location.pathname });
    };

    const onHandleNavigateProduct = (id, event) => {
        const path = pathnames.productDetails.replace(':productId', id);
        history.push(path, { from: location.pathname });
        event.stopPropagation();
    };

    const onHandleNavigateOrder = (orderNumber) => {
        history.push(pathnames.accountOrderDetails, { orderNumber: orderNumber, editMode: true });
    };

    const onHandleReviewModal = (event, data) => {
        // setReviewModalVisibility(!reviewModalVisibility);
        // setReviewModalData(data);
        history.push('/account/reviews?to=1');
        event.stopPropagation();
    };

    const onHandleShowConfirmModal = (o, cancel) => {
        try {
            if (cancel) {
                id(order.id, 'cancel');
                setOrderCancelModalVisibility(false);
            } else {
                setOrderCancelModalVisibility(o);
            }
        } catch (error) {
            alert(error);
        }
    };

    const fileErrors = [
        { type: 'fileSize', error: 'File Size is too large' },
        { type: 'fileType', error: 'Unsupported File Format' },
    ];

    const initialValues = {
        rating: 0,
        review: '',
        image: null,
    };

    const validationSchema = yup.object().shape({
        rating: yup.number().min(1, 'Please rate the product.'),
        image: yup
            .mixed()
            .test(fileErrors[0].type, fileErrors[0].error, function (value) {
                const { image } = this.parent;
                return value && image?.size <= config.file.size ? true : !value ? true : false;
            })
            .test(fileErrors[1].type, fileErrors[1].error, function (value) {
                const { image } = this.parent;
                return image && config.file.types.includes(image.type)
                    ? true
                    : !value
                    ? true
                    : false;
            }),
    });

    const onHandleOnSubmit = async (values) => {
        try {
            const formData = new FormData();

            if (!values.ratingId) {
                formData.append('ratingId', reviewModaldata.id);
            }

            for (var key in values) {
                formData.append(key, values[key]);
            }
            await api.post.rateProduct(formData);
            setReviewModalVisibility(false);
        } catch (error) {}
    };

    const onHandleTriggerUpload = () => {
        if (uploadInputRef) {
            uploadInputRef.current.click();
        }
    };

    const payNow = (orderNumber) => {
        // history.push('payment', { orderNumber: orderNumber, type: 'repay' });
    };

    return (
        <div className="bg-white mt-4" style={{ border: '1px solid lightGrey' }}>
            <OrderCancelModal
                isOpen={orderCancelModalVisibility}
                onHandleVisible={onHandleShowConfirmModal}
            />
            <Modal
                center
                open={reviewModalVisibility}
                onClose={() => setReviewModalVisibility(false)}
                showCloseIcon={false}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onHandleOnSubmit}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <div className="m-card">
                                <div className="account-reviews__input-wrapper pb-0">
                                    <button
                                        className="account-reviews__close"
                                        type="button"
                                        onClick={() => setReviewModalVisibility(false)}
                                        style={{ boxShadow: 'none' }}
                                    >
                                        <IoClose size={30} color="lightGrey" />
                                    </button>

                                    <div className="product__reviews-content mr-auto">
                                        <div className="mb-4">
                                            <CircleAvatar src={reviewModaldata.productImages} />
                                        </div>
                                        <div className="product__reviews-user">
                                            <p className="m-txt font-medium mb-0 -mt-8 text-lg truncate">
                                                {reviewModaldata.name}
                                            </p>
                                            {reviewModaldata.variations ? (
                                                <p
                                                    className="text-xs mb-2"
                                                    style={{ color: '#888888' }}
                                                >
                                                    {reviewModaldata.variations[0].titles[0]
                                                        ? reviewModaldata.variations[0].titles[0] +
                                                          ': ' +
                                                          reviewModaldata.variations[0].variations
                                                              .variationOne
                                                        : null}
                                                    {reviewModaldata.variations[0].titles[1]
                                                        ? ', ' +
                                                          reviewModaldata.variations[0].titles[1] +
                                                          ': ' +
                                                          reviewModaldata.variations[0].variations
                                                              .variationTwo
                                                        : null}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="account-reviews__input-wrapper items-start py-0 mb-4">
                                    <p className="text-sm ml-2 mb-2">Make a rate</p>
                                    <RatingPicker
                                        value={values.rating}
                                        onChange={(value) => setFieldValue('rating', value)}
                                    />
                                    <p className="m-txt m-txt--small m-txt--error">
                                        <ErrorMessage name="rating" />
                                    </p>
                                </div>
                                <div className="account-reviews__input-wrapper mx-2">
                                    <textarea
                                        className="account-reviews__textarea bg-white rounded-none"
                                        style={{ border: '1px solid lightGrey' }}
                                        placeholder="How do you feel about this product, share your comment with others!"
                                        onChange={(e) => setFieldValue('review', e.target.value)}
                                    />
                                </div>
                                <div className="account-reviews__input-wrapper items-start py-0 mt-0">
                                    <p className="text-sm ml-2 mb-2">Click to upload photo</p>
                                </div>
                                <div className="account-reviews__input-wrapper account-reviews__input-wrapper--start">
                                    <button
                                        className="account-reviews__upload-btn shadow-none"
                                        type="button"
                                        onClick={
                                            !values.image
                                                ? onHandleTriggerUpload
                                                : () => setFieldValue('image', null)
                                        }
                                    >
                                        <span className="m-txt">
                                            {!values.image ? (
                                                <div
                                                    style={{
                                                        padding: '25px',
                                                        border: '2px dotted lightgray',
                                                    }}
                                                    className="rounded"
                                                >
                                                    <IoAdd size={30} style={{ color: '#BBBBBB' }} />
                                                </div>
                                            ) : (
                                                <>
                                                    {values.image ? (
                                                        <div className="account-reviews__input-wrapper">
                                                            <div className="account-reviews__image">
                                                                <div
                                                                    className="nav-bar__badge"
                                                                    style={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        borderRadius: '50px',
                                                                    }}
                                                                >
                                                                    <IoRemove
                                                                        size={25}
                                                                        color="white"
                                                                    />
                                                                </div>
                                                                {!errors.image ? (
                                                                    <img
                                                                        src={URL.createObjectURL(
                                                                            values.image
                                                                        )}
                                                                        alt={'review_image'}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                            <p className="m-txt m-txt--small m-txt--error">
                                                                {errors.image}
                                                            </p>
                                                        </div>
                                                    ) : null}
                                                </>
                                            )}
                                        </span>
                                    </button>
                                    <input
                                        hidden
                                        type="file"
                                        name="image"
                                        ref={uploadInputRef}
                                        onChange={(e) => {
                                            setFieldValue('image', e.target.files[0]);
                                        }}
                                    />
                                </div>
                                <div className="account-reviews__button-wrapper">
                                    <Button
                                        text
                                        bold
                                        type="button"
                                        label="CANCEL"
                                        style={{ boxShadow: 'none' }}
                                        onClick={() => setReviewModalVisibility(false)}
                                    />
                                    <Button
                                        gold
                                        text
                                        bold
                                        label="SUBMIT"
                                        type="submit"
                                        style={{ boxShadow: 'none' }}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <div className="account-orders__header generalOrderCardHeader">
                <div className="table">
                    <p
                        className="account-orders__label font-medium table-cell cursor-pointer"
                        onClick={onHandleGoToStore}
                    >
                        {order.companyName}
                        <FiChevronRight className="inline-block" />
                    </p>
                </div>
            </div>
            {product.map((o, i) => (
                <div
                    className={
                        order.status == 'delivered'
                            ? 'generalOrderCardContent_review'
                            : 'generalOrderCardContent'
                    }
                    style={{ borderTop: '1px solid lightGrey' }}
                    onClick={() => onHandleNavigateOrder(order.orderNumber)}
                >
                    <div className="account-orders__card-wrapper--item" key={`${i}-item`}>
                        <CircleAvatar
                            src={o.product.productImages[0]}
                            size={50}
                            onClick={(e) => onHandleNavigateProduct(o.product.id, e)}
                            className="cursor-pointer"
                        />
                        <div className="account-orders__item ml-6">
                            <p
                                className="m-txt m-txt--bold truncate cursor-pointer"
                                onClick={(e) => onHandleNavigateProduct(o.product.id, e)}
                            >
                                {o.product.name}
                            </p>
                            {o.product.variations ? (
                                <p className="text-xs mb-2" style={{ color: '#888888' }}>
                                    {o.product.variations[0].titles[0]
                                        ? o.product.variations[0].titles[0] +
                                          ': ' +
                                          o.product.variations[0].variations.variationOne
                                        : null}
                                    {o.product.variations[0].titles[1]
                                        ? ', ' +
                                          o.product.variations[0].titles[1] +
                                          ': ' +
                                          o.product.variations[0].variations.variationTwo
                                        : null}
                                </p>
                            ) : null}
                            <p className="font-medium text-sm" style={{ color: '#666666' }}>
                                RM {o.product.price.toFixed(2)}
                            </p>
                        </div>
                    </div>

                    <p className="m-txt m-txt--grey w-1/5">
                        <span className="font-medium text-black block text-right mb-4">
                            Qty: {o.quantity}
                        </span>
                        <span
                            className="m-txt m-txt--bold float-right"
                            style={{ color: '#666666' }}
                        >
                            RM {(o.product.price * o.quantity).toFixed(2)}
                        </span>
                        {order.status == 'delivered' ? (
                            <div className="float-right mt-3 -mr-3 ml-3 clear-right">
                                <Button
                                    label="Review"
                                    tiny
                                    gold
                                    style={{ boxShadow: 'none' }}
                                    onClick={(e) => onHandleReviewModal(e, o.product)}
                                />
                            </div>
                        ) : null}
                    </p>
                </div>
            ))}
            {order.status == 'toPack' ? (
                <div
                    className="generalOrderCardContentButton"
                    style={{ borderTop: '1px solid lightGrey' }}
                >
                    <div className="ml-auto">
                        <Button
                            label="Cancel"
                            tiny
                            textWithGoldBorder
                            style={{ boxShadow: 'none' }}
                            onClick={() => setOrderCancelModalVisibility(true)}
                        />
                    </div>
                </div>
            ) : order.status == 'packing' ? (
                <div
                    className="generalOrderCardContentButton"
                    style={{ borderTop: '1px solid lightGrey' }}
                >
                    <div className="ml-auto">
                        <Button
                            label="Cancel"
                            tiny
                            textWithGoldBorder
                            style={{ boxShadow: 'none' }}
                            onClick={() => setOrderCancelModalVisibility(true)}
                        />
                    </div>
                </div>
            ) : order.status == 'pendingPayment' ? (
                <div
                    className="generalOrderCardContentButton"
                    style={{ borderTop: '1px solid lightGrey' }}
                >
                    <div className="ml-auto">
                        <div className="inline-block mr-4">
                            <Button
                                label="Cancel all"
                                tiny
                                textWithGoldBorder
                                style={{ boxShadow: 'none' }}
                                onClick={() => setOrderCancelModalVisibility(true)}
                            />
                        </div>
                        <div className="inline-block">
                            <Button
                                label="Pay Now"
                                tiny
                                gold
                                style={{ boxShadow: 'none' }}
                                onClick={() => payNow(order.orderNumber)}
                            />
                        </div>
                    </div>
                </div>
            ) : null}            
        </div>
    );
};

const OrderCancelModal = ({ isOpen, onHandleVisible }) => {
    return (
        <Modal center showCloseIcon={false} open={isOpen} onClose={() => onHandleVisible(false)}>
            <div className="cart__card bg-white h-auto">
                <img src={orderCancelIcon} className="h-16 mt-4 mb-4 ml-auto mr-auto" />
                <p className="m-txt cart__modal-label text-black font-medium font-sans">
                    Confirm to cancel this order?
                </p>
                <div className="cart__button-wrapper">
                    <Button
                        label="CANCEL"
                        text
                        style={{ boxShadow: 'none' }}
                        onClick={() => onHandleVisible(false)}
                    />
                    <Button
                        label="CONFIRM"
                        text
                        gold
                        bold
                        style={{ boxShadow: 'none' }}
                        onClick={() => onHandleVisible(false, true)}
                    />
                </div>
            </div>
        </Modal>
    );
};
