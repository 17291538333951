import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupPasswordConfig } from 'helpers/utility';
import TextInput from 'components/inputs/text-input/text-input';
import Button from 'components/button/button';
import api from 'services/api';

const initialValues = {
    password: '',
    confirmPassword: '',
    originalPassword: '',
};

const validationSchema = yup.object().shape({
    originalPassword: yup.string().required('Original Password required'),
    password: yupPasswordConfig(),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Confirm password is not matched')
        .required('password is required.'),
});

const AccountResetPasswordPage = (props) => {
    const [onSubmit, setOnSubmit] = useState(false);
    const submitBtnText = onSubmit ? "RESETING" : "RESET PASSWORD";
    const { id } = useSelector(state => state.user.profile);

    const onHandleResetPassword = async (values) => {
        setOnSubmit(true);
        try {
            const payload = { ...values, userId: id }
            const response = await api.post.resetPassword(payload);
            console.log(response);
        }
        catch (error) {
            console.log(error.response.data.message);
        } finally {
            setOnSubmit(false);
        }
    }

    return (
        <div className="account-reset-pwd">
            <h1 className="text-2xl font-medium mb-8">Reset Password</h1>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onHandleResetPassword}
            >
                <Form className={'space-y-8 w-2/5'}>
                    <div className="account-reset-pwd__input-wrapper">
                        <TextInput
                            outline
                            max
                            label="Old Password"
                            name="originalPassword"
                            placeholder="Old Password"
                            type="password"
                            disabled={onSubmit}
                        />
                        <p className="m-txt m-txt--small m-txt--error">
                            <ErrorMessage name={'originalPassword'} />
                        </p>
                    </div>
                    <div className="account-reset-pwd__input-wrapper">
                        <TextInput
                            outline
                            max
                            name="password"
                            type="password"
                            label="New Password"
                            placeholder="New Password"
                            disabled={onSubmit}
                        />
                        <p className="m-txt m-txt--small m-txt--error">
                            <ErrorMessage name={'password'} />
                        </p>
                    </div>
                    <div className="account-reset-pwd__input-wrapper">
                        <TextInput
                            outline
                            max
                            name="confirmPassword"
                            label="Confirm New Password"
                            placeholder="Confirm New Password"
                            type="password"
                            disabled={onSubmit}
                        />
                        <p className="m-txt m-txt--small m-txt--error">
                            <ErrorMessage name={'confirmPassword'} />
                        </p>
                    </div>
                    <div className="account-reset-pwd__button-wrapper">
                        <Button type="submit" label={submitBtnText} disabled={onSubmit} />
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

export default AccountResetPasswordPage;
