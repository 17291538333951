import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';

const RatingPicker = ({ value = 0, onChange, size = 23, forFilterUse = false }) => {
    const [hovered, setHovered] = useState(null)
    let stars = [];

    const onHandleMouseOver = (i) => {
        if (!onChange) return;
        setHovered(i);
    }

    for (let a = 0; a < 5; ++a) {
        const className =
            a < value
                ? 'rating__star rating__star--active'
                : hovered && hovered >= a ? 'rating__star rating__star--active'
                    : 'rating__star';

        stars.push(
            <div key={a} className={className}
                onClick={onChange ? () => onChange(a + 1) : null}
                onMouseOver={() => onHandleMouseOver(a)}
                onMouseOut={() => onHandleMouseOver(null)}
            >
                <FaStar size={size} />
            </div>
        );
    }

    return (
        <div className="rating">
            {stars}
            {forFilterUse ? <p className="m-txt">& Up</p> : null}
        </div>
    );
};

export default RatingPicker;
