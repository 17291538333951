import React from 'react';
import { MdContentCopy } from "react-icons/md";

const Button = ({ outline, gold, text, textRed, small, copy, textWithBorder, textWithGoldBorder, tiny, bold, ...props }) => {
    const className = ['button'];
    const textS = 'button--txt';
    const textR = 'button--txt-red';
    const textB = 'button--txt-border';
    const textBG = 'button--txt-border-gold';
    const outlineS = 'button--outline';
    const goldS = 'button--gold';
    const smallS = 'button--small';
    const tinyS = 'button--tiny';
    const boldW = 'button--bold';
    const textGoldS = 'button--txt-gold';

    if (text) className.push(textS);
    if (textRed) className.push(textR);
    if (textWithBorder) className.push(textB);
    if (text && gold) className.push(textGoldS);
    if (outline) className.push(outlineS);
    if (gold) className.push(goldS);
    if (small) className.push(smallS);
    if (tiny) className.push(tinyS);
    if (bold) className.push(boldW);
    if (textWithGoldBorder) className.push(textBG);

    return (
        <button className={className.join(' ')} {...props} onClick={props.onClick}>
            <p className="button__txt">{props.label}
            {copy?<MdContentCopy size={16} className="inline-block ml-2.5 mb-0.5" style={{transform: "scaleX(-1)", marginLeft: "10px", marginBottom: "2px"}}/>:null}
            </p>
            
        </button>
    );
};

export default Button;
