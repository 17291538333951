import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Layout from "components/layout/layout";
import ShortDurationModal from "components/short-duration-modal/short-duration-modal";
import withInterceptor from 'context/with-interceptor';
import withMessages from 'context/with-messages';

import rootHistory from 'routes/rootRoutes';
import { routes } from 'routes/routes';
import "tailwindcss/tailwind.css";

function App() {    
    return (
        <div className="m-app">
            <Router history={rootHistory}>
                <ShortDurationModal />
                <Layout>
                    <Switch>                        
                        {routes.map((o, i) => {
                            const key = `${i}-route-${o.path}`;
                            const component = (o.component);
                            return (
                                <Route
                                    exact
                                    key={key}
                                    path={o.path}
                                    component={component}
                                />
                            )
                        })}
                    </Switch>
                </Layout>
            </Router>
        </div>
    );
}

export default withInterceptor(App);
