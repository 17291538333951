import { takeEvery, put, call } from "redux-saga/effects";
import { user } from "redux/actions/user-actions";
import api from "services/api";

export default function* userSaga() {
    yield takeEvery(user.USER_PROFILE_GET, getUserProfile);
    yield takeEvery(user.USER_SET_WISH, setWishProduct);
    yield takeEvery(user.USER_NOTIFICATION_SUMMARY_GET, updateNotificationSummary);
}

function* getUserProfile(action) {
    try {
        const userId = action.data;
        const response = yield call(api.get.profile, userId);
        yield put({ type: user.USER_PROFILE_SET, data: response.data.result.user });
    } catch (error) {
        console.log(error.response.data.message);
    }
}

function* setWishProduct(action) {
    try {
        yield call(api.post.wishlistPost, action.data);
    } catch (error) {
        console.log(error.response.data.message);
    }
}

function* updateNotificationSummary(action) {
    try {
        const response = yield call(api.get.notificationSummary, action.data);
        yield put({ type: user.USER_NOTIFICATION_SUMMARY_SET, data: response.data.result });
    } catch (error) {
        console.log(error.response.data.message);
    }
}