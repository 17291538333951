import React, { useCallback, useEffect, useState, Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import { useIsMount } from 'hooks/use-mount';
import api from 'services/api';
import ReviewSection from 'pages/common/reviewSection/reviewSection';
import { pathnames } from 'routes/routes';

const AccountReviewsPage = (props) => {
    const history = useHistory();

    return (
        <div className="account-reviews">
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[{ onClick: ()=>history.push(pathnames.accounts), text: 'My Account' }, 'My reviews']}
                    />
                </div>
            </div>
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                   <h1 className="m-txt m-txt--l m-txt--bold mb-8">My reviews</h1>
                   <ReviewSection />
                </div>
            </div>
        </div>
    );
};
export default AccountReviewsPage;