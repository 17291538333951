import React, { useState, useEffect, useRef, Fragment, useCallback } from 'react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { IoCartOutline, IoHeart, IoPersonSharp, IoAdd, IoRemove } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import SockJsClient from 'react-stomp';
import Scrollbars from 'rc-scrollbars';
import api from 'services/api';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import { formatCurrency } from 'helpers/utility';
import { pathnames } from 'routes/routes';
import { useIsMount } from 'hooks/use-mount';
import ShareButton from 'components/share-button/share-button';
import addToCartIcon from 'assets/products/addToCart.png';
import { FiChevronRight, FiRadio } from 'react-icons/fi';
import { BsPersonFill } from 'react-icons/bs';
import Modal from 'react-responsive-modal';
import _ from 'lodash';
import Button from 'components/button/button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { getUserNotificationSummary } from 'redux/actions/user-actions';
import withCart from 'context/with-cart';
import noProductIcon from 'assets/notfound/no_product.png';

const defaultSelectedVar = {
    variationOne: '',
    variationTwo: '',
    product: null,
};

const getStreamUrl = (streamId) =>
    `${process.env.REACT_APP_MEDIA_ENDPOINT}/LiveApp/streams/${streamId}.m3u8`;
const webSocketUrl = process.env.REACT_APP_WEB_SOCKET;
const reactPlayerConfig = {
    file: {
        forceHLS: true,
    },
};

const joinStreamSocketUrl = '/atf-commerce/app/join-stream';
const leaveStreamSocketUrl = '/atf-commerce/app/leave-stream';
const messageHistorySocketUrl = '/atf-commerce/app/messageHistory';
const sendMsgToAudienceSocketUrl = '/atf-commerce/app/message';
const sendLikeToStreamer = '/atf-commerce/app/like';

const StreamingPage = (props) => {
    const isMount = useIsMount();
    const location = useLocation();
    const scrollbarRef = useRef();
    const { auth, id, displayName, image } = useSelector((state) => state.user.profile);
    const [streamUrl, setStreamUrl] = useState('');
    const [streamData, setStreamData] = useState('');
    const [like, setLike] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const [streamCount, setStreamCount] = useState({
        likes: 0,
        views: 0,
    });
    const dispatch = useDispatch();
    const playerRef = useRef(null);
    const socket = useRef();
    const history = useHistory();
    const { state } = useLocation();
    const { streamingKey } = useParams();
    const [copyData, setCopyData] = useState();
    const [product, setProduct] = useState();
    const [onSubmit, setOnSubmit] = useState(false);
    const heartLiked = like ? 'streaming__icon streaming__icon--liked' : 'streaming__icon';
    const submitBtnText = onSubmit ? 'ADDING' : 'ADD TO CART';
    const [onGoingStreams, setOnGoingStreams] = useState([]);
    const [upcomingStreams, setUpcomingStreams] = useState([]);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [selectedVar, setSelectedVar] = useState(defaultSelectedVar);
    const [purchaseQuantity, setPurchaseQuantity] = useState(1);    
    const userAuth = useSelector((state) => state.user.auth);
    const userProfile = useSelector((state) => state.user.profile);
    const onHandleGetNotificationSummary = useCallback(
        (o) => dispatch(getUserNotificationSummary(o)),
        [dispatch]
    );
    const onHandleGetOnGoingStreams = useCallback(async () => {
        try {
            const payload = {
				screen: 'all',
			};	
            const response = await api.get.announcement(payload);
            setOnGoingStreams(response.data.result.streamAnnouncements);
        }
        catch (error) {
        }
    }, [props]);

    const onHandleGetUpcomingStreams = useCallback(async () => {
        try {
            const payload = {
				screen: 'upcoming',
			};	
            const response = await api.get.announcement(payload);
            setUpcomingStreams(response.data.result.streamAnnouncements);
        }
        catch (error) {
        }
    }, [props]);

    useEffect(() => {
        if (isMount) {
            onHandleGetOnGoingStreams();
        }
    }, [isMount, onHandleGetOnGoingStreams]);

    useEffect(() => {
        if (isMount) {
            onHandleGetUpcomingStreams();
        }
    }, [isMount, onHandleGetUpcomingStreams]);
    let websocketTopics = [
        `/atf-commerce/topic/${streamingKey}/messageHistory/${id}`,
        `/atf-commerce/topic/${streamingKey}/message`,
        `/atf-commerce/topic/${streamingKey}/like`,
        `/atf-commerce/topic/${streamingKey}/view`,
    ];
    const socketPayload = JSON.stringify({
        userId: id,
        userName: displayName,
        streamKey: streamingKey,
    });

    const onPlayerReady = () => {};
    const onPlayerEnded = () => {
        history.push(pathnames.liveStream);
    };
    const onPlayerError = (error) => {
    };

    const onReceivedMessage = (response) => {
        switch (response.responseType) {
            case 'messageHistory':
                if (response.messageHistory) {
                    const messageHistory = response.messageHistory.map((i) => {
                        return {
                            type: i.responseType,
                            content: i.content,
                            name: i.senderName,
                            profile: i.senderImageUrl,
                        };
                    });
                    setChatMessages(messageHistory);
                }
                break;
            default:
                const messages = chatMessages ? [...chatMessages] : [];
                switch (response.responseType) {
                    case 'host':
                    case 'audience':
                        const audienceResponse = {
                            type: response.responseType,
                            content: response.content,
                            name: response.senderName,
                            profile: response.senderImageUrl,
                        };
                        messages.push(audienceResponse);
                        setChatMessages(messages);
                        break;
                    case 'like':
                        setStreamCount({
                            ...streamCount,
                            likes: response.currentLikeCount,
                        });
                        break;
                    case 'view':
                        setStreamCount({
                            ...streamCount,
                            views: response.currentViewCount,
                        });

                        if (response.lastJoinedUserName) {
                            const joinedResponse = {
                                type: response.responseType,
                                content: `${response.lastJoinedUserName} has joined`,
                            };
                            messages.push(joinedResponse);
                            setChatMessages(messages);
                        }
                        break;
                    default:
                        break;
                }

                scrollbarRef.current?.scrollToBottom();
                break;
        }
    };

    const onHandleSendMessage = (event) => {
        event.preventDefault();
        const form = event.target;
        const msg = form.chat.value;
        if (!msg) return;
        const trimMsg = msg.trim();
        sendMessage(trimMsg);
        form.reset();
    };

    const sendMessage = useCallback(
        (msg) => {
            var userPayload = JSON.stringify({
                ...JSON.parse(socketPayload),
                senderType: 'audience',
                senderId: id,
                senderName: displayName,
                senderImageUrl: image,
                content: msg,
            });
            if (socket.current) {
                socket.current.sendMessage(sendMsgToAudienceSocketUrl, userPayload);
            }
        },
        [displayName, id, image, socketPayload]
    );

    const toggleLike = () => {
        setLike(!like);
        sendLikes(!like);
    };

    const sendLikes = (like) => {
        const likesPayload = JSON.stringify({
            ...JSON.parse(socketPayload),
            liked: like,
        });

        if (socket.current) {
            socket.current.sendMessage(sendLikeToStreamer, likesPayload);
        }
    };

    const getMessageHistory = useCallback(() => {
        if (socket.current) {
            socket.current.sendMessage(messageHistorySocketUrl, socketPayload);
        }
    }, [socketPayload]);

    const onJoinedStream = () => {
        if (socket.current) {
            const joinedPayload = JSON.stringify({
                ...JSON.parse(socketPayload),
                userType: 'audience',
            });
            socket.current.sendMessage(joinStreamSocketUrl, joinedPayload);
        }
    };

    const onConnectStream = () => {
        if (socket.current) {
            getMessageHistory();
            onJoinedStream();
        }
    };

    const onLeaveStream = useCallback(() => {
        if (socket.current) {
            try {
                socket.current.sendMessage(leaveStreamSocketUrl, socketPayload);
                socket.current.disconnect();
            } catch (error) {
                console.log(error);
            }
        }
    }, [socketPayload]);

    useEffect(() => {
        if (isMount) {
            if (streamingKey) {
                setStreamUrl(getStreamUrl(streamingKey));
            }
            return () => onLeaveStream;
        }
    }, [isMount, streamingKey, onLeaveStream]);    

	const getStreamData = async () => {
		if (streamingKey) {
			try {
				const response = await api.get.announcementDetails(streamingKey);	
				setStreamData(response.data.result);
			} catch (error) {
			}
		}
	};

    useEffect(() => {
        if (isMount) {
		getStreamData();
    }
	}, [isMount, getStreamData]);

    const getCopyData = () => {
        setCopyData("Let's watch " + streamData?.companyName + "'s liveshow " + streamData?.title + " at " + window.location.href + " via Buyer ");
    }

    const onHandleRedirectStreamTabs = (type) => {
        switch(type){
            case 'onGoing':{
                localStorage.setItem('streamTab', 0);
                history.push(pathnames.liveStreamTabs);
                break;
            }
            case 'upComing':{
                localStorage.setItem('streamTab', 1);
                history.push(pathnames.liveStreamTabs);
                break;
            }
        }
    }

    const onHandleRedirectStream = (key) => {
        history.push(pathnames.streaming.replace(':streamingKey', key));
        window.location.reload();
    }

    const onHandleGoToStore = (id) => {
        const path = pathnames.merchart.replace(':merchantId', id);
        history.push(path);
    };

    const onHandleNavigateProduct = (id) => {
        const path = pathnames.productDetails.replace(':productId', id);
        history.push(path, { from: location.pathname });
    };

    const onHandleAddToCart = useCallback(async (id) => {
        try {
            const response = await api.get.productDetails(id);
            const results = response.data.result;
            setProduct(results.product);
            
        } catch (error) {
        }
        finally{
            setModalVisibility(true);
        }
    }, [id]);

    const onHandleSelectVar = (item, type) => {
        const options = [...product?.variations];
        const typeTwoOption = options[0].titles.length === 2;
        const selected = { ...selectedVar };
        const isOptionOne = type === 'variationOne';
        const isOptionTwo = type === 'variationOne';
        selected[type] = item;

        if (type === 'variationOne' && selected.variationTwo) {
            selected.variationTwo = '';
            selected.product = null;
        }

        const variationOne = isOptionOne ? item : selected.variationOne;
        const variationTwo = isOptionTwo ? item : selected.variationTwo;

        if (typeTwoOption && variationOne && variationTwo) {
            const getSelectedProduct = options.filter(
                (o) =>
                    o.variations.variationOne === variationOne &&
                    o.variations.variationTwo === variationTwo
            );

            selected.product = getSelectedProduct[0];
        }

        if (!typeTwoOption) {
            const getSelectedProduct = options.filter(
                (o) => o.variations.variationOne === variationOne
            );
            selected.product = getSelectedProduct[0];
        }

        setPurchaseQuantity(1);
        setSelectedVar(selected);
        getCopyData();
    };

    
    const onHandleQuantity = useCallback(
        (type) => {
            let totalStock = 0;
            const productItems = product;

            if (selectedVar && productItems?.variations) {
                totalStock = selectedVar.product?.stock;
            } else {
                totalStock = productItems?.stock;
            }
            switch (type) {
                case 'decrease':
                    if (purchaseQuantity - 1 > 0)
                        setPurchaseQuantity(parseInt(purchaseQuantity) - 1);
                    break;
                case 'increase':
                    if (purchaseQuantity + 1 <= totalStock)
                        setPurchaseQuantity(parseInt(purchaseQuantity) + 1);
                    break;
                default:
                    break;
            }
        },
        [purchaseQuantity, product, selectedVar]
    );

    const onHandleQuantityInput = useCallback(
        (e) => {
            const value = e.currentTarget.value;
            const selected = selectedVar.product;
            const productStock = product?.stock;
            let totalStock = 0;

            if (selected) {
                totalStock = selected?.stock;
            } else {
                totalStock = productStock;
            }

            if (value > totalStock) {
                setPurchaseQuantity(totalStock);
            } else {
                setPurchaseQuantity(value);
            }
        },
        [product, selectedVar]
    );

    const onHandleBlurQuantityInput = useCallback((e) => {
        const value = e.currentTarget.value;
        if (!value) {
            setPurchaseQuantity(1);
        }
    }, []);

    const isDisabled = () => {
        if (!product || onSubmit) return true;

        if (selectedVar.product || product.variations) {
            return !selectedVar?.product?.stock;
        } else {
            return !product?.stock;
        }
    };

    const onBtnAddToCart = () => {
        if (!product) return;

        if (!userAuth) {
            props.onHandleToggleLogin();
        } else {
            setOnSubmit(true);
            const variationId = selectedVar.product?.id || null;
            const productId = product?.id;
            const payload = {
                userId: userProfile.id,
                productId: productId,
                quantity: purchaseQuantity,
                variationId: variationId,
            };
            props.onHandleUpdateCart(payload, (error) => {
                if (!error) {
                    onHandleGetNotificationSummary(userProfile.id);
                    setTimeout(function () {
                        createNotification('success', 'Added To Cart');
                    }, 1000);
                }
                setOnSubmit(false);
            });
        }
    };

    const createNotification = (type, message, title) => {
        switch (type) {
            case 'info':
                NotificationManager.info(message);
                break;
            case 'success':
                NotificationManager.success(message);
                break;
            case 'warning':
                NotificationManager.warning(message);
                break;
            case 'error':
                NotificationManager.error(title, message);
                break;
        }
    };

    return (
        <div className="streaming">
            <NotificationContainer />
            <Modal
                center
                open={modalVisibility}
                onClose={() => setModalVisibility(false)}
                classNames={{ modal: 'w-1/2 p-8' }}
            >
                <div className="flex pb-4 mb-4 product__section--divider">
                <CircleAvatar src={product?.productImages[0]} size={55}/>
                    <div className="ml-4 font-medium">
                        <p>{product?.name}</p>
                        <p className="text-red-500">RM {product?.price}</p>
                    </div>
                </div>
                {product && product?.variations ? (
                                    <div className="product__section product__section--column product__section--divider pb-4 mb-4">
                                        <div className="product__content product__content">
                                            <ProductVariations
                                                data={product.variations}
                                                selected={selectedVar}
                                                type="variationOne"
                                                onSelect={onHandleSelectVar}
                                            />
                                        </div>
                                        <div className="product__content product__content">
                                            {selectedVar.variationOne &&
                                            Object.keys(product.variationLevels).length >
                                                1 ? (
                                                <ProductVariations
                                                    data={product.variations}
                                                    selected={selectedVar}
                                                    type="variationTwo"
                                                    onSelect={onHandleSelectVar}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}
                                <div className="product__section pb-4 mb-4">
                                    <div className="product__quantity-ctrl">
                                        <p>
                                            Quantity
                                        </p>
                                        <div className="product__quantity-wrapper ml-auto">
                                            <button
                                                className="product__quantity-btn product__quantity-btn--outline"
                                                onClick={() => onHandleQuantity('decrease')}
                                                disabled={purchaseQuantity === 1}
                                            >
                                                <IoRemove />
                                            </button>
                                            <input
                                                type="number"
                                                className="product__quantity-input"
                                                value={purchaseQuantity}
                                                onChange={onHandleQuantityInput}
                                                onBlur={onHandleBlurQuantityInput}
                                                disabled={isDisabled()}
                                            />
                                            <button
                                                className="product__quantity-btn"
                                                onClick={() => onHandleQuantity('increase')}
                                                disabled={isDisabled()}
                                            >
                                                <IoAdd />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Button
                                        label={submitBtnText}
                                        small
                                        disabled={isDisabled()}
                                        onClick={onBtnAddToCart}
                                    />
                                </div>
            </Modal>
            <Fragment>
                <SockJsClient
                    url={webSocketUrl}
                    topics={websocketTopics}
                    onMessage={onReceivedMessage}
                    onConnect={onConnectStream}
                    onDisconnect={onLeaveStream}
                    ref={socket}
                />

                <div className="m-container m-container--center">
                    <div className="m-wrapper">
                        <BreadCrumb
                            paths={[{ onClick: ()=>history.push(pathnames.liveStream), text: 'Live stream' },'Watch live stream']}
                        />
                    </div>
                </div>
                <div className="m-container m-container--center">
                <div className="m-wrapper my-3">
                    <p className="m-txt m-txt--bold m-txt--l">{streamData?.title}</p>
                </div>
            </div>
                <div className="streaming__container m-container m-container--center">
                    <div className="m-wrapper streaming__wrapper relative">
                        <div className="streaming__section">
                            <ReactPlayer
                                className="streaming__video"
                                ref={playerRef}
                                url={streamUrl}
                                controls={true}
                                volume={1}
                                config={reactPlayerConfig}
                                playing={true}
                                muted={true}
                                width="100%"
                                height="100%"
                                onReady={onPlayerReady}
                                onEnded={onPlayerEnded}
                                onError={onPlayerError}
                            />
                                <div className="absolute" style={{ width: "70%", padding: "10px", height: "80%"}}>
                                <div className="streaming__count-wrapper">
                                    <CircleAvatar src={streamData.companyImage} size={20} onClick={() => onHandleGoToStore(streamData.companyId)}/>
                                    <p className="m-txt m-txt--white m-txt--s ml-3">
                                        {streamData.companyName}
                                    </p>
                                    <div onClick={()=>getCopyData()} className="ml-auto">
                                        <ShareButton url={window.location.href} copyData={copyData} white/>
                                    </div>
                                </div>
                                <div className="streaming__count-wrapper">
                                    <IoPersonSharp size={20} className="streaming__icon" />
                                    <p className="m-txt m-txt--white m-txt--s">
                                        {streamCount.views} views
                                    </p>
                                </div>
                                <Scrollbars ref={scrollbarRef} autoHide style={{width:"70%", height:"75%", marginTop: "5%", overflow: "hidden", marginLeft: "1rem"}}>
                                <div className="streaming__product-wrapper">
                                    {chatMessages.map((o, i) => {
                                        const key = `${i}-chat-bubble`;
                                        const type =
                                            o.type === 'host' ? `m-txt--gold` : 'm-txt--valid';

                                        switch (o.type) {
                                            case 'view':
                                                return (
                                                    <div
                                                        key={key}
                                                        className="streaming__chat-bubble"
                                                    >
                                                        <div className="streaming__chat-bubble-content streaming__chat-bubble-content--joined ml-0">
                                                            <p className="m-txt m-txt--white m-txt--s">
                                                                {o.content}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            default:
                                                return (
                                                    <div
                                                        key={key}
                                                        className="streaming__chat-bubble"
                                                    >
                                                        <CircleAvatar src={o.profile} size={26} />
                                                        <div className="streaming__chat-bubble-content">
                                                            <p className="m-txt m-txt--white m-txt--s">
                                                                {o.content}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                        }
                                    })}
                                </div>
                            </Scrollbars>
                            <div className="streaming__chat-input">
                                <form onSubmit={onHandleSendMessage}>
                                    <input name="chat" placeholder="Say something..." style={{border:"1px solid white"}} className="px-4 mr-4 ml-2"/>
                                    
                                    <IoHeart
                                        size={30}
                                        onClick={toggleLike}
                                        className={heartLiked}
                                    />
                                </form>
                            </div>

                                </div>
                        </div>
                        <div className="product_card">
                            <p className="mb-3">Products</p>
                            {streamData?.liveProducts?.length > 0? 
                            <Scrollbars autoHide style={{height:"90%"}}>
                                <div style={{height:"90%"}}>
                                {streamData?.liveProducts?.map((o,i)=> {
                                    return(
                                    <div className="flex" style={i != 0 ?{borderTop:"1px solid rgba(0, 0, 0, 0.1)"}:null}>
                                        <CircleAvatar src={o.productImage} size={40} onClick={() =>onHandleNavigateProduct(o.productId)}/>
                                        <div style={{width: "65%"}} className="px-4 py-2 font-medium cursor-pointer" onClick={() =>onHandleNavigateProduct(o.productId)}>
                                            <p>{o.productName}</p>
                                            <p className="text-red-500">RM {o.productPrice}</p>
                                        </div>
                                        <CircleAvatar src={addToCartIcon} size={25} onClick={() => onHandleAddToCart(o.productId)}/>
                                    </div>)
                                })}
                                </div>
                            </Scrollbars>
                            :
                                <div className="m-wrapper">
                                    <img src={noProductIcon} className="m-auto mt-48 mb-4 w-12" />
                                    <p className="text-center mb-40 text-gray-400">Sorry, no product yet</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>                
                <div className="streaming__container m-container m-container--center mt-16">
                    <div className="m-wrapper streaming__wrapper">
                        <div className="streaming__section--product">
                        {onGoingStreams.length > 0?
                        <div className="w-full" style={upcomingStreams.length > 0?{ paddingBottom: '2rem', marginBottom: '2rem' }:null}>
                            <div className="w-full flex justify-between">
                                <div className="mb-4 inline-block">
                                    <p className="font-medium" style={{fontSize: '26px'}}>Other live stream</p>
                                </div>
                                <div className="cursor-pointer">
                                    <p className="inline-block" onClick={()=> onHandleRedirectStreamTabs('onGoing')}>View all</p>
                                    <FiChevronRight className="inline-block" />
                                </div>
                            </div>
                            <div className="flex">                        
                            {onGoingStreams.map((o, i) => {
                                const key = `${i}-stream-item`;
                                return (
                                    <>
                                {i < 4? 
                                    <div style={{width:"25%"}}>
                                        <div className="live-stream__card" key={key} >
                                            <div onClick={() => onHandleRedirectStream(o.streamKey)}>
                                                <div className="live-stream__image" style={{ backgroundImage: `url(${o.image})` }}>  
                                                    <p className="text-white absolute mt-3 ml-4 flex pr-4" style={{padding:"3px", backgroundColor: "#C19E66", fontSize: "14px"}}><FiRadio className="mt-auto mb-auto mx-2" size={18}/>Live</p>
                                                </div>                                    
                                            </div>                                                                                
                                        </div>
                                        <div>
                                            <div className="live-stream__content-wrapper cursor-pointer" style={{height: "48px"}} onClick={() => history.push(pathnames.streaming.replace(':streamingKey', o.id))}>
                                                <p className="font-medium mx-2" 
                                                style={{
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-word',
                                                    overflow: 'hidden',
                                                    display: '-webkit-box',
                                                    '-webkit-line-clamp': '2',
                                                    '-webkit-box-orient': 'vertical',
                                                }}>{o.title}</p>
                                            </div>
                                        </div>
                                        <div className="live-stream__content">
                                            <CircleAvatar src={o.companyImage} size={25} onClick={() => onHandleGoToStore(o.companyId)}/>
                                            <div className="live-stream__content-wrapper truncate">
                                                <p>{o.companyName}</p>
                                            </div>
                                        </div>
                                        <div className="live-stream__content pt-0">
                                            <BsPersonFill size={25} style={{color: "#C19E66"}}/>
                                            <div className="live-stream__content-wrapper truncate" style={{marginTop:"2px"}}>
                                                <p className="font-medium" style={{color:"#666666"}}>{o.viewCount? o.viewCount : 0} viewing</p>
                                            </div>
                                        </div>    
                                    </div>
                                :null}
                                </>
                                )                                
                            })}
                            </div>   
                        </div>
                    :null}
                        </div>
                    </div>
                </div>
            </Fragment>
        </div>
    );
};

export default withCart(StreamingPage);

const ProductVariations = ({ data, type, selected, onSelect }) => {
    if (!data) return null;

    const options = _.uniq(data.map((o) => o.variations[type]));
    const typeTwoOption = data[0].titles.length === 2;

    return (
        <div className="product__vars">
            <p className="m-txt product__txt--label pt-0">
                {data[0].titles[type === 'variationOne' ? 0 : 1]}
            </p>
            <div className="product__vars-wrapper">
                {options.map((item, i) => {
                    let disabled = false;
                    const selectedProduct = data.filter(
                        (o) => o.variations.variationOne === selected.variationOne
                    );

                    if (typeTwoOption && selected.variationOne) {
                        const equalToCurrentItem = selectedProduct.filter(
                            (o) => o.variations.variationTwo === item
                        );

                        if (equalToCurrentItem[0] && equalToCurrentItem[0].stock === 0) {
                            disabled = true;
                        }
                    } else {
                        if (selectedProduct[0] && selectedProduct[0].stock === 0) {
                            disabled = true;
                        }
                    }

                    const optionsClassName =
                        selected[type] === item && !disabled
                            ? `product__var-item product__var-item--active`
                            : !disabled
                            ? 'product__var-item'
                            : `product__var-item product__var-item--disabled`;

                    return (
                        <div
                            key={`${i}-v-${type}`}
                            className={optionsClassName}
                            onClick={() => onSelect(item, type)}
                        >
                            <p className="m-txt m-txt--s">{item}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
