import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIsMount } from 'hooks/use-mount';
import moment from 'moment';
import Modal from 'react-responsive-modal';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import RatingPicker from 'components/rating-picker/rating-picker';
import api from 'services/api';
import starIcon from 'assets/notfound/star.png';
import Button from 'components/button/button';
import config from 'helpers/config';
import { IoClose, IoAdd, IoRemove } from 'react-icons/io5';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { pathnames } from 'routes/routes';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import noStreamIcon from 'assets/notfound/live-stream.png';
import { FiChevronRight, FiRadio } from 'react-icons/fi';
import { RiAlarmLine } from 'react-icons/ri';
import { BsPersonFill } from 'react-icons/bs';
import { formatStreamDate } from 'helpers/utility';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LiveStreamTabsPage = (props) => {
    const history = useHistory();
    const isMount = useIsMount();
    const [value, setValue] = React.useState(
        localStorage.getItem('streamTab') == 0 ? 0 : localStorage.getItem('streamTab') == 1 ? 1 : 0
    );
    const [onGoingStreams, setOnGoingStreams] = useState([]);
    const [upcomingStreams, setUpcomingStreams] = useState([]);

    const onHandleGetOnGoingStreams = useCallback(async () => {
        try {
            const payload = {
				screen: 'all',
			};	
            const response = await api.get.announcement(payload);
            setOnGoingStreams(response.data.result.streamAnnouncements);
        } catch (error) {}
    }, [props]);

    const onHandleGetUpcomingStreams = useCallback(async () => {
        try {
            const payload = {
				screen: 'upcoming',
			};	
            const response = await api.get.announcement(payload);
            setUpcomingStreams(response.data.result.streamAnnouncements);
        } catch (error) {}
    }, [props]);

    useEffect(() => {
        if (isMount) {
            onHandleGetOnGoingStreams();
        }
    }, [isMount, onHandleGetOnGoingStreams]);

    useEffect(() => {
        if (isMount) {
            onHandleGetUpcomingStreams();
        }
    }, [isMount, onHandleGetUpcomingStreams]);

    const handleChange = (event, newValue) => {
        localStorage.setItem('streamTab', newValue);
        setValue(newValue);
    };

    const onHandleGoToStore = (id) => {
        const path = pathnames.merchart.replace(':merchantId', id);
        history.push(path);
    };

    return (
        <div className="account-settings">            
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[
                            {
                                onClick: () => history.push(pathnames.liveStream),
                                text: 'Live stream',
                            },
                            'All live stream',
                        ]}
                    />
                </div>
            </div>
            <div className="m-container m-container--center">
                <div className="m-wrapper mt-6">
                    <p className="m-txt m-txt--bold" style={{ fontSize: '26px' }}>Live Stream</p>
                </div>
            </div>
            <div className="m-container m-container--center">
                <AppBar
                    className="m-wrapper ml-5 z-0"
                    position="static"
                    style={{ background: 'none', boxShadow: 'none', color: 'black', marginTop: 20 }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { background: '#000000', height: '3px' } }}
                        className="mr-auto"
                    >
                        <Tab
                            disableRipple
                            label="Live Now"
                            {...a11yProps(0)}
                            style={{ boxShadow: 'none', textTransform: 'capitalize' }}
                        />
                        <Tab
                            disableRipple
                            label="Upcoming"
                            {...a11yProps(1)}
                            style={{ boxShadow: 'none', textTransform: 'capitalize' }}
                        />
                    </Tabs>
                </AppBar>
            </div>

            <TabPanel
                value={value}
                index={0}
                className="bg-white rounded-md mt-1"
                className="singleOrderTab"
            >
                <div className="m-container m-container--center">
                    <div className="m-wrapper account-settings__wrapper">
                        <div className="w-full m-card border border-gray-300 mt-5 p-10">
                        {onGoingStreams.length > 0 ? (
                                <div className="w-full">
                                    <div className="w-full flex justify-between">
                                        <div className="mb-4 inline-block">
                                            <p className="font-medium" style={{ fontSize: '26px' }}>
                                                Live now
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap">
                                        {onGoingStreams.map((o, i) => {
                                            const key = `${i}-stream-item`;
                                            return (
                                                <>
                                                    <div className="w-1/4 mt-2">
                                                        <div
                                                            className="live-stream__card"
                                                            key={key}
                                                        >
                                                            <div
                                                                 onClick={() => history.push(pathnames.streaming.replace(':streamingKey', o.streamKey))}
                                                            >
                                                                <div className="live-stream__image" style={{ backgroundImage: `url(${o.image})` }}>  
                                                    <p className="text-white absolute mt-3 ml-4 flex pr-4" style={{padding:"3px", backgroundColor: "#C19E66", fontSize: "14px"}}><FiRadio className="mt-auto mb-auto mx-2" size={18}/>Live</p>
                                                </div>   
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div
                                                                className="live-stream__content-wrapper cursor-pointer"
                                                                style={{ height: '48px' }}
                                                                onClick={() => history.push(pathnames.streaming.replace(':streamingKey', o.streamKey))}
                                                            >
                                                                <p
                                                                    className="font-medium mx-2"
                                                                    style={{
                                                                        textOverflow: 'ellipsis',
                                                                        wordBreak: 'break-word',
                                                                        overflow: 'hidden',
                                                                        display: '-webkit-box',
                                                                        '-webkit-line-clamp': '2',
                                                                        '-webkit-box-orient':
                                                                            'vertical',
                                                                    }}
                                                                >
                                                                    {o.title}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="live-stream__content">
                                                            <div className="mt-auto mb-auto">
                                                                <CircleAvatar
                                                                    src={o.companyImage}
                                                                    size={20}
                                                                    onClick={() => onHandleGoToStore(o.companyId)}
                                                                />
                                                            </div>
                                                            <div className="live-stream__content-wrapper truncate">
                                                                <p className="mt-auto mb-auto">
                                                                    {o.companyName}
                                                                </p>
                                                            </div>                                                            
                                                        </div>
                                                        <div className="live-stream__content pt-0">
                                            <BsPersonFill size={25} style={{color: "#C19E66"}}/>
                                            <div className="live-stream__content-wrapper truncate" style={{marginTop:"2px"}}>
                                                <p className="font-medium" style={{color:"#666666"}}>{o.viewCount? o.viewCount : 0} viewing</p>
                                            </div>
                                        </div> 
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <EmptyCard />
                            )}
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel
                value={value}
                index={1}
                className="bg-white rounded-md mt-1"
                className="singleOrderTab"
            >
                <div className="m-container m-container--center">
                    <div className="m-wrapper account-settings__wrapper">
                        <div className="w-full m-card border border-gray-300 mt-5 p-10">
                            {upcomingStreams.length > 0 ? (
                                <div className="w-full">
                                    <div className="w-full flex justify-between">
                                        <div className="mb-4 inline-block">
                                            <p className="font-medium" style={{ fontSize: '26px' }}>
                                                Upcoming
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap">
                                        {upcomingStreams.map((o, i) => {
                                            const key = `${i}-stream-item`;
                                            return (
                                                <>
                                                    <div className="w-1/4 mt-2">
                                                        <div
                                                            className="live-stream__card"
                                                            key={key}
                                                        >
                                                            <div
                                                                 onClick={() => history.push(pathnames.streaming.replace(':streamingKey', o.streamKey))}
                                                            >
                                                                <div onClick={() => history.push(pathnames.streaming.replace(':streamingKey', o.streamKey))}>
                                                <div className="live-stream__image" style={{ backgroundImage: `url(${o.image})` }}>  
                                                    <p className="text-white absolute mt-3 ml-4 flex pr-4" style={{padding:"3px", backgroundColor: "#333333", fontSize: "12px"}}><RiAlarmLine className="mt-auto mb-auto mx-2"/>{formatStreamDate(o.streamDate)}</p>
                                                </div>                                    
                                            </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div
                                                                className="live-stream__content-wrapper cursor-pointer"
                                                                style={{ height: '48px' }}
                                                                onClick={() => history.push(pathnames.streaming.replace(':streamingKey', o.streamKey))}
                                                            >
                                                                <p
                                                                    className="font-medium mx-2"
                                                                    style={{
                                                                        textOverflow: 'ellipsis',
                                                                        wordBreak: 'break-all',
                                                                        overflow: 'hidden',
                                                                        display: '-webkit-box',
                                                                        '-webkit-line-clamp': '2',
                                                                        '-webkit-box-orient':
                                                                            'vertical',
                                                                    }}
                                                                >
                                                                    {o.title}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="live-stream__content">
                                                            <div className="mt-auto mb-auto">
                                                                <CircleAvatar
                                                                    src={o.companyImage}
                                                                    size={20}
                                                                    onClick={() => onHandleGoToStore(o.companyId)}
                                                                />
                                                            </div>
                                                            <div className="live-stream__content-wrapper truncate">
                                                                <p className="mt-auto mb-auto">
                                                                    {o.companyName}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <EmptyCard />
                            )}
                        </div>
                    </div>
                </div>
            </TabPanel>
        </div>
    );
};

export default LiveStreamTabsPage;

const EmptyCard = () => {
    const history = useHistory();
    return (
        <div className="home__products  container m-container m-container--center mt-0">
            <div className="m-wrapper">
                <img src={noStreamIcon} className="m-auto mt-40 mb-4 w-12" />
                <p className="text-center text-gray-400 mb-40">
                    Sorry, no live stream at{'\n'}the moment
                </p>                
            </div>
        </div>
    );
};
