import React, { useState, Fragment } from 'react';
import { Modal } from 'react-responsive-modal';
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share';
import {
    IoLogoWhatsapp,
    IoShareSocialOutline,
    IoArrowRedoOutline,
    IoLogoFacebook,
    IoLogoTwitter,
    IoMailOutline,
    IoClose,
} from 'react-icons/io5';
import Button from 'components/button/button';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ShareButton = ({ url = '', white, size = 30, children, copyData }) => {
    const [canShare, setCanShare] = useState(false);
    const iconClassName = ['share-button__icon'];

    if (white) {
        iconClassName.push('share-button__icon--white');
    }

    const onHandleToggle = () => {
        console.log('RUN');
        setCanShare(!canShare);
    };

    const onHandleCopy = () => {
        console.log(copyData);
        setCanShare(!canShare);
    };

    return (
        <Fragment>
            <div>
                <button onClick={onHandleToggle} className="shadow-none">
                    {children ? (
                        children
                    ) : (
                        <IoArrowRedoOutline size={size} className={iconClassName.join(' ')} />
                    )}
                </button>
              {canShare?
                <div
                    className="share-card m-card speech upperArrow absolute place-content-start"
                   style={{transform: "translateX(-270px)"}}
                >
                    <div className="flex flex-row ">
                        <p className="m-txt m-txt--bold">Share via:</p>
                        <FacebookShareButton url={url}>
                            <IoLogoFacebook size={24} color="#4267B2" className="m-0" />
                        </FacebookShareButton>
                        <TwitterShareButton url={url}>
                            <IoLogoTwitter size={24} color="#1DA1F2" className="m-0" />
                        </TwitterShareButton>
                        <WhatsappShareButton url={url}>
                            <IoLogoWhatsapp size={24} color="#25D366" className="m-0" />
                        </WhatsappShareButton>
                    </div>
                    <div class="share_separator w-4/5 text-black">or</div>
                    <CopyToClipboard text={copyData}>
                    <Button label="Copy link" textWithBorder small copy onClick={onHandleCopy}/>           
                    </CopyToClipboard>         
                </div>
                :null}
            </div>
            {/* <Modal open={canShare} showCloseIcon={false} center={true} onClose={() => setCanShare(false)}>
                <div className="share-card m-card">                   
                    <p className="m-txt m-txt--bold">Share now:</p>
                    <div className="share-card__button">
                        <FacebookShareButton url={url}>
                            <IoLogoFacebook size={30} color="#4267B2" />
                        </FacebookShareButton>
                        <TwitterShareButton url={url}>
                            <IoLogoTwitter size={30} color="#1DA1F2" />
                        </TwitterShareButton>
                        <WhatsappShareButton url={url}>
                            <IoLogoWhatsapp size={30} color="#25D366" />
                        </WhatsappShareButton>
                        <EmailShareButton url={url}>
                            <IoMailOutline size={30} color="#DB4437" />
                        </EmailShareButton>
                    </div>
                </div>
            </Modal> */}
        </Fragment>
    );
};

export default ShareButton;
