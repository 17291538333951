import axios from "axios";

export const axiosService = {
  get: async (url, params = {}, config = {}) => {
    return axios.get(url, { params, ...(await headers()), ...config });
  },
  getWithoutToken: async (url, params = {}, config = {}) => {
    return axios.get(url, { params, ...(await headers(false)), ...config });
  },
  post: async (url, body = {}) => {
    return axios.post(url, body, await headers());
  },
  patch: async (url, body = {}) => {
    return axios.patch(url, body, await headers());
  },
  delete: async (url, params = {}) => {
    return axios.delete(url, { params, ...(await headers()) });
  },
};

const headers = async (withToken = true) => {
  let headers = { "Content-Type": "application/json" };

  try {
    if (withToken) {
      const authToken = localStorage.getItem("@storage_token");
      if (authToken) {
        headers = Object.assign({}, headers, {
          Authorization: "Bearer " + authToken,
        });
      }
    }
  } catch (error) {
    return error;
  }
  return { headers };
};
