import React from 'react'
import GoogleLogin from 'react-google-login';
import googleIcon from 'assets/login/google-icon.svg';

const GoogleSSOButton = ({ appId, onLoginSuccess, onLoginFailure }) => {
    return (
        <GoogleLogin
            clientId={appId}
            icon={false}
            className="sso-btn"
            onSuccess={onLoginSuccess}
            onFailure={onLoginFailure}
            cookiePolicy={'single_host_origin'}
        >
            <img src={googleIcon} alt="google login"/>
        </GoogleLogin>
    );
}

export default GoogleSSOButton;