import { combineReducers } from 'redux';
import { user } from 'redux/actions/user-actions';
import userReducer from './user-reducer';
import productsReducer from './products-reducer';
import infoReducer from './info-reducer';


const appReducers = combineReducers({
    user: userReducer,
    info: infoReducer,
    products: productsReducer,
});

const rootReducers = (state, action) => {
    if (action.type === user.USER_LOGOUT) {
        return appReducers(undefined, action)
    }
    return appReducers(state, action);
}

export default rootReducers;