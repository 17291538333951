import { products } from 'redux/actions/products-actions';

const initialState = {
  lists: null,
  product: null,
}

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case products.PRODUCTS_SET:
      let lists = null;

      if (state.lists) {
        lists = {
          ...state.list,
          productList: state.lists.productList.concat(action.data.productList)
        }
      }
      else {
        lists = action.data
      }
      return {
        ...state,
        lists
      }
    case products.PRODUCTS_CLEAR:
      return initialState;
    default:
      return state;
  }
}

export default productsReducer;