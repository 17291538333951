import React from 'react';
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';

const BreadCrumb = ({ paths = [] }) => (
    <div className="breadcrumb">
        {paths.map((path, index) => {
            const pathIsObject = typeof path === 'object';
            const className =
                index >= paths.length - 1
                    ? 'breadcrumb__link breadcrumb__link--active'
                    : 'breadcrumb__link';

            if (pathIsObject && 'linkTo' in path) {
                return (
                    <Link key={index} className={className} to={path.linkTo}>
                        {index > 0 && " / "}
                        <p>{path.text}</p>
                    </Link>
                );
            } else {
                return (
                    <div className={className} key={index} onClick={path.onClick}>
                        {index > 0 && " / "}
                        <p>{pathIsObject ? path.text : path}</p>
                    </div>
                );
            }
        })}
    </div>
);

export default BreadCrumb;
