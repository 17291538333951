import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import Button from 'components/button/button';
import GoogleSSOButton from 'components/sso/google';
import FacebookSSOButton from 'components/sso/facebook';
import TextInput from 'components/inputs/text-input/text-input';
import Error from 'components/error/error';
import overviewImage from 'assets/login/overview.svg';

import api from 'services/api';
import { getUserProfile, setUserAuth } from 'redux/actions/user-actions';
import { pathnames } from 'routes/routes';

const initialValues = {
    email: '',
    password: '',
};
const validationSchema = yup.object().shape({
    email: yup.string().email().required('Email is required.'),
    password: yup.string().required(),
});

const LoginPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const onHandleSetUserAuth = useCallback((o) => dispatch(setUserAuth(o)), [dispatch]);
    const onHandleGetUserProfile = useCallback((o) => dispatch(getUserProfile(o)), [dispatch]);
    const [formError, setFormError] = useState({
		email: [""],
		password: [""],
	});

    const onHandleLogin = async (values) => {     
            if(!values.email || values.email ==""){
                setFormError({...formError, email:"Email address is mandatory"});
                return;
            }
            if(!values.password || values.password == ""){
                setFormError({...formError, password:"Password is mandatory", email:""});
                return;
            }
            else{
                setFormError({email:"", password:""});
            }
        try {               
            const response = await api.post.login(values);
            const data = response.data.result;
            localStorage.setItem("@storage_token", data.jsonWebToken);
            onHandleSetUserAuth(data);
            onHandleGetUserProfile(data.userId);
            history.push(pathnames.home);
        }
        catch (error) {   
            setFormError({...formError, password:error.response.data.message}); 
        }
    };

    const handleSocialLogin = async (props) => {
        if (props.googleId) {
            try {
                const token = props.tokenId;
                const response = await api.post.googleLogin({ token });
                const data = response.data.result;
                onHandleSetUserAuth(data);
                localStorage.setItem("@storage_token", data.jsonWebToken);
                onHandleGetUserProfile(data.userId);
                history.push(pathnames.home);
            } catch (error) {
                setFormError({...formError, password:error.response.data.message}); 
            }
        }
        else {
            try {
                const token = props.accessToken;
                const response = await api.post.facebookLogin({ token });
                const data = response.data.result;
                onHandleSetUserAuth(data);
                localStorage.setItem("@storage_token", data.jsonWebToken);
                onHandleGetUserProfile(data.userId);
                history.push(pathnames.home);
            } catch (error) {
                setFormError({...formError, password:error.response.data.message}); 
            }
        }
    }

    const handleSocialLoginFailure = (err) => {
        console.log(err)
    }

    return (
        <div className="login m-full-h">

            <div className="container m-container m-container--center">

                <div className="login__card m-card">

                    <section className="login__section">
                        <img src={overviewImage} alt="" />
                    </section>

                    <div className="login__divider" />

                    <section className="login__section">
                        <p className="login__headline m-txt m-txt--xl m-txt">Login</p>

                        <Formik
                            initialValues={initialValues}
                            //validationSchema={validationSchema}
                            onSubmit={onHandleLogin}
                        >
                            {() => (
                                <Form>
                                    <TextInput
                                        type="text"
                                        name="email"
                                        placeholder="Email address"
                                        style={formError.email != ""?{border: "1px solid red" }:null}
                                    />
                                    {formError.email != "" && <div className="text-red-500 text-left -mt-3 mb-4">{formError.email}</div>}
                                    <TextInput
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        style={formError.password != ""?{border: "1px solid red" }:null}
                                    />
                                    {formError.password != "" && <div className="text-red-500 text-left -mt-3 mb-4">{formError.password}</div>}
                                  
                                    <Link className="m-link" to={pathnames.forgotPasssword}>
                                        Forgot Password?
                                    </Link>

                                    <div className="login__sso">
                                        <p className="m-text m-txt--s">Login with</p>

                                        <div className="login__sso-wrapper">
                                            <GoogleSSOButton
                                                appId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                onLoginSuccess={handleSocialLogin}
                                                onLoginFailure={handleSocialLoginFailure} />
                                            <FacebookSSOButton
                                                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                                onLoginFailure={handleSocialLoginFailure}
                                                onLoginSuccess={handleSocialLogin}
                                            />
                                        </div>
                                    </div>

                                    <Button label="LOGIN" type="submit" />
                                </Form>
                            )}
                        </Formik>

                        <p className="login__signup m-txt m-txt--s">
                            Dont't have account yet?{' '}
                            <Link to={pathnames.register} className="m-link">
                                <b>Sign up</b>
                            </Link>
                        </p>

                    </section>

                </div>
            </div>
        </div>
    );
};

export default LoginPage;