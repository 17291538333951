import React, { useCallback, useState, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import Button from 'components/button/button';
import GoogleSSOButton from 'components/sso/google';
import FacebookSSOButton from 'components/sso/facebook';
import TextInput from 'components/inputs/text-input/text-input';
import Error from 'components/error/error';

import api from 'services/api';
import { getUserProfile, setUserAuth } from 'redux/actions/user-actions';
import { pathnames } from 'routes/routes';


const initialValues = {
    email: '',
    password: '',
};
const validationSchema = yup.object().shape({
    email: yup.string().email().required('Email is required.'),
    password: yup.string().required(),
});

const MicroLoginPage = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const onHandleSetUserAuth = useCallback((o) => dispatch(setUserAuth(o)), [dispatch]);
    const onHandleGetUserProfile = useCallback((o) => dispatch(getUserProfile(o)), [dispatch]);


    const onHandleLogin = async (values) => {
        try {
            const response = await api.post.login(values);
            const data = response.data.result;
            onHandleSetUserAuth(data);
            localStorage.setItem("@storage_token", data.jsonWebToken);
            onHandleGetUserProfile(data.userId);
            props.onHandleHide();
        }
        catch (error) {
            setError(error.response.data.message);
        }
    };

    const handleSocialLogin = async (props) => {
        console.log(props, "here");
        if (props.googleId) {
            try {
                const token = props.tokenId;
                const response = await api.post.googleLogin({ token });
                const data = response.data.result;
                onHandleSetUserAuth(data);
                localStorage.setItem("@storage_token", data.jsonWebToken);
                onHandleGetUserProfile(data.userId);
                props.onHandleHide();
                console.log(response, " SSO GOOGLE LOGGING")
            } catch (error) {
                setError(error.response.data.message);
            }
        }
        else {
            try {
                const token = props.accessToken;
                const response = await api.post.facebookLogin({ token });
                const data = response.data.result;
                onHandleSetUserAuth(data);
                localStorage.setItem("@storage_token", data.jsonWebToken);
                onHandleGetUserProfile(data.userId);
                props.onHandleHide();
                console.log(response, " SSO FACEBOOK LOGGING")
            } catch (error) {
                setError(error.response.data.message);
            }
        }
    }

    const handleSocialLoginFailure = (err) => {
        console.log(err)
    }

    return (
        <div className="login m-full-h micro-login" ref={ref}>

            <div className="container m-container m-container--center">

                <div className="login__card m-card">

                    <section className="login__section">
                        <p className="login__headline m-txt m-txt--xl m-txt">Login</p>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onHandleLogin}
                        >
                            {() => (
                                <Form>
                                    <TextInput
                                        type="text"
                                        name="email"
                                        placeholder="Email address"
                                    />
                                    <TextInput
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                    />

                                    <Error msg={error} />

                                    <Link className="m-link" to={pathnames.forgotPasssword}>
                                        Forgot Password?
                                    </Link>

                                    <div className="login__sso">
                                        <p className="m-text m-txt--s">Login with</p>

                                        <div className="login__sso-wrapper">
                                            <GoogleSSOButton
                                                appId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                onLoginSuccess={handleSocialLogin}
                                                onLoginFailure={handleSocialLoginFailure} />
                                            <FacebookSSOButton
                                                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                                onLoginFailure={handleSocialLoginFailure}
                                                onLoginSuccess={handleSocialLogin}
                                            />
                                        </div>
                                    </div>

                                    <Button label="LOGIN" type="submit" />
                                </Form>
                            )}
                        </Formik>

                    </section>

                </div>
            </div>
        </div>
    );
});

export default MicroLoginPage;