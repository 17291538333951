import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router';

import Carousel from 'components/carousel/carousel';
import CategoryPicker from 'components/category-picker/category-picker';
import ProductCard from 'components/product-card-home/product-card';
import Button from 'components/button/button';
import api from 'services/api';
import { pathnames } from 'routes/routes';
import { useIsMount } from 'hooks/use-mount';
import noProductIcon from 'assets/notfound/no_product.png';

const HomePage = (props) => {
    const isMount = useIsMount();
    const categoriesHeight = useRef();
    const history = useHistory();
    const [home, setHome] = useState();    
    const [allProduct, setAllProduct] = useState();    
    useEffect(() => {
        const categoriesEle = document.getElementById('m-categories');
        const test = document.getElementById('allProductRow');
        const onHandleScroll = () => {
            if (!categoriesHeight.current) {
                categoriesHeight.current = categoriesEle.offsetTop - 60;
            }

            if (window.pageYOffset > categoriesHeight.current) {
                categoriesEle.classList.add('m-sticky-header');
            } else {
                categoriesEle.classList.remove('m-sticky-header');
            }
        };

        window.addEventListener('scroll', onHandleScroll);

        return () => {
            window.removeEventListener('scroll', onHandleScroll);
        };
    }, []);

    useEffect(() => {
        fetchingProducts();
    }, []);

    const onHandleGetIndexs = useCallback(async () => {
        try {
            const response = await api.get.home();
            setHome(response.data.result);
        } catch (error) {
        }
    }, [props]);

    const fetchingProducts = async () => {
        const response = await api.get.products({ length: 10 });
        setAllProduct(response.data?.result);
    };    

    useEffect(() => {
        if (isMount) {
            onHandleGetIndexs();
        }
    }, [isMount, onHandleGetIndexs]);

    return (
        <div className="home">
            <div className="home__banners container m-container m-container--center">
                <div className="m-wrapper" style={{ maxWidth: 'none' }}>
                    {home && home.banners ? <Carousel banners={home.banners} /> : null}
                </div>
            </div>
            <div
                className="home__categories container m-container m-container--center z-10"
                id="m-categories"
            >
                <div className="m-wrapper">
                    {home && home.categories ? (
                        <CategoryPicker categories={home.categories} />
                    ) : null}
                </div>
            </div>
            {(home && home?.topPick) || (home && home?.newProducts) ? (
                <>
                    <div className="home__products container m-container m-container--center">
                        {home && home.topPick ? (
                            <div className="m-wrapper">
                                <div className="home__header">
                                    <p className="m-txt m-txt--xl m-txt--bold">Top Picks</p>
                                    <Button
                                        label="SHOP MORE"
                                        onClick={() => history.push(pathnames.productCategory)}
                                    />
                                </div>
                                <div className="home__card-wrapper">
                                    <ProductCard title="Top Picks" products={home.topPick} />
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="home__products home__products--white container m-container m-container--center">
                        {home && home.newProducts ? (
                            <div className="m-wrapper">
                                <div className="home__header">
                                    <p className="m-txt m-txt--xl m-txt--bold">What's New</p>
                                    <Button
                                        label="SHOP MORE"
                                        onClick={() => history.push(pathnames.productCategory)}
                                    />
                                </div>
                                <div className="home__card-wrapper">
                                    <ProductCard title="Top Picks" products={home.newProducts} />
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="mb-20" id="allProductRow">

                    </div>
                    <div className="home__products home__products--white container m-container m-container--center mt-0" >
                        {allProduct && allProduct.productList.length > 0 ? (
                            <>
                                <div className="m-wrapper">
                                    <div className="home__header">
                                        <p className="m-txt m-txt--xl m-txt--bold">All Product</p>
                                    </div>
                                    <div className="home__card-wrapper">
                                        <ProductCard
                                            title="All Product"
                                            products={allProduct.productList}
                                            className="product-card__smaller"
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    {allProduct.totalPage > 1 ? (
                        <div className="bg-white text-center py-8">
                            <Button
                                textWithBorder
                                label="LOAD MORE"
                                onClick={() => history.push(pathnames.productCategory)}
                            />
                        </div>
                    ) : null}
                </>
            ) : (
                <div className="home__products home__products--white container m-container m-container--center mt-0">
                    <div className="m-wrapper">
                        <img src={noProductIcon} className="m-auto mt-48 mb-4 w-12" />
                        <p className="text-center mb-40 text-gray-400">Sorry, no product yet</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HomePage;
