import React from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';

const Carousel = ({ banners }) => {
    if (!banners) return null;

    return (
        <ResponsiveCarousel
            className="m-carousel"
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={1500}
            infiniteLoop={true}
        >
            {banners.map((o, i) => (
                <div
                    key={`${i}-banner`}
                    className="m-carousel__slider"
                    style={{backgroundImage: `url(${o.desktopImage.replaceAll(" ", "%20")})` }}
                />
            ))}
        </ResponsiveCarousel>
    );
};

export default Carousel;
