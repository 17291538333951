import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import ProductCard from 'components/product-card/product-card';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import { useIsMount } from 'hooks/use-mount';
import api from 'services/api';
import { pathnames } from 'routes/routes';
import Button from 'components/button/button';
import noWishListIcon from 'assets/notfound/wishList.png';

const AccountWishlistPage = () => {
    const isMount = useIsMount();
    const history = useHistory();
    const [wishlist, setWishlist] = useState([]);
    const { id } = useSelector((state) => state.user.profile);

    const onHandleGetWishlist = useCallback(async () => {
        try {
            const response = await api.get.wishlist(id);
            setWishlist(response.data.result);
        } catch (error) {
            console.log(error.response.data.message);
        }
    }, [id]);

    useEffect(() => {
        if (isMount) {
            onHandleGetWishlist();
        }
    }, [isMount, onHandleGetWishlist]);

    return (
        <div className="account-wishlist">
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[{ onClick: ()=>history.push(pathnames.accounts), text: 'My Account' }, 'My Wishlist']}
                    />
                </div>
            </div>
            <div className="m-container m-container--center">
                <div className="account-wishlist__wrapper m-wrapper">
                    {wishlist.length > 0 ? (
                        <ProductCard title="Top Picks" products={wishlist} />
                    ) : (
                        <div className="home__products home__products--white container m-container m-container--center mt-0">
                            <div className="m-wrapper">
                                <img src={noWishListIcon} className="m-auto mt-48 mb-4 w-12" />
                                <p className="text-center mb-4 text-gray-400 font-medium">
                                    Sorry, no product in your {'\n'} wishlist yet
                                </p>
                                <div className="text-center m-auto mb-40">
                                    <Button
                                        label="View Products"
                                        gold
                                        small
                                        onClick={() => history.push(pathnames.productCategory)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AccountWishlistPage;
