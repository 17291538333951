import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import Error from 'components/error/error';
import Button from 'components/button/button';
import ReactCodeInput from 'react-verification-code-input';
import overviewImage from 'assets/login/overview.svg';

import api from 'services/api';
import { pathnames } from 'routes/routes';

const initialValues = {
    token: '',
};
const validationSchema = yup.object().shape({
    token: yup.string().required('Token is required'),
});

const ForgotPasswordVerificationPage = () => {
    const [error, setError] = useState('');
    const [onSubmit, setOnSubmit] = useState(false);
    const [onResendCode, setResendCode] = useState(false);
    const submitBtnText = onSubmit ? "SUBMITING" : "SUBMIT";
    const onResendBtnText = onResendCode ? "Resending..." : "Resend Code";
    const location = useLocation();
    const history = useHistory();

    const resendCodes = async () => {
        const { email } = location.state;
        setResendCode(true);
        try {
            await api.post.forgetPassword({ email });
        } catch (error) {
            setError(error.response.data.message);
        } finally {
            setResendCode(false);
        }
    };

    const onHandleSubmit = async (values) => {
        setOnSubmit(true);
        try {
            const response = await api.post.validatePasswordToken(values);
            history.push(pathnames.resetPassword, { userId: response.data.result.userId });
        } catch (error) {
            setError(error.response.data.message);
        } finally {
            setOnSubmit(false);
        }
    };

    return (
        <div className="forgot-password-validate m-full-h">

            <div className="container m-container m-container--center">
                <div className="forgot-password-validate__card m-card">

                    <section className="forgot-password-validate__section">
                        <img src={overviewImage} alt="" />
                    </section>

                    <div className="forgot-password-validate__divider" />

                    <section className="forgot-password-validate__section">

                        <p className="forgot-password__headline m-txt m-txt--xl m-txt">
                            Forget Password Verification
                        </p>
                        <p className="m-txt m-txt--small">
                            We have sent you the access code via your email for email address
                            verification.
                        </p>
                        <p className="m-txt">
                            Enter code here
                        </p>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onHandleSubmit}
                        >
                            {(props) => (
                                <Form>
                                    {console.log(props.values)}
                                    <ReactCodeInput
                                        className="forgot-password-validate__token-field"
                                        fields={4}
                                        fieldWidth={42}
                                        fieldHeight={42}
                                        name="token"
                                        type="number"
                                        disabled={onSubmit}
                                        onChange={(value) => props.setFieldValue('token', value)}
                                    />

                                    <Button label={onResendBtnText} text type="button" onClick={resendCodes} disabled={onResendCode} />

                                    <Error msg={error} />

                                    <Button label="VERIFY" type={submitBtnText} disabled={onSubmit} />
                                </Form>
                            )}
                        </Formik>
                    </section>
                </div>
            </div>

        </div>
    );
};

export default ForgotPasswordVerificationPage;
