import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import ProductCard from 'components/product-card/product-card';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import { useIsMount } from 'hooks/use-mount';
import api from 'services/api';
import noProductIcon from 'assets/notfound/no_product.png';
import Button from 'components/button/button';
import { pathnames } from 'routes/routes';

const AccountRecentViewPage = () => {
    const isMount = useIsMount();
    const history = useHistory();
    const [recentViews, setRecentViews] = useState([]);
    const { id } = useSelector((state) => state.user.profile);

    const onHandleGetRecentViewed = useCallback(async () => {
        try {
            const response = await api.get.recentView(id);
            const data = response.data.result.productDateViews;
            setRecentViews(data);
        } catch (error) {
            console.log(error.response.data.message);
        }
    }, [id]);

    useEffect(() => {
        if (isMount) {
            onHandleGetRecentViewed();
        }
    }, [isMount, onHandleGetRecentViewed]);

    return (
        <div className="account-recent-view">
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[
                            { onClick: ()=>history.push(pathnames.accounts), text: 'My Account' },
                            'My Recent Viewed',
                        ]}
                    />
                </div>
            </div>
            <div className="m-container m-container--center">
                <div className="account-recent-view__wrapper m-wrapper">
                    {recentViews.length > 0 ? (
                        <ProductCard title="Top Picks" products={recentViews} />
                    ) : (
                        <div className="home__products home__products--white container m-container m-container--center mt-0">
                            <div className="m-wrapper">
                                <img src={noProductIcon} className="m-auto mt-48 mb-4 w-12" />
                                <p className="text-center mb-4 text-gray-400 font-medium">
                                    No products have been {'\n'} viewed
                                </p>
                                <div className="text-center m-auto mb-40">
                                    <Button
                                        label="View Products"
                                        gold
                                        small
                                        onClick={() => history.push(pathnames.productCategory)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AccountRecentViewPage;
