import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ErrorMessage, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/button/button';
import TextInput from 'components/inputs/text-input/text-input';
import Dropdown from 'components/inputs/dropdown/dropdown';
import { getUserProfile } from 'redux/actions/user-actions';
import { useIsMount } from 'hooks/use-mount';
import api from 'services/api';
import config from 'helpers/config';
import { FiEdit2 } from 'react-icons/fi';
import Modal from 'react-responsive-modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Scrollbars } from 'rc-scrollbars';

const validateSchema = yup.object().shape({
    Address: yup.string().required('Address is required.'),
    country: yup.string().required('Country is required.'),
    state: yup.string().required('State is required.'),
    city: yup.string().required('City is required.'),
    postcode: yup.string().required('Postcode is required.'),
});

const AccountAddressPage = (props) => {
    const isMount = useIsMount();
    const dispatch = useDispatch();
    const [onSubmit, setOnSubmit] = useState(false);
    const submitBtnText = onSubmit ? 'UPDATING' : 'UPDATE';
    const { profile } = useSelector((state) => state.user);
    const { address, id } = useSelector((state) => state.user.profile);
    const [countries, setCountries] = useState([]);
    const onHandleGetUserProfile = useCallback((o) => dispatch(getUserProfile(o)), [dispatch]);
    const [modalVisibility, setModalVisibility] = useState(false);

    const initialValues = {
        name: profile?.displayName || '',
        mobileNumber: profile?.mobileNumber || '',
        Address: address?.Address,
        city: address?.city || '',
        state: address?.state || '',
        postcode: address?.postcode || '',
        country: address?.country || '',
        addressType: address?.addressType || '',
    };

    const onHandleGetCountries = useCallback(async () => {
        try {
            const response = await api.get.countries();
            const mappedCountries = response.data.result.map((o) => ({
                label: o.country,
                value: o.id,
            }));
            const mysiaIndex = mappedCountries.findIndex(
                (o) => o.label.toLowerCase() === 'malaysia'
            );
            mappedCountries.unshift(mappedCountries[mysiaIndex]);
            mappedCountries.splice(mysiaIndex);
            setCountries(mappedCountries);
        } catch (error) {
            console.log(error.response.data.message);
        }
    }, []);

    useEffect(() => {
        if (isMount) {
            onHandleGetCountries();
        }
    }, [isMount, onHandleGetCountries]);

    const onHandleUpdateAddress = async (values) => {
        setOnSubmit(true);
        try {
            var mobileNum = document.getElementById('mobile').value;
            const formData = new FormData();
            formData.append('id', id);
            formData.append('address', JSON.stringify({
                Address: values.Address,
                city: values.city,
                state: values.state,
                postcode: values.postcode,
                country: values.country,
                addressType: values.addressType,
            }));
            formData.append('name', values.name);
            formData.append('mobileNumber', mobileNum);
            await api.post.profileUpdate(formData);
            onHandleGetUserProfile(id);
            setTimeout(() => {
                createNotification('success', 'Address Updated');
            }, 500);
        } catch (error) {
            createNotification('error', error);
        } finally {
            setOnSubmit(false);
        }
    };

    const createNotification = (type, message, title) => {
        switch (type) {
            case 'info':
                NotificationManager.info(message);
                break;
            case 'success':
                NotificationManager.success(message);
                break;
            case 'warning':
                NotificationManager.warning(message);
                break;
            case 'error':
                NotificationManager.error(title, message);
                break;
        }
    };

    const handleMobileNumber = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]+/g, '');
    };

    return (
        <div className="account-address">
            <NotificationContainer />
            <div className="checkout__shipping-details">
                {profile.address.Address ? (
                    <div className="checkout__shipping-content checkout__shipping-content--header">
                        <div>
                            <p className="m-txt m-txt--m font-medium">{profile.displayName}</p>
                            <p>
                                {profile.address.Address}, {profile.address.city},{' '}
                                {profile.address.postcode}, {profile.address.state}
                            </p>
                            <p className="text-sm text-gray-500 mt-4">
                                Default shipping & billing address{' '}
                                {profile.address.addressType
                                    ? '| ' + profile.address.addressType
                                    : null}
                            </p>
                        </div>

                        <div
                            className="checkout__edit m-txt mb-auto"
                            onClick={() => setModalVisibility(true)}
                        >
                            <FiEdit2 size={20} />
                            <p className="m-txt m-txt--bold">Edit</p>
                        </div>
                    </div>
                ) : (
                    <div className="table w-full">
                        <div
                            className="text-center h-24 table-cell align-middle cursor-pointer font-medium"
                            style={{ border: '2px dashed black' }}
                            onClick={() => setModalVisibility(true)}
                        >
                            + Add address
                        </div>
                    </div>
                )}
            </div>

            <Modal
                center
                open={modalVisibility}
                onClose={() => setModalVisibility(false)}
                classNames={{ modal: 'w-1/2' }}
            >
                <div className="address-modal-card bg-white w-full">
                    <div className="mr-auto">
                        <p className="font-medium text-left text-2xl">
                            {profile.address.Address
                                ? 'Edit shipping address'
                                : 'Add shipping address'}
                        </p>
                    </div>
                    <Scrollbars>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validateSchema}
                            onSubmit={onHandleUpdateAddress}
                            classNames="w-1/2"
                        >
                            {({ values, setFieldValue }) => (
                                <Form className="micro-address__form">
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="name"
                                            placeholder="Recipient’s name"
                                            label="Recipient’s name"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="name" />
                                        </p>
                                    </div>
                                    <div
                                        className="micro-address__input-wrapper"
                                        style={{
                                            borderBottom: '1px solid lightgrey',
                                            paddingBottom: '20px',
                                        }}
                                    >
                                        <label
                                            class="m-text m-text--s text-input__label"
                                            for="name"
                                        >
                                            Phone Number
                                        </label>
                                        <div class="inputpad-group prefix mt-2">
                                            <span class="inputpad-group-addon">
                                                +60{' '}
                                                <h1
                                                    style={{
                                                        color: 'lightgray',
                                                        display: 'inline',
                                                        marginLeft: 10,
                                                    }}
                                                >
                                                    {' '}
                                                    |{' '}
                                                </h1>{' '}
                                            </span>
                                            <input
                                                maxLength={9}
                                                type="text"
                                                id="mobile"
                                                pattern="[0-9]*"
                                                defaultValue={profile.mobileNumber}
                                                onChange={(e) => handleMobileNumber(e)}
                                                placeholder="Mobile Number"
                                                className="bg-white
						   py-4 inputpad
						  justify-between items-center"
                                                style={{
                                                    border: '1px solid rgba(0, 0, 0, 0.1)',
                                                    borderLeft: 'none',
                                                    borderRadius: '0 0.25rem 0.25rem 0',
                                                    paddingLeft: 0,
                                                }}
                                            />
                                        </div>
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="mobileNumber" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="Address"
                                            placeholder="Address"
                                            label="Address"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="Address" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper">
                                        <Dropdown
                                            outline
                                            max
                                            value={
                                                countries.filter(
                                                    (o) => o.value === values.country
                                                )[0]
                                            }
                                            options={countries}
                                            label="Country"
                                            disabled={onSubmit}
                                            onChange={(country) =>
                                                setFieldValue('country', country.value)
                                            }
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="country" />
                                        </p>
                                    </div>
                                    {values.country && values.country === 157 ? (
                                        <div className="account-address__input-wrapper">
                                            <Dropdown
                                                outline
                                                max
                                                value={
                                                    config.malaysia.state.filter(
                                                        (o) => o.value === values.state
                                                    )[0]
                                                }
                                                options={config.malaysia.state}
                                                label="State"
                                                disabled={onSubmit}
                                                onChange={(state) =>
                                                    setFieldValue('state', state.value)
                                                }
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="state" />
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="account-address__input-wrapper">
                                            <TextInput
                                                max
                                                outline
                                                name="state"
                                                placeholder="States"
                                                label="States"
                                                disabled={onSubmit}
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="state" />
                                            </p>
                                        </div>
                                    )}
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="city"
                                            placeholder="City"
                                            label="City"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="city" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="postcode"
                                            placeholder="Zip/Postal code"
                                            label="Zip/Postal code"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="postcode" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper mb-6">
                                        <RadioGroup
                                            row
                                            aria-label="addressType"
                                            name="addressType"
                                            defaultValue={
                                                initialValues.addressType
                                                    ? initialValues.addressType
                                                    : 'Home'
                                            }
                                            onChange={(e) =>
                                                setFieldValue('addressType', e.target.value)
                                            }
                                        >
                                            <FormControlLabel
                                                value="Home"
                                                control={<Radio color="default" />}
                                                label="Home"
                                            />
                                            <FormControlLabel
                                                value="Office"
                                                control={<Radio color="default" />}
                                                label="Office"
                                            />
                                        </RadioGroup>
                                    </div>
                                    <div className="cart__button-wrapper">
                                        <label
                                            className="self-center m-auto cursor-pointer"
                                            onClick={() => setModalVisibility(false)}
                                        >
                                            CANCEL
                                        </label>
                                        <Button
                                            type="submit"
                                            label={profile.address.Address ? 'SAVE' : 'ADD'}
                                            text
                                            gold
                                            disabled={onSubmit}
                                            style={{ boxShadow: 'none' }}
                                            onClick={() => setModalVisibility(false)}
                                            className="m-auto"
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Scrollbars>
                </div>
            </Modal>
            {/* <Formik
                initialValues={initialValues}
                validationSchema={validateSchema}
                onSubmit={onHandleUpdateAddress}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="account-address__input-wrapper">
                            <TextInput max outline name="street1" placeholder="Street 1" label="Street 1" disabled={onSubmit} />
                            <p className="m-txt m-txt--s m-txt--error">
                                <ErrorMessage name="street1" />
                            </p>
                        </div>
                        <div className="account-address__input-wrapper">
                            <TextInput max outline name="street2" placeholder="Street 2" label="Street 2" disabled={onSubmit} />
                            <p className="m-txt m-txt--s m-txt--error">
                                <ErrorMessage name="street2" />
                            </p>
                        </div>
                        <div className="account-address__input-wrapper">
                            <Dropdown
                                outline
                                max
                                value={countries.filter(o => o.value === values.country)[0]}
                                options={countries}
                                label="Country"
                                disabled={onSubmit}
                                onChange={(country) => setFieldValue('country', country.value)}
                            />
                            <p className="m-txt m-txt--s m-txt--error">
                                <ErrorMessage name="country" />
                            </p>
                        </div>
                        {values.country && values.country === 157 ?
                            <div className="account-address__input-wrapper">
                                <Dropdown
                                    outline
                                    max
                                    value={config.malaysia.state.filter(o => o.value === values.state)[0]}
                                    options={config.malaysia.state}
                                    label="State"
                                    disabled={onSubmit}
                                    onChange={(state) => setFieldValue('state', state.value)}
                                />
                                <p className="m-txt m-txt--s m-txt--error">
                                    <ErrorMessage name="state" />
                                </p>
                            </div>
                            :
                            <div className="account-address__input-wrapper">
                                <TextInput max outline name="state" placeholder="State" label="State" disabled={onSubmit} />
                                <p className="m-txt m-txt--s m-txt--error">
                                    <ErrorMessage name="state" />
                                </p>
                            </div>
                        }
                        <div className="account-address__input-wrapper">
                            <TextInput max outline name="city" placeholder="City" label="City" disabled={onSubmit} />
                            <p className="m-txt m-txt--s m-txt--error">
                                <ErrorMessage name="city" />
                            </p>
                        </div>
                        <div className="account-address__input-wrapper">
                            <TextInput max outline name="postcode" placeholder="Postcode" label="Postcode" disabled={onSubmit} />
                            <p className="m-txt m-txt--s m-txt--error">
                                <ErrorMessage name="postcode" />
                            </p>
                        </div>
                        <Button type="submit" label={submitBtnText} disabled={onSubmit} />
                    </Form>
                )}
            </Formik> */}
        </div>
    );
};

export default AccountAddressPage;
