import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import CircleAvatar from 'components/circle-avatar/circle-avatar';
import { orderStatusFormat } from 'helpers/utility';
import { useIsMount } from 'hooks/use-mount';
import moment from "moment";
import api from 'services/api';

const orderDateFormat = 'DD MMM YYYY, H:mm:ss';
const NotificationsPage = () => {
    const isMount = useIsMount();
    const [notifications, setNotifications] = useState([]);
    const { id } = useSelector((state) => state.user.profile);

    const onHandleGetNotifications = useCallback(async () => {
        const response = await api.get.notifications(id);
        setNotifications(response.data.result.orderNotificationList);
    }, [id]);

    useEffect(() => {
        if (isMount) {
            onHandleGetNotifications();
        }
    }, [isMount, onHandleGetNotifications]);

    return (
        <Fragment>
            <div className="notifications">
                <div className="m-container m-container--center">
                    <div className="notifications__wrapper m-wrapper">
                        <h1 className="m-txt m-txt--bold m-txt--xl">Notifications</h1>
                    </div>
                </div>
                <div className="container m-container m-container--center">
                    <div className="notifications__wrapper m-wrapper">
                        {notifications.map((o, i) => (
                            <NotificationCard key={`${i}-notification`} data={o} />
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default NotificationsPage;

const NotificationCard = ({ data }) => {
    const orderStatus = orderStatusFormat(data.orderStatus);

    return (
        <Fragment>
            <div className="notifications__card-wrapper m-card">
                <div className="notifications__header">
                    <div className="notifications__card-wrapper">
                        <p className="notifications__label m-txt m-txt--grey">
                            Order Number - <span className="m-txt">{data.orderNumber}</span>
                        </p>
                        <p className="notifications__label m-txt m-txt--grey">
                            Placed on -{' '}
                            <span className="m-txt">
                                {moment(data.createdAt).format(orderDateFormat)}
                            </span>
                        </p>
                        <p className="notifications__label m-txt m-txt--grey">
                            From  -{' '}
                            <span className="m-txt">{data.companyName}</span>
                        </p>
                    </div>
                    <div className="notifications__card-wrapper">
                        <div className={`notifications__status notifications__status--${orderStatus.color}`}>
                            <p className="m-txt m-txt--s">{orderStatus.label}</p>
                        </div>
                    </div>
                </div>
                <div className="notifications__card-content">
                    {data.products.map((o, i) => (
                        <div className="notifications__card-wrapper--item" key={`${i}-item`}>
                            <CircleAvatar src={o.productImage} size={50} />
                            <div className="notifications__item">
                                <p className="m-txt m-txt--bold">{o.productName} <span className="m-txt m-txt--s m-txt--grey">x {o.quantity}</span></p>
                                <div className="m-txt m-txt--grey" dangerouslySetInnerHTML={{ __html: o.productDescription }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};
