import React, { Fragment, useState, useRef } from 'react';

import MicroLoginPage from 'pages/auth/micro-login/micro-login-page';

const withLogin = (WrappedComponent) => ({ ...props }) => {
    const overlayRef = useRef();
    const microLoginRef = useRef();
    const [visible, setVisible] = useState(false);

    const onHandleToggleLogin = () => {
        if (!microLoginRef && !overlayRef) return;

        if (overlayRef.current && !visible) {
            overlayRef.current.classList.remove('m-overlay--fade-out');
            microLoginRef.current.classList.remove('micro-login--slide-out');
            overlayRef.current.classList.add('m-overlay--fade-in');
            microLoginRef.current.classList.add('micro-login--slide-in');
        }
        else {
            overlayRef.current.classList.add('m-overlay--fade-out');
            overlayRef.current.classList.remove('m-overlay--fade-in');
            microLoginRef.current.classList.remove('micro-login--slide-in');
            microLoginRef.current.classList.add('micro-login--slide-out');
        }

        setVisible(!visible);
    }

    return (
        <Fragment>
            <MicroLoginPage ref={microLoginRef} onHandleHide={onHandleToggleLogin} />
            <div className="m-overlay" ref={overlayRef} onClick={onHandleToggleLogin} />
            <WrappedComponent {...props} onHandleToggleLogin={onHandleToggleLogin} />
        </Fragment>
    );

};
export default withLogin;
