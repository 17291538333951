import { info } from 'redux/actions/info-actions';
import { sanitizeError } from 'helpers/utility'

const initialState = {
    msg: null,
    error: null,
    success: null,
}

const infoReducer = (state = initialState, action) => {
    switch (action.type) {
        case info.INFO_SHOW:
            const msg = sanitizeError(action.data.msg);
            return {
                ...state,
                error: action.data.error,
                success: action.data.success,
                msg
            }
        case info.INFO_HIDE:
            return initialState;
        default:
            return state;
    }
}

export default infoReducer;