import React from 'react';
import { IoMailOutline } from 'react-icons/io5';
import { FiPhoneCall } from "react-icons/fi";

const HelpPage = () => {
    return (
        <div className="help">
            <div className="container m-container m-container--center">
                <div className="help__card">
                    <h1 className="m-txt m-txt--xl m-txt--bold">Need help?</h1>
                    <p className="my-2">Please contact us for assistance</p>
                    <div className="help__card-item">
                        <IoMailOutline size={20} />
                        <a href="mailto:sample2021@gmail.com" style={{color:"#C19E66"}}>sample2021@gmail.com</a>
                    </div>
                    <div className="help__card-item">
                        <FiPhoneCall size={20} />
                        <p className="m-txt m-txt--bold">+60 12-345 6789</p>
                    </div>
                    <div className="help__card-item m-container--center">
                        <div className="help__card-item--text-center">
                            <p className="m-txt--bold">Altecflex Sdn. Bhd. <span className="m-txt--xs">(1300232-T)</span></p>
                            <p className="m-txt--s m-txt--grey">Unit B, IOI Boulevard, 1st Floor, 1F-02, Jalan Kenari 5,</p>
                            <p className="m-txt--s m-txt--grey">Bandar Puchong Jaya, 47100 Puchong, Selangor.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpPage;
