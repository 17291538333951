import React, { useCallback, useRef, useState, memo } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';

import { validMobileNumbers } from 'helpers/utility';
import Button from 'components/button/button';
import TextInput from 'components/inputs/text-input/text-input';
import Dropdown from 'components/inputs/dropdown/dropdown';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import { getUserProfile } from 'redux/actions/user-actions';
import config from 'helpers/config';
import api from 'services/api';

const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'nonbinary', label: 'Non-Binary' },
];

const formatGender = (gender) => {
    switch (gender) {
        case 'M':
            return 'male';
        case 'F':
            return 'female';
        case 'nonbinary':
            return 'nonbinary';
        default:
            return gender;
    }
};

const fileErrors = [
    { type: 'fileSize', error: 'File Size is too large' },
    { type: 'fileType', error: 'Unsupported File Format' },
];
const validationSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    mobileNumber: yup
        .string()
        .test('mobileNumber', 'Please enter a valid mobile number', validMobileNumbers)
        .required('Mobile number is required'),
    gender: yup.string().required('Gender is required'),
    image: yup
        .mixed()
        .test(fileErrors[0].type, fileErrors[0].error, function (value) {
            const { image } = this.parent;
            return value && image?.size <= config.file.size ? true : !value ? true : false;
        })
        .test(fileErrors[1].type, fileErrors[1].error, function (value) {
            const { image } = this.parent;
            return image && config.file.types.includes(image.type) ? true : !value ? true : false;
        }),
});

const ProfilePage = (props) => {
    const uploadRef = useRef();
    const dispatch = useDispatch();
    const [onSubmit, setOnSubmit] = useState(false);
    const submitBtnText = onSubmit ? 'UPDATING' : 'UPDATE';
    const { image, displayName, email, mobileNumber, gender, id } = useSelector(
        (state) => state.user.profile
    );
    const onHandleGetUserProfile = useCallback((o) => dispatch(getUserProfile(o)), [dispatch]);

    const initialValues = {
        name: displayName,
        email,
        mobileNumber,
        gender: formatGender(gender),
    };

    const onHandleOnSubmit = async (values) => {
        setOnSubmit(true);
        try {
            const formData = new FormData();
            formData.append('id', id);
            for (var key in values) {
                formData.append(key, values[key]);
            }
            await api.post.profileUpdate(formData);
            onHandleGetUserProfile(id);
        } catch (error) {
        } finally {
            setOnSubmit(false);
        }
    };

    const onHandleTriggerUpload = () => {
        if (uploadRef) {
            uploadRef.current.click();
        }
    };

    return (
        <div className="account-profile">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onHandleOnSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form className="w-2/5">
                        <div className="account-profile__header">
                            <Circle
                                onClick={onHandleTriggerUpload}
                                img={values.image}
                                url={image}
                            />
                            <p className="m-txt m-txt--xl font-medium">{displayName}</p>
                            <input
                                type="file"
                                name="image"
                                hidden
                                ref={uploadRef}
                                onChange={(e) => {
                                    setFieldValue('image', e.target.files[0]);
                                }}
                            />
                        </div>
                        <div className="account-profile__input-wrapper">
                            <p className="m-txt m-txt--s m-txt--error">
                                <ErrorMessage name="image" />
                            </p>
                        </div>
                        <div className="account-profile__input-wrapper">
                            <TextInput
                                outline
                                max
                                name="name"
                                placeholder="Full Name"
                                label="Full Name"
                                disabled={onSubmit}
                            />
                            <p className="m-txt m-txt--s m-txt--error">
                                <ErrorMessage name="name" />
                            </p>
                        </div>
                        <div className="account-profile__input-wrapper">
                            <TextInput
                                outline
                                max
                                name="email"
                                label="Email Address"
                                placeholder="Email Address"
                                disabled={true}
                            />
                            <p className="m-txt m-txt--s m-txt--error">
                                <ErrorMessage name="email" />
                            </p>
                        </div>
                        <div className="account-profile__input-wrapper">
                            <TextInput
                                outline
                                max
                                name="mobileNumber"
                                placeholder="Mobile Number"
                                label="Mobile Number"
                                disabled={onSubmit}
                            />
                            <p className="m-txt m-txt--s m-txt--error">
                                <ErrorMessage name="mobileNumber" />
                            </p>
                        </div>
                        <div className="account-profile__input-wrapper">
                            <Dropdown
                                outline
                                max
                                options={genderOptions}
                                value={values.gender}
                                label="Gender"
                                disabled={onSubmit}
                                onChange={(gender) => setFieldValue('gender', gender.value)}
                            />
                            <p className="m-txt m-txt--s m-txt--error">
                                <ErrorMessage name="gender" />
                            </p>
                        </div>

                        <div className="account-profile__button-wrapper">
                            <Button type="submit" label={submitBtnText} disabled={onSubmit} />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ProfilePage;

const Circle = memo(({ img, url, onClick }) => {
    return <CircleAvatar src={img ? URL.createObjectURL(img) : url} onClick={onClick} />;
});
