import React from 'react'
import facebookIcon from 'assets/login/facebook-icon.svg';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const FacebookSSOButton = ({ appId, onLoginSuccess, onLoginFailure }) => {
    return (
        <FacebookLogin
            appId={appId}
            autoLoad={false}
            scope="pages_show_list"
            fields="name,email,picture"
            callback={onLoginSuccess}
            onFailure={onLoginFailure}
            render={renderProps => (
                <button type="button" className="sso-btn" onClick={renderProps.onClick}>
                    <img src={facebookIcon} alt="facebok login" style={{padding:"10px"}}/>
                </button>
            )}
        />
    );

}

export default FacebookSSOButton;