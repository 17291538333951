import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import Error from 'components/error/error';
import Button from 'components/button/button';
import TextInput from 'components/inputs/text-input/text-input';
import overviewImage from 'assets/login/overview.svg';

import api from "services/api"
import { pathnames } from 'routes/routes';
import ITUBEPolicy from 'assets/document/Itube Policy.pdf'
import ITUBEPrivacyPolicy from 'assets/document/HOT TV ENTERTAINMENT SDN BHD Privacy Policy.pdf';

const initialValues = {
    email: '',
    password: '',
    username: '',
};

const validationSchema = yup.object().shape({
    email: yup.string().email().required('Email is required.'),
    password: yup.string().required(),
});

const RegisterPage = () => {
    const history = useHistory();
    const [error, setError] = useState("");
    const [onSubmit, setOnSubmit] = useState(false);
    const submitBtnText = onSubmit ? "SIGNING" : "SIGN UP";
    const [formError, setFormError] = useState({
        name: [""],
		email: [""],
		password: [""],
	});
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const onHandleSubmit = async (values) => {
        if(!values.username || values.username ==""){
            setFormError({...formError, name:"Full Name is mandatory"});
            return;
        }
        if(!values.email || values.email ==""){
            setFormError({...formError, email:"Email address is mandatory", name: ""});
            return;
        }
        if(values.email && !values.email.match(mailformat)){
            setFormError({...formError, email:"Invalid email address", name: ""});
            return;
        }
        if(!values.password || values.password == ""){
            setFormError({...formError, password:"Password is mandatory" , name: "" , email:""});
            return;
        }
        else{
            setFormError({name: "", email:"", password:""});
        }
        setOnSubmit(true);
        try {
            const response = await api.post.signup(values);
            history.push(pathnames.verifyAccount, { userId: response.data.result.user.id });
            console.log(response)
        }
        catch (error) {
            setFormError({...formError, password:error.response.data.message}); 
        } finally {
            setOnSubmit(false);
        }
    };


    return (
        <div className="signup m-full-h">
            <div className="container m-container m-container--center">

                <div className="signup__card m-card">

                    <section className="signup__section">
                        <img src={overviewImage} alt="" />
                    </section>

                    <div className="signup__divider" />

                    <section className="signup__section">
                        <p className="login__headline m-txt m-txt--xl">Sign up</p>

                        <Formik
                            initialValues={initialValues}
                            //validationSchema={validationSchema}
                            onSubmit={onHandleSubmit}
                        >
                            {() => (
                                <Form>
                                    <TextInput
                                        type="text"
                                        name="username"
                                        placeholder="Full name"
                                        disabled={onSubmit}
                                        style={formError.name != ""?{border: "1px solid red" }:null}
                                    />
                                     {formError.name != "" && <div className="text-red-500 text-left -mt-3 mb-4">{formError.name}</div>}
                                    <TextInput
                                        type="text"
                                        name="email"
                                        placeholder="Email address"
                                        disabled={onSubmit}
                                        style={formError.email != ""?{border: "1px solid red" }:null}
                                    />
                                     {formError.email != "" && <div className="text-red-500 text-left -mt-3 mb-4">{formError.email}</div>}
                                    <TextInput
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        disabled={onSubmit}
                                        style={formError.password != ""?{border: "1px solid red" }:null}
                                    />
                                    {formError.password != "" && <div className="text-red-500 text-left -mt-3 mb-4">{formError.password}</div>}
                                  
                                    <p className="signup__terms m-txt">
                                        By signing up you accept the{' '}
                                        <a className="m-link" href='https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf' target="_blank">
                                            Terms & condition
                                            </a>{' '}
                                        &{' '}
                                        <a className="m-link" href='https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf' target="_blank">
                                            Privacy Policy
                                            </a>
                                    </p>

                                    <Button label={submitBtnText} type="submit" disabled={onSubmit}/>
                                </Form>
                            )}
                        </Formik>

                        <p className="signup__link m-txt">
                            Already have an account?{' '}
                            <Link to={pathnames.login} className="m-link">
                                <b>Login</b>
                            </Link>
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
