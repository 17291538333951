import LoginPage from 'pages/auth/login/login-page';
import ForgotPasswordPage from 'pages/auth/forgot-password/forgot-password-page';
import ForgotPasswordVerificationPage from 'pages/auth/forgot-password-verification/forgot-password-verification-page';
import ResetPasswordPage from 'pages/auth/reset-password/reset-password-page';
import RegisterPage from 'pages/auth/register/register-page';
import VerifyAccountPage from 'pages/auth/vefication-account/vefication-account-page';
import NotFoundPage from 'pages/common/not-found/not-found-page';
import MobileUserPage from 'pages/common/mobile-user/mobile-user-page';
import PolicyPage from 'pages/common/policy/policy-page';
import TermsPage from 'pages/common/terms/terms-page';
import AboutUsPage from 'pages/common/about-us/about-us-page';
import HelpPage from 'pages/common/help/help-page';
import FAQPage from 'pages/common/faq/faq-page';
import HomePage from 'pages/home/home-page';
import CategoryPage from 'pages/category/category-page';
import ProductPage from 'pages/product-details/product-details-page';
import ProductReviewPage from 'pages/product-review/product-review-page';
import MerchantPage from 'pages/merchant/merchant-page';
import AccountPage from 'pages/accounts/account/account-page';
import AccountSettingsPage from 'pages/accounts/account-settings/account-settings-page';
import AccountWishListPage from 'pages/accounts/account-wishlist/account-wishlist-page';
import AccountRecentViewPage from 'pages/accounts/account-recent-view/account-recent-view-page';
import AccountOrdersPage from 'pages/accounts/account-orders/account-orders-page';
import AccountReviewsPage from 'pages/accounts/account-reviews/account-reviews-page';
import NotificationsPage from 'pages/notifications/notifications-page';
import CartPage from 'pages/cart/cart/cart-page';
import CheckoutPage from 'pages/cart/checkout/checkout-page';
import StreamingPage from 'pages/live-stream/streaming/streming-page';
import LiveStreamsPage from 'pages/live-stream/live-stream/live-stream-page';
import AccountOrderDetailsPage from 'pages/accounts/account-order-details/account-order-details-page';
import LiveStreamTabsPage from 'pages/live-stream/live-stream-tabs/live-stream-tabs.page';
import PaymentPage from 'pages/cart/payment/payment-page';

export const pathnames = {
    home: "/",
    login: "/login",
    forgotPasssword: "/forgot-password",
    forgotPasswordVerification: "/forgot-password-verification",
    resetPassword: "/reset-password",
    register: "/register",
    verifyAccount: '/verify-account',
    productCategory: '/buyer',
    productDetails: '/product/:productId',
    productReview: '/product/review/:productId',
    mobileUser: '/mobile-user',
    merchart: '/merchant/:merchantId',
    accounts: '/accounts',
    accountSettings: '/account/settings/:currentTab',
    accountWishlist: '/account/wishlist',
    accountRecentViewed: '/account/recent-viewed',
    accountReviews: '/account/reviews',
    accountOrders: '/account/orders',
    accountOrderDetails: '/account/orders/details',
    cart: '/cart',
    checkout: '/checkout',
    payment: '/payment',
    notifications: '/notifications',
    liveStream: '/live-stream',
    liveStreamTabs: '/live-stream/all',
    streaming: '/streaming/:streamingKey',
    about: '/about',
    help: '/help',
    faq: '/faq',
    terms: '/terms',
    policy: '/policy',
    notFound: '/*',
}

export const routes = [
    { path: pathnames.login, component: LoginPage, nav: false },
    { path: pathnames.forgotPasssword, component: ForgotPasswordPage, nav: false },
    { path: pathnames.forgotPasswordVerification, component: ForgotPasswordVerificationPage, nav: false },
    { path: pathnames.resetPassword, component: ResetPasswordPage, nav: false },
    { path: pathnames.register, component: RegisterPage, nav: false },
    { path: pathnames.verifyAccount, component: VerifyAccountPage, nav: false },
    { path: pathnames.home, component: HomePage, nav: true },
    { path: pathnames.productCategory, component: CategoryPage, nav: true },
    { path: pathnames.productDetails, component: ProductPage, nav: true },
    { path: pathnames.productReview, component: ProductReviewPage, nav: true },
    { path: pathnames.merchart, component: MerchantPage, nav: true },
    { path: pathnames.accounts, component: AccountPage, nav: true, private: true },
    { path: pathnames.accountSettings, component: AccountSettingsPage, nav: true },
    { path: pathnames.accountWishlist, component: AccountWishListPage, nav: true, private: true },
    { path: pathnames.accountRecentViewed, component: AccountRecentViewPage, nav: true, private: true },
    { path: pathnames.accountOrders, component: AccountOrdersPage, nav: true, private: true },
    { path: pathnames.accountOrderDetails, component: AccountOrderDetailsPage, nav: true },
    { path: pathnames.accountReviews, component: AccountReviewsPage, nav: true, private: true },
    { path: pathnames.cart, component: CartPage, nav: true, private: true },
    { path: pathnames.checkout, component: CheckoutPage, nav: true, private: true },
    { path: pathnames.payment, component: PaymentPage, nav: true, private: true },
    { path: pathnames.notifications, component: NotificationsPage, nav: true, private: true },
    { path: pathnames.liveStream, component: LiveStreamsPage, nav: true },
    { path: pathnames.liveStreamTabs, component: LiveStreamTabsPage, nav: true },
    { path: pathnames.streaming, component: StreamingPage, nav: true, private: true },
    { path: pathnames.mobileUser, component: MobileUserPage, nav: true },
    { path: pathnames.about, component: AboutUsPage, nav: true },
    { path: pathnames.help, component: HelpPage, nav: true },
    { path: pathnames.faq, component: FAQPage, nav: true },
    { path: pathnames.terms, component: TermsPage, nav: true },
    { path: pathnames.policy, component: PolicyPage, nav: true },
    { path: pathnames.notFound, component: NotFoundPage, nav: true },
];

