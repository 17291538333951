import React, { useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import _ from 'lodash';

const QuantityPicker = ({ value, min = 0, max = 0, onChange, disabled }) => {
    const [quantity, setQuantity] = useState(value);

    const onHandleQuantityChange = (e) => {
        const v = Math.floor(parseInt(e.currentTarget.value));

        if (v > max) {
            setQuantity(max);
        }
        else {
            setQuantity(v)
        }
    }

    const onHandleInputBlur = () => {
        if (!quantity || quantity <= 0) {
            setQuantity(value);
            return;
        }

        onChange(quantity);
    }


    const onHandleCircleButton = (v) => {

        if (v <= 0) {
            setQuantity(1);
            onChange(v);
        }
        else {
            setQuantity(v);
            onChange(v);
        }
    }

    const debounceQuantityButton = _.debounce(onHandleCircleButton);

    return (
        <div className="quantity-picker">
            <button
                className="quantity-picker__button quantity-picker__button--outline"
                onClick={() => debounceQuantityButton(value - 1)}
                disabled={!onChange || disabled || (value <= min)}
            >
                <FiMinus />
            </button>
            <input
                className="quantity-picker__input"
                type="number"
                value={quantity}
                onBlur={onHandleInputBlur}
                onChange={onHandleQuantityChange}
                readOnly={!onChange}
            />
            <button
                className="quantity-picker__button"
                onClick={() => debounceQuantityButton(value + 1)}
                disabled={!onChange || disabled || (max > 0 && value >= max)}
            >
                <FiPlus />
            </button>
        </div>
    )
};

export default QuantityPicker;
