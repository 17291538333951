import React from 'react';
import MobileUserSVG from 'assets/mobileUser/mobileUser.svg';
import AppleSVG from 'assets/mobileUser/appleStore.svg';
import GoogleSVG from 'assets/mobileUser/googlePlay.svg';

const MobileUserPage = () => {
    return (
        <div className="mobile-user m-full-h">
            <div className="container m-container m-container--center">
                <div className="mobile-user__card m-card">
                    <img
                        className="mobile-user__image"
                        src={MobileUserSVG}
                        alt="download play store/app store"
                    />
                    <h1 className="mobile-user__headline m-txt m-txt--l m-txt--bold">
                        For better mobile experience, please download our apps on the app
                        stores.
                    </h1>
                    <div className="mobile-user__apps">
                        <a href={process.env.REACT_APP_APP_STORE}>
                            <img src={AppleSVG} alt="app store" />
                        </a>
                        <a href={process.env.REACT_APP_PLAY_STORE}>
                            <img src={GoogleSVG} alt="play store" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileUserPage;
